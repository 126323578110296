import axios from 'axios';

const logger = require('../../logger');

export function fetchCourses(onFetchCourses) {
  logger.trace('fetchCourses');

  axios
    .get('/courses')
    .then((response) => {
      if (response.status === 200) {
        onFetchCourses(response.data.courses);
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function updateCourse(data, onUpdateCourse) {
  logger.trace('updateCourse');

  axios
    .post('/course/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Course updated...');
        onUpdateCourse();
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function createCourse(data, onCreateCourse) {
  logger.trace('createCourse');

  axios
    .post('/course/create', data)
    .then((response) => {
      if (response.status === 200) {
        onCreateCourse();
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function editCourse(idCourse, onEditCourse) {
  logger.trace('editCourse');

  logger.info('Editing course:', idCourse);
  axios
    .get(`/course/${idCourse}`)
    .then((response) => {
      if (response.status === 200) {
        onEditCourse(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `GET - /course/${idCourse}`);
    });
}

export function deleteCourse(idCourse, onDeleteCourse) {
  logger.trace('deleteCourse');

  if (window.confirm('Are you sure you wish to delete this course?')) {
    logger.info('Deleting course:', idCourse);

    axios
      .delete(`/course/${idCourse}`)
      .then((response) => {
        if (response.status === 200) {
          onDeleteCourse();
        }
      })
      .catch((error) => {
        logger.error(error, `DELETE /course/${idCourse}`);
      });
  }
}

export function changeCourse(idOfCourse, onChangeCourse) {
  logger.trace('changeCourse');

  axios
    .get(`/teachingunit/course/${idOfCourse}`)
    .then((response) => {
      if (response.status === 200) {
        onChangeCourse(idOfCourse, response.data.teachingunits);
      }
    })
    .catch((error) => {
      logger.error(error, `GET /teachingunit/course/${idOfCourse}`);
    });
}

export function lookupCourse(listOfCourses, idOfCourse) {
  logger.trace('lookupCourse');

  for (let i = 0; i < listOfCourses.length; i += 1) {
    if (listOfCourses[i].courseid === idOfCourse) {
      return listOfCourses[i].name;
    }
  }
  return idOfCourse;
}

export function fetchCourseHierarchy(onFetchCourseHierarchy) {
  logger.trace('fetchCourseHierarchy');

  axios
    .get('/courses/hierarchy')
    .then((response) => {
      if (response.status === 200) {
        onFetchCourseHierarchy(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, 'GET /courses/hierarchy');
    });
}
