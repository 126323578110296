import PropTypes from 'prop-types';
import './HeartCard.scss';
import React, { useRef, useEffect } from 'react';

function HeartCard(props) {
  HeartCard.propTypes = {
    id: PropTypes.number.isRequired,
    favourite: PropTypes.bool.isRequired,
    changeFavourite: PropTypes.func.isRequired
  };

  const { id, favourite, changeFavourite } = props;

  const tooltipRef = useRef(null);

  useEffect(() => {
    const tooltip = new window.bootstrap.Tooltip(tooltipRef.current, {
      container: 'body',
      trigger: 'hover'
    });

    return () => {
      tooltip.dispose();
    };
  }, []);

  return (
    <button className="heart-card" type="button" ref={tooltipRef} data-bs-toggle="tooltip" title="Click to add to or remove from your Favourites">
      {(favourite === undefined || favourite === false) && (
        <i
          className="bi bi-suit-heart text-primary"
          id={id}
          onClick={(e) => {
            e.stopPropagation();
            changeFavourite(e.target.id, true);
          }}
          style={{ cursor: 'pointer' }}
        />
      )}
      {favourite === true && (
        <i
          className="bi bi-suit-heart-fill text-primary"
          id={id}
          onClick={(e) => {
            e.stopPropagation();
            changeFavourite(e.target.id, false);
          }}
          style={{ cursor: 'pointer' }}
        />
      )}
    </button>
  );
}

export default HeartCard;
