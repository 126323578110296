import { useState } from 'react';
import PropTypes from 'prop-types';

import logger from '../../../logger';

function ImageStarterEdit(props) {
  logger.trace('ImageStarterEdit');

  const [title, setTitle] = useState(props.title);
  const [src, setSrc] = useState(props.src);
  const [imageAlt, setImageAlt] = useState(props.imageAlt);

  ImageStarterEdit.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    onChildChange: PropTypes.func
  };

  ImageStarterEdit.defaultProps = {
    onChildChange: null
  };

  function somethingChanged(target) {
    logger.trace('somethingChanged');

    const data = { title, src, imageAlt };

    if (target.name === 'title') {
      setTitle(target.value);
      data.title = target.value;
    }
    if (target.name === 'src') {
      setSrc(target.value);
      data.src = target.value;
    }
    if (target.name === 'imageAlt') {
      setImageAlt(target.value);
      data.imageAlt = target.value;
    }

    if (props.onChildChange !== undefined && props.onChildChange !== null) {
      props.onChildChange(data);
    } else {
      logger.warn('No onChildChange defined for ImageStarterEdit');
    }
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-title">
          Title:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="title"
          id="newcon-title"
          placeholder="Enter lesson starter title"
          onChange={(e) => somethingChanged(e.target)}
          value={title}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-src">
          Source:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="src"
          id="newcon-src"
          placeholder="Enter Image embedded link"
          onChange={(e) => somethingChanged(e.target)}
          value={src}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-imageAlt">
          Image Alt:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="imageAlt"
          id="newcon-imageAlt"
          placeholder="Image Alternative"
          onChange={(e) => somethingChanged(e.target)}
          value={imageAlt}
        />
      </div>
    </>
  );
}

export default ImageStarterEdit;
