import axios from 'axios';
import { formatWeekViewDate } from '../diary';
import { getStoredTeacher } from '../user';
import { logActivity } from './activity';

const logger = require('../../logger');

export async function fetchStartersInDateRange(currentTeacher, searchDates, setStartersArray) {
  logger.trace('fetchStartersInDateRange');

  axios
    .get('/diary/entry/teacher/id/date', {
      params: {
        teacherid: currentTeacher.teacherid,
        dateRange: searchDates
      }
    })
    .then((response) => {
      setStartersArray([
        Object.values(response.data)[0],
        Object.values(response.data)[1],
        Object.values(response.data)[2],
        Object.values(response.data)[3],
        Object.values(response.data)[4]
      ]);
      return response;
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function getDatesOfCurrentWeek(stateSetter) {
  logger.trace('getDatesOfCurrentWeek');

  const today = new Date();

  stateSetter(today);

  const currentDayOfWeek = today.getDay();
  const firstDayOfWeek = new Date(today);
  firstDayOfWeek.setDate(today.getDate() - currentDayOfWeek + 1); // Move to the start of the week (Monday)

  const datesOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const newDate = formatWeekViewDate(new Date(firstDayOfWeek.getTime() + i * 24 * 60 * 60 * 1000));
    datesOfWeek.push(newDate);
  }
  return datesOfWeek;
}

export async function deleteDiaryEntry(entryid, lessonid, teacherid, setDiaryNotification, setRefreshRequired, refreshRequired, entrypos, date) {
  logger.trace('deleteDiaryEntry');

  const teacher = await getStoredTeacher();

  axios
    .delete(`/diary/delete/${entryid}`, { params: { lessonid, teacherid, date, entrypos } })
    .then((response) => {
      setRefreshRequired(refreshRequired !== true);

      if (response.status === 200) {
        setDiaryNotification({ show: true, warn: false, message: 'Diary updated successfully' });
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      } else {
        setDiaryNotification({ show: true, warn: true, message: 'Something went wrong updating the diary' });
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      }
    })
    .catch((error) => {
      logger.error(error, 'Error deleting diary entry');
    });

  logActivity(teacher.teacherid, 'Diary', 'Delete');
}

export async function apiDiaryAppend(starterid, title, className, date, setRefreshRequired, refreshRequired, setDiaryNotification, closeModal) {
  logger.trace('apiDiaryAppend');

  const teacher = await getStoredTeacher();
  const { teacherid } = teacher;
  const input = {
    teacherEmail: teacher.email,
    starterid,
    title,
    description: className,
    date,
    teacherid
  };

  axios
    .post('/diary/append', input)
    .then((response) => {
      if (refreshRequired === true) {
        setRefreshRequired(false);
      } else {
        setRefreshRequired(true);
      }
      if (response.status === 200) {
        setDiaryNotification({ show: true, warn: false, message: 'Lesson added to Diary' });
        closeModal();
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      } else {
        setDiaryNotification({ show: true, warn: true, message: 'Something went wrong updating the diary' });
        closeModal();
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      }
    })
    .catch((error) => {
      logger.error(error);
    });
  logActivity(teacher.teacherid, 'Diary', 'Append');
}

export async function moveDiaryEntry(entryid, oldDate, newDate, currentIndex, setRefreshRequired, refreshRequired, setDiaryNotification) {
  const newEntryPos = currentIndex;
  const teacher = await getStoredTeacher();

  const { teacherid } = teacher;
  const input = { entryid, oldDate, newDate, newEntryPos, teacherid };
  axios
    .post('/diary/move/entry', input)
    .then((response) => {
      if (response.status === 200) {
        setDiaryNotification({ show: true, warn: false, message: 'Diary updated successfully' });
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      } else {
        setDiaryNotification({ show: true, warn: true, message: 'Something went wrong updating the diary' });
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      }

      if (response.status === 200) {
        if (refreshRequired === true) {
          setRefreshRequired(false);
        } else {
          setRefreshRequired(true);
        }
      }
    })
    .catch((error) => {
      logger.error(error);
    });
  logActivity(teacher.teacherid, 'Diary', 'Move');
}

export function fetchEntries(onFetchEntries) {
  logger.trace('onFetchEntries');

  axios
    .get('/diary/entries')
    .then((response) => {
      if (response.status === 200) {
        onFetchEntries(response.data.diary);
      }
    })
    .catch((error) => {
      logger.error(error, 'GET /diary/entries');
    });
}

export function editEntry(idOfEntry, onEditEntry) {
  logger.trace('editEntry');

  logger.info('Editing Entry: ', idOfEntry);

  axios
    .get(`/diary/entry/${idOfEntry}`)
    .then((response) => {
      if (response.status === 200) {
        onEditEntry(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `GET /entry/${idOfEntry}`);
    });
}

export function createEntry(data, onCreateEntry) {
  logger.trace('createEntry');

  axios
    .post('/diary/entry/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Entry created...');
        onCreateEntry();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /diary/entry/create');
    });
}

export async function deleteEntry(idOfEntry, onDeleteEntry) {
  const teacher = await getStoredTeacher();
  const { teacherid } = teacher;

  axios
    .delete(`/diary/entry/${idOfEntry}`, { teacherid })
    .then((response) => {
      if (response.status === 200) {
        logger.info('entry deleted...');
        onDeleteEntry();
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /entry/${idOfEntry}`);
    });
}

export async function updateEntry(data, onUpdateEntry) {
  logger.trace('updateEntry');

  const teacher = await getStoredTeacher();

  axios
    .post('/diary/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Entry updated...');
        onUpdateEntry();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /entry/update');
    });
  logActivity(teacher.teacherid, 'Diary', 'Update');
}

export async function updateExistingEntry(
  className,
  entryid,
  starter,
  date,
  title,
  setRefreshRequired,
  refreshRequired,
  setDiaryNotification,
  closeModal,
  isDateUpdate,
  setIsDateUpdate
) {
  logger.trace('updateExistingEntry');

  const teacher = await getStoredTeacher();
  const { teacherid } = teacher;

  axios
    .post(`/diary/update/${entryid}`, { className, starter, date, title, isDateUpdate, teacherid })
    .then((response) => {
      if (response.status === 200) {
        setDiaryNotification({ show: true, warn: false, message: 'Lesson added to Diary' });
        closeModal();
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      } else {
        setDiaryNotification({ show: true, warn: true, message: 'Something went wrong updating the diary' });
        closeModal();
        setTimeout(() => {
          setDiaryNotification({ show: false, warn: false, message: '' });
        }, 3000);
      }
      setIsDateUpdate(false);
      if (refreshRequired === true) {
        setRefreshRequired(false);
      } else {
        setRefreshRequired(true);
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /diary/update/:entryid');
    });

  logActivity(teacher.teacherid, 'Diary', 'Update - Class Name');
}
