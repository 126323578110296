import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const teachersApi = require('../../helpers/api/teachers');
const feedbackApi = require('../../helpers/api/feedback');
const datetimeApi = require('../../helpers/datetime');
const activityApi = require('../../helpers/api/activity');

function AdminFeedback() {
  logger.trace('AdminFeedback');

  const [errorMessages, setErrorMessages] = useState([]);

  const [feedbacks, setFeedbacks] = useState([]);

  const [teachers, setTeachers] = useState([]);

  const [senderid, setSenderID] = useState(0);
  const [datesent, setDateSent] = useState(new Date());
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(1);

  const nav = useNavigate();
  const theTeacher = user.getStoredTeacher();

  function resetVariables() {
    logger.trace('resetVariables');

    setSenderID(0);
    setDateSent(new Date());
    setFeedback('');
    setRating(1);
  }

  function onFetchFeedbacks(listOfFeedbacks) {
    logger.trace('onFetchFeedbacks');
    setFeedbacks(listOfFeedbacks);
  }

  function onFetchTeachers(listOfTeachers) {
    logger.trace('onFetchTeachers');
    setTeachers(listOfTeachers);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (senderid === null || senderid === 0) {
      errors.push('Invalid Teacher!');
    }

    if (feedback === null || feedback === '') {
      errors.push('Invalid Feedback!');
    }

    if (rating < 1 || rating > 5) {
      errors.push('Invalid Rating!');
    }

    return errors;
  }

  function onCreateFeedback() {
    logger.trace('createFeedback');

    activityApi.logActivity(theTeacher.teacherid, 'AdminFeedback', 'Create');

    resetVariables();
    feedbackApi.fetchFeedbacks(onFetchFeedbacks);
    teachersApi.fetchTeachers(onFetchTeachers);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        senderid,
        datesent,
        feedback,
        rating
      };

      feedbackApi.createFeedback(postData, onCreateFeedback);
    }
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(theTeacher.teacherid, 'AdminFeedback', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    feedbackApi.fetchFeedbacks(onFetchFeedbacks);
    teachersApi.fetchTeachers(onFetchTeachers);
  }, [theTeacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Sent by', prop: 'sentby', isSortable: true, isFilterable: true },
    { title: 'Sent On', prop: 'senton', isSortable: true, isFilterable: true },
    { title: 'Feedback', prop: 'feedback', isSortable: true, isFilterable: true },
    { title: 'Rating', prop: 'rating', isSortable: true, isFilterable: true }
  ];

  const body = [];
  feedbacks.forEach((theFeedback) => {
    body.push({
      theFeedback,
      id: theFeedback.feedbackid,
      sentby: teachersApi.lookupTeacher(teachers, theFeedback.senderid),
      senton: datetimeApi.formatDateTime(theFeedback.datesent),
      feedback: theFeedback.feedback,
      rating: theFeedback.rating
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            <h2>New Feedback</h2>

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newfeedback-senderid">
                  Sender:
                  <select className="form-control" name="senderid" id="newfeedback-senderid" onChange={(e) => setSenderID(e.target.value)} value={senderid}>
                    <option value="">--Please choose a teacher--</option>
                    {teachers.map((teacher) => (
                      <option key={teacher.teacherid} value={teacher.teacherid}>
                        {teacher.firstname} {teacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-datesent">
                  Date Sent:
                </label>
                <div>
                  <DateTimePicker format="dd/MM/y h:mm:ss a" disableClock onChange={(e) => setDateSent(e)} value={datesent} />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newfeedback-feedback">
                  Feedback:
                  <textarea
                    className="form-control"
                    rows="8"
                    cols="50"
                    name="feedback"
                    id="newfeedback-feedback"
                    placeholder="Enter feedback"
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newfeedback-feedback">
                  Rating:
                  <select className="form-control" name="rating" id="newfeedback-rating" onChange={(e) => setRating(e.target.value)} value={rating}>
                    <option>--Please choose a rating--</option>
                    <option key={1} value={1}>
                      {1}
                    </option>
                    <option key={2} value={2}>
                      {2}
                    </option>
                    <option key={3} value={3}>
                      {3}
                    </option>
                    <option key={4} value={4}>
                      {4}
                    </option>
                    <option key={5} value={5}>
                      {5}
                    </option>
                  </select>
                </label>
              </div>
              <input className="btn btn-primary" type="submit" value="Create Feedback" />
            </form>

            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Feedback</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="feedback.csv" data={feedbacks}>
                    Download Feedback
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminFeedback;
