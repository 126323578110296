import axios from 'axios';

const logger = require('../../logger');

export function fetchTags(onFetchTags) {
  logger.trace('fetchTags');

  axios
    .get('/tags')
    .then((response) => {
      if (response.status === 200) {
        onFetchTags(response.data.tags);
      }
    })
    .catch((error) => {
      logger.error(error, '/tags');
    });
}

export function fetchTagsForStarter(idOfStarter, onFetchTags) {
  logger.trace('fetchTags');

  axios
    .get(`/tags/starter/${idOfStarter}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchTags(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/tags');
    });
}

export function createTag(data, onCreateTag) {
  logger.trace('createTag');

  logger.info('Creating Tag:');

  axios
    .post('/tag/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Tag created...');
        onCreateTag();
      }
    })
    .catch((error) => {
      logger.error(error, '/tag/create');
    });
}

export function updateTag(data, onUpdateTag) {
  logger.trace('updateTag');

  axios
    .post('/tag/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Tag updated...');
        onUpdateTag();
      }
    })
    .catch((error) => {
      logger.error(error, '/tag/update');
    });
}

export function deleteTag(idOfTag, onDeleteTag) {
  logger.trace('deleteTag');

  if (window.confirm('Are you sure you wish to delete this tag?')) {
    logger.info('Deleting Tag: ', idOfTag);

    axios
      .delete(`/tag/${idOfTag}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Tag deleted...');
          onDeleteTag();
        }
      })
      .catch((error) => {
        logger.error(error, '/tag/');
      });
  }
}

export function editTag(idOfTag, onEditTag) {
  logger.trace('editTag');

  logger.info('Editing Tag: ', idOfTag);

  axios
    .get(`/tag/${idOfTag}`)
    .then((response) => {
      if (response.status === 200) {
        onEditTag(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/tag/');
    });
}

export function lookupTag(listOfTags, idOfTag) {
  logger.trace('lookupTag');

  for (let i = 0; i < listOfTags.length; i += 1) {
    if (listOfTags[i].tagid === idOfTag) {
      return listOfTags[i].title;
    }
  }
  return idOfTag;
}
