// Package imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

// Component imports
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

Modal.setAppElement('#root');
