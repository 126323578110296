import axios from 'axios';

const logger = require('../../logger');

export const MOTD = 'motd';

export function getSysProp(name, onGetSysProp) {
  logger.trace('getSysProp');

  axios
    .get(`/systemprop/${name}`)
    .then((response) => {
      if (response.status === 200) {
        onGetSysProp(response.data.value);
      }
    })
    .catch((error) => {
      logger.error(error, `/systemprop/${name}`);
    });
}

export function dummy() {}
