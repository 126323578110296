import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../../styles/ForgotPassword.css';

const user = require('../../helpers/user');
const logger = require('../../logger');
const activityApi = require('../../helpers/api/activity');
const teachersApi = require('../../helpers/api/teachers');

function NewPassword() {
  logger.trace('ForgotPassword');

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errormsg, setErrorMsg] = useState('');

  const teacher = user.getStoredTeacher();
  const nav = useNavigate();

  const { resetToken } = useParams();

  function validateForm() {
    logger.trace('validateForm');
    return password.length > 0 && password2.length > 0;
  }

  function passwordChange(text) {
    logger.trace('passwordChange');
    setPassword(text);
    validateForm();
  }

  function password2Change(text) {
    logger.trace('passwordChange');
    setPassword2(text);
    validateForm();
  }

  function onResetPassword(success, errorMsg) {
    if (success === true) {
      activityApi.logActivity(0, 'NewPasswordPage', 'Reset Password');
      nav('/signin');
      return;
    }

    setErrorMsg(errorMsg);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    if (password !== password2) {
      setErrorMsg('Passwords do not match!');
      return;
    }
    if (user.validatePassword(password) === false) {
      setErrorMsg('Password must use a combination of A-Z, a-z, 0-9 plus atleast 1 special character and be longer than 8');
    }

    if (resetToken === undefined || resetToken === null || resetToken.length === 0) {
      nav('/lessonstarters');
    }

    const token = resetToken.split('=')[1];
    teachersApi.resetPassword(token, password, onResetPassword);
  }

  useEffect(() => {
    if (teacher !== undefined && teacher !== null) {
      nav('/lessonstarters');
    }
  }, [teacher]);

  return (
    <div className="fplayout">
      <form className="fpform" onSubmit={(event) => handleFormSubmit(event)}>
        <img className="fplogo" src="/images/dressCodeLogo.png" alt="logo" />
        <div className="fpline1" />
        <h3 className="fpheadline">Enter a New Password</h3>
        {errormsg.length > 0 && <span>{errormsg}</span>}
        <input className="fpinputText" type="text" id="newPass" placeholder="New Password" required onChange={(e) => passwordChange(e.target.value)} />
        <input className="fpinputText" type="text" id="confirmNewPass" placeholder="Confirm New Password" required onChange={(e) => password2Change(e.target.value)} />
        <button className="fpbutton" type="submit">
          Reset password
        </button>

        <p className="fpsignup">
          Don’t have an account? <a href="/signup">Sign up</a>
        </p>
      </form>
    </div>
  );
}

export default NewPassword;
