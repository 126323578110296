/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import Tags from '../../components/common/Tags';
import Hierarchy from '../../components/common/Hierarchy';
import { deleteDiaryEntry } from '../../helpers/api/diary';
import { formatForDelete } from '../../helpers/diary';

const logger = require('../../logger');

function DiaryCard({
  starter,
  setDiaryNotification,
  setRefreshRequired,
  refreshRequired,
  setStarterBeingDragged,
  setIsModalUpdate,
  setModalOpen,
  setModalUpdateClassName,
  setModalUpdateDate,
  setStarterForModal,
  setTitleForModal
}) {
  logger.trace('DiaryCard');

  const nav = useNavigate();

  if (starter === null) {
    return <h1>Can&apos;t get starter...</h1>;
  }

  const handleEdit = () => {
    setStarterForModal(starter);
    setStarterBeingDragged(starter);
    setModalUpdateClassName(starter.description);
    setTitleForModal(starter.title);
    setModalUpdateDate(starter.date);
    setIsModalUpdate(true);
    setModalOpen(true);
  };

  function deleteEntry() {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      deleteDiaryEntry(
        starter.entryid,
        starter.starterData.starterid,
        starter.teacherid,
        setDiaryNotification,
        setRefreshRequired,
        refreshRequired,
        starter.entrypos,
        formatForDelete(starter.date)
      );
    }
  }

  return (
    <div draggable className="diary-card-container">
      <div className="diary-card">
        <AiFillDelete color="#FE6464" size={20} className="diary-card-delete-btn" onClick={() => deleteEntry()} />
        <AiOutlineEdit color="grey" size={20} onClick={handleEdit} className="diary-card-edit-btn" />
        <p onClick={() => nav(`/lessonstarter/${starter.starterData.starterid}`)} className="diary-card-title">
          {starter.starterData.data.title ? starter.starterData.data.title : 'Loading...'}
        </p>
        <p className="diary-card-description">{starter.description}</p>
        <div className="diary-card-tags">{starter.tags !== undefined && starter.tags.length > 0 ? <Tags tags={starter.tags} /> : <div />}</div>
        <div className="diary-card-hierarchy">
          {starter.starterData.hierarchies.map((hierarchy, index) => (
            <div key={index}>
              <Hierarchy hierarchy={hierarchy} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

DiaryCard.propTypes = {
  starter: PropTypes.object,
  setDiaryNotification: PropTypes.func.isRequired,
  setRefreshRequired: PropTypes.func.isRequired,
  refreshRequired: PropTypes.bool.isRequired,
  setStarterBeingDragged: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func,
  setIsModalUpdate: PropTypes.func.isRequired,
  setModalUpdateClassName: PropTypes.func.isRequired,
  setModalUpdateDate: PropTypes.func.isRequired,
  setStarterForModal: PropTypes.func.isRequired,
  setTitleForModal: PropTypes.func.isRequired
};

DiaryCard.defaultProps = {
  starter: {
    data: 'Loading',
    tags: ['Software development']
  },
  setModalOpen: undefined
};

export default DiaryCard;
