import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';
import logger from '../../logger';

const user = require('../../helpers/user');
const coursesApi = require('../../helpers/api/courses');
const subjectsApi = require('../../helpers/api/subjects');
const activityApi = require('../../helpers/api/activity');
const adminAccess = require('./access');

function AdminCourse() {
  logger.trace('AdminCourse');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);

  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [courseid, setCourseID] = useState(0);

  const [subjectid, setSubjectID] = useState(0);
  const [name, setName] = useState('');

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function onFetchCousres(listOfCourses) {
    setCourses(listOfCourses);
  }

  function onFetchSubjects(listOfSubjects) {
    setSubjects(listOfSubjects);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setSubjectID(0);
    setName('');
  }

  function setVariables(data) {
    logger.trace('setVariables');

    setSubjectID(data.subjectid);
    setName(data.name);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (subjectid == null || subjectid === 0) {
      errors.push('Invalid subject!');
    }

    if (name == null || name.length === 0) {
      errors.push('Name cannot be blank!');
    }

    return errors;
  }

  function onCreateCourse() {
    logger.trace('onCreateCourse');
    setSubjectID(0);
    setName('');

    activityApi.logActivity(teacher.teacherid, 'AdminCourse', 'Create');

    coursesApi.fetchCourses(onFetchCousres);
    subjectsApi.fetchSubjects(onFetchSubjects);
  }

  function onUpdateCourse() {
    logger.trace('onUpdateCourse');

    activityApi.logActivity(teacher.teacherid, 'AdminCourse', 'Update');

    setCreate(true);
    setCourseID(0);
    resetVariables();

    coursesApi.fetchCourses(onFetchCousres);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const data = {
        subjectid,
        name
      };

      if (create === true) {
        coursesApi.createCourse(data, onCreateCourse);
      } else {
        data.courseid = courseid;
        coursesApi.updateCourse(data, onUpdateCourse);
      }
    }
  }

  function onEditCourse(data) {
    logger.trace('onEditCourse');

    activityApi.logActivity(teacher.teacherid, 'AdminCourse', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setCourseID(data.courseid);
    setCreate(false);
    setVariables(data);
  }

  function onDeleteCourse() {
    logger.trace('onDeleteCourse');

    activityApi.logActivity(teacher.teacherid, 'AdminCourse', 'Delete');

    setCreate(true);
    coursesApi.fetchCourses(onFetchCousres);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');

    logger.info('Cancelling edit');
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminCourse', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    coursesApi.fetchCourses(onFetchCousres);
    subjectsApi.fetchSubjects(onFetchSubjects);
  }, [teacher.teacherid, nav]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Subject', prop: 'subject', isSortable: true, isFilterable: true },
    { title: 'Course', prop: 'name', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => coursesApi.editCourse(row.course.courseid, onEditCourse)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => coursesApi.deleteCourse(row.course.courseid, onDeleteCourse)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  courses.forEach((course) => {
    body.push({
      course,
      id: course.courseid,
      subject: subjectsApi.lookupSubject(subjects, course.subjectid),
      name: course.name,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10">
            <div className="container bg-white">
              {create === true && <h2>New Course</h2>}
              {create === false && <h2>Update Course</h2>}

              {errorMessages.length > 0 && (
                <div>
                  <ul>
                    {errorMessages.map((message) => (
                      <li key={message}>{message}</li>
                    ))}
                  </ul>
                </div>
              )}

              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="newcourse-subjectid">
                    Subject:
                    <select className="form-control" name="subjectid" id="newcourse-subjectid" onChange={(e) => setSubjectID(e.target.value)} value={subjectid}>
                      <option value="">--Please choose a subject--</option>
                      {subjects.map((subject) => (
                        <option key={subject.subjectid} value={subject.subjectid}>
                          {subject.name}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="newcourse-course">
                    Name:
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="newcourse-course"
                      placeholder="Enter course name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </label>
                </div>
                {create === true && <input className="btn btn-primary" type="submit" value="Create Course" />}
                {create === false && (
                  <>
                    <input className="btn btn-primary" type="submit" value="Update Course" />
                    <button className="btn btn-secondary" value="Cancel" type="submit" onClick={() => cancelEdit()}>
                      Cancel
                    </button>
                  </>
                )}
              </form>
            </div>

            <div className="container bg-white">
              <hr />
            </div>

            <div className="container bg-white">
              <div>
                <div>
                  <h2>Courses</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="courses.csv" data={courses}>
                    Download Courses
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminCourse;
