import axios from 'axios';

const logger = require('../../logger');

export function fetchSchoolTeachers(onFetchSchoolTeachers) {
  logger.trace('fetchSchoolTeachers');

  axios
    .get('/schoolteachers')
    .then((response) => {
      if (response.status === 200) {
        onFetchSchoolTeachers(response.data.schoolteachers);
      }
    })
    .catch((error) => {
      logger.error(error, '/schoolteachers');
    });
}

export function createSchoolTeacher(data, onCreateSchoolTeacher) {
  logger.trace('createSchoolTeacher');

  axios
    .post('/schoolteacher/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('SchoolTeacher created...');
        onCreateSchoolTeacher();
      }
    })
    .catch((error) => {
      logger.error(error, '/schoolteacher/create');
    });
}

export function updateSchoolTeacher(data, onUpdateSchoolTeacher) {
  logger.trace('updateSchoolTeacher');

  axios
    .post('/schoolteacher/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('School teacher updated...');
        onUpdateSchoolTeacher();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /schoolteacher/update');
    });
}

export function deleteSchoolTeacher(idOfSchoolTeacher, onDeleteSchoolTeacher) {
  logger.trace('deleteSchoolTeacher');

  if (window.confirm('Are you sure you wish to delete this School Teacher link?')) {
    logger.info('Deleting School Teacher: ', idOfSchoolTeacher);

    axios
      .delete(`/schoolteacher/${idOfSchoolTeacher}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('School Teacher deleted');
          onDeleteSchoolTeacher();
        }
      })
      .catch((error) => {
        logger.error(error, '/schoolteacher/');
      });
  }
}

export function deleteSchoolTeachersForTeacher(teacherid, onDeleteSchoolTeachers) {
  axios
    .delete(`/schoolteachers/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        if (onDeleteSchoolTeachers !== null) {
          onDeleteSchoolTeachers();
        }
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /schoolteachers/${teacherid}`);
    });
}

export function editSchoolTeacher(idOfSchoolTeacher, onEditSchoolteacher) {
  logger.trace('editSchoolTeacher');

  logger.info('Editing school teacher');

  axios
    .get(`/schoolteacher/${idOfSchoolTeacher}`)
    .then((response) => {
      if (response.status === 200) {
        onEditSchoolteacher(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/schoolteacher/');
    });
}
