import axios from 'axios';

const logger = require('../../logger');

const messagesApi = require('./messages');
const feedbackApi = require('./feedback');
const schoolteachersApi = require('./schoolteachers');
const favouritesApi = require('./favourites');
const ratingsApi = require('./ratings');

export function fetchTeachers(setFunc) {
  logger.trace('fetchTeachers');

  axios
    .get('/teachers')
    .then((response) => {
      if (response.status === 200) {
        setFunc(response.data.teachers);
      }
    })
    .catch((error) => {
      logger.error(error, 'GET /teachers');
    });
}

export function fetchTeachersWithSchool(onFetchTeachersWithSchool) {
  logger.trace('fetchTeachersWithSchool');

  axios
    .get('/teachers/school')
    .then((response) => {
      onFetchTeachersWithSchool(response.data.teachers);
    })
    .catch((error) => {
      logger.error(error, '/teachers/school');
    });
}

export function lookupTeacher(listOfTeachers, idOfTeacher) {
  logger.trace('lookupTeacher');

  if (idOfTeacher === 0) {
    return 'System';
  }

  for (let i = 0; i < listOfTeachers.length; i += 1) {
    if (listOfTeachers[i].teacherid === idOfTeacher) {
      if (listOfTeachers[i].firstname === 'admin') {
        return 'Administrator';
      }
      return `${listOfTeachers[i].firstname}`;
    }
  }
  return idOfTeacher;
}

export function doesEmailExist(email, onEmailExists) {
  logger.trace('fetchTeachersWithSchool');

  axios
    .get(`/teacherByEmail/${email}`)
    .then((response) => {
      if (response.status === 200) {
        onEmailExists(true);
      } else {
        onEmailExists(false);
      }
    })
    .catch((error) => {
      logger.error(error, '/teacherByEmail/:email');
      onEmailExists(false);
    });
}

export function formatTeacherName(teacher) {
  if (teacher.firstname === 'admin') {
    return 'Administrator';
  }
  return `${teacher.firstname} ${teacher.lastname}`;
}

export function lookupTeacherFullname(listOfTeachers, idOfTeacher) {
  logger.trace('lookupTeacher');

  if (idOfTeacher === 0) {
    return 'System';
  }

  for (let i = 0; i < listOfTeachers.length; i += 1) {
    if (listOfTeachers[i].teacherid === idOfTeacher) {
      return formatTeacherName(listOfTeachers[i]);
    }
  }
  return idOfTeacher;
}

export function createTeacher(data, onCreateTeacher) {
  logger.trace('createTeacher');

  axios
    .post('/teacher/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Teacher created...', response.data.teacherid);
        onCreateTeacher(response.data);
      } else {
        logger.info('Teacher create failed...', response.status, response.statusText);
      }
    })
    .catch((error) => {
      logger.error(error, '/teacher/create');
    });
}

export function updateTeacher(data, onUpdateTeacher) {
  logger.trace('updateTeacher');

  axios
    .post('/teacher/update', data)
    .then((response) => {
      if (response.status === 200) {
        onUpdateTeacher();
      }
    })
    .catch((error) => {
      logger.error(error, '/teacher/update');
    });
}

export function editTeacher(idOfTeacher, onEditTeacher) {
  logger.trace('editTeacher');

  logger.info('Editing teacher');

  axios
    .get(`/teacher/${idOfTeacher}`)
    .then((response) => {
      if (response.status === 200) {
        onEditTeacher(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/teacher/');
    });
}

function deleteTeacherAccount(idOfTeacher, onDeleteAccount) {
  logger.trace('deleteTeacherAccount');

  axios
    .delete(`/teacher/${idOfTeacher}`)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Teacher deleted');
        if (onDeleteAccount !== null) {
          onDeleteAccount();
        }
      }
    })
    .catch((error) => {
      logger.error(error, '/teacher/');
    });
}

export function deleteTeacher(idOfTeacher, onDeleteTeacher) {
  logger.trace('deleteTeacher');

  if (window.confirm('Are you sure you wish to delete this teacher?')) {
    logger.info('Deleting teacher', idOfTeacher);
    deleteTeacherAccount(idOfTeacher, onDeleteTeacher);
  }
}

export function deleteAccount(idOfTeacher, onDeleteAccount) {
  logger.trace('deleteAccount');

  if (
    window.confirm(
      'Are you sure you want to delete your Account, this will remove all activity, including all lessons, messages, feedback and ratings you may have created. There is no opportunity to recover from this activity. Are you sure ?'
    )
  ) {
    if (window.confirm('Are you really sure ?')) {
      logger.info('Deleting user account');

      // Delete messages
      messagesApi.deleteMessagesForTeacher(idOfTeacher, null);

      // Delete feedback
      feedbackApi.deleteFeedbacksForTeacher(idOfTeacher, null);

      // Delete School teachers
      schoolteachersApi.deleteSchoolTeachersForTeacher(idOfTeacher, null);

      // Delete Favourites
      favouritesApi.deleteFavouritesForTeacher(idOfTeacher, null);

      // Delete Ratings
      ratingsApi.deleteRatingsForTeacher(idOfTeacher, null);

      // Finally Delete the Teacher
      deleteTeacherAccount(idOfTeacher, onDeleteAccount);
    }
  }
}

export function resetPassword(token, password, onResetPassword) {
  const data = {
    resetToken: token,
    password
  };

  axios
    .post('/teacher/updatepassword', data)
    .then((response) => {
      logger.info(response);
      if (response.status === 200) {
        onResetPassword(true);
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /teacher/updatepassword');
    });

  onResetPassword(false, 'Failed to reset password');
}
