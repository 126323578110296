import { useState } from 'react';
import PropTypes from 'prop-types';

import logger from '../../../logger';

function MultiChoiceStarterEdit(props) {
  logger.trace('MultiChoiceStarterEdit');

  const [title, setTitle] = useState(props.title);
  const [src, setSrc] = useState(props.src);
  const [src2, setSrc2] = useState(props.src2);
  const [imageAlt, setMultiChoiceAlt] = useState(props.imageAlt);

  MultiChoiceStarterEdit.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    src2: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    onChildChange: PropTypes.func
  };

  MultiChoiceStarterEdit.defaultProps = {
    onChildChange: null
  };

  function somethingChanged(target) {
    logger.trace('somethingChanged');

    const data = { title, src, src2, imageAlt };

    if (target.name === 'title') {
      setTitle(target.value);
      data.title = target.value;
    }

    if (target.name === 'src') {
      setSrc(target.value);
      data.src = target.value;
    }

    if (target.name === 'src2') {
      setSrc2(target.value);
      data.src2 = target.value;
    }

    if (target.name === 'imageAlt') {
      setMultiChoiceAlt(target.value);
      data.imageAlt = target.value;
    }

    if (props.onChildChange !== undefined && props.onChildChange !== null) {
      props.onChildChange(data);
    } else {
      logger.warn('No onChildChange defined for MultiChoiceStarterEdit');
    }
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-title">
          Title:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="title"
          id="newcon-title"
          placeholder="Enter lesson starter title"
          onChange={(e) => somethingChanged(e.target)}
          value={title}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-src">
          Question:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="src"
          id="newcon-src"
          placeholder="Enter MultiChoice embedded link"
          onChange={(e) => somethingChanged(e.target)}
          value={src}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-src">
          Answer:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="src2"
          id="newcon-src2"
          placeholder="Enter MultiChoice embedded link"
          onChange={(e) => somethingChanged(e.target)}
          value={src2}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-imageAlt">
          MultiChoice Alt:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="imageAlt"
          id="newcon-imageAlt"
          placeholder="MultiChoice Alternative"
          onChange={(e) => somethingChanged(e.target)}
          value={imageAlt}
        />
      </div>
    </>
  );
}

export default MultiChoiceStarterEdit;
