import PropTypes from 'prop-types';

const logger = require('../../logger');

function Hierarchy(props) {
  logger.trace('Hierarchy');

  Hierarchy.propTypes = {
    hierarchy: PropTypes.array.isRequired
  };

  const { hierarchy } = props;

  function getHierarchyLevel() {
    if (process.env.REACT_APP_HIERACHY_LEVEL === undefined || process.env.REACT_APP_HIERACHY_LEVEL === null) {
      return 3;
    }
    return Number(process.env.REACT_APP_HIERACHY_LEVEL);
  }

  return (
    <>
      {hierarchy[0]}
      {hierarchy.length > 1 && getHierarchyLevel() > 0 && (
        <>
          <i className="bi bi-caret-right-fill" />
          {hierarchy[1]}
        </>
      )}
      {hierarchy.length > 2 && getHierarchyLevel() > 1 && (
        <>
          <i className="bi bi-caret-right-fill" />
          {hierarchy[2]}
        </>
      )}
      {hierarchy.length > 3 && getHierarchyLevel() > 2 && (
        <>
          <i className="bi bi-caret-right-fill" />
          {hierarchy[3]}
        </>
      )}
    </>
  );
}

export default Hierarchy;
