import axios from 'axios';

const logger = require('../../logger');

export function fetchRatings(onFetchRatings) {
  logger.trace('fetchRatings');

  axios
    .get('/ratings')
    .then((response) => {
      if (response.status === 200) {
        onFetchRatings(response.data.ratings);
      }
    })
    .catch((error) => {
      logger.error(error, '/ratings');
    });
}

export function fetchRatingsForStarter(idOfStarter, onFetchRatingsForStarter) {
  logger.trace('fetchRatings');

  axios
    .get(`/ratings/starter/${idOfStarter}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchRatingsForStarter(response.data.ratings);
      }
    })
    .catch((error) => {
      logger.error(error, '/ratings');
    });
}

export function createRating(data, onCreateRating) {
  logger.trace('createRating');

  axios
    .post('/rating/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Rating created...');
        onCreateRating();
      }
    })
    .catch((error) => {
      logger.error(error, '/rating/create');
    });
}

export function updateRating(data, onUpdateRating) {
  logger.trace('updateRating');

  logger.info(`Updating Rating: ${data.ratingid}`);

  axios
    .post('/rating/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Rating updated...');
        onUpdateRating();
      }
    })
    .catch((error) => {
      logger.error(error, '/rating/update');
    });
}

export function deleteRating(idOfRating, onDeleteRating) {
  logger.trace('deleteRating');

  if (window.confirm('Are you sure you wish to delete this rating?')) {
    logger.info('Deleting Rating: ', idOfRating);

    axios
      .delete(`/rating/${idOfRating}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Rating deleted...');
          onDeleteRating();
        }
      })
      .catch((error) => {
        logger.error(error, `/rating/${idOfRating}`);
      });
  }
}

export function deleteRatingsForTeacher(teacherid, onDeleteRatings) {
  axios
    .delete(`/ratings/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        if (onDeleteRatings !== null) {
          onDeleteRatings();
        }
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /ratings/${teacherid}`);
    });
}

export function editRating(idOfRating, onEditRating) {
  logger.trace('editRating');

  logger.info('Editing Rating: ', idOfRating);

  axios
    .get(`/rating/${idOfRating}`)
    .then((response) => {
      if (response.status === 200) {
        onEditRating(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `/rating/${idOfRating}`);
    });
}
