import axios from 'axios';

const logger = require('../../logger');

export function fetchStarters(setLessonStarters) {
  logger.trace('fetchStarters');

  axios
    .get('/starters')
    .then((response) => {
      if (response.status === 200) {
        setLessonStarters(response.data.starters);
      }
    })
    .catch((error) => {
      logger.error(error, '/starters');
    });
}

export function fetchStarter(idOfStarter, onFetchStarter) {
  logger.trace('fetchStarters');

  axios
    .get(`/starter/${idOfStarter}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchStarter(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `/starter/${idOfStarter}`);
    });
}

export function fetchHierarchy(idOfStarter, onFetchHierarchy) {
  axios
    .get(`/starter/hierarchy/${idOfStarter}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchHierarchy(response.data);
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function lookupStarter(listOfStarters, idOfStarter) {
  logger.trace('lookupStarter');

  for (let i = 0; i < listOfStarters.length; i += 1) {
    if (listOfStarters[i].starterid === idOfStarter) {
      return listOfStarters[i].name;
    }
  }
  return idOfStarter;
}

export function createStarter(postData, onCreateStarter) {
  logger.trace('createStarter');

  logger.info('Creating Starter: ', postData.datastarterid);

  axios
    .post('/starter/create', postData)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Starter created...');
        onCreateStarter();
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function updateStarter(postData, onUpdateStarter) {
  logger.trace('updateStarter');

  axios
    .post('/starter/update', postData)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Starter updated...');
        onUpdateStarter();
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function editStarter(idOfStarter, onEditStarter) {
  logger.trace('editStarter');

  logger.info('Editing Starter: ', idOfStarter);

  axios
    .get(`/starter/${idOfStarter}`)
    .then((response) => {
      if (response.status === 200) {
        onEditStarter(response.data);
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function deleteStarter(idOfStarter, onDeleteStarter) {
  logger.trace('deleteStarter');

  logger.info('Deleeting Starter: ', idOfStarter);

  if (window.confirm('Are you sure you wish to delete this Lesson Starter?')) {
    logger.info('Deleting lesson starter:', idOfStarter);

    axios
      .delete(`/starter/${idOfStarter}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Starter created...');
          onDeleteStarter();
        }
      })
      .catch((error) => {
        logger.error(error);
      });
  }
}

export function filterStarters(filter, onFilterStarters) {
  axios
    .post('/starters/filter', filter)
    .then((response) => {
      if (response.status === 200) {
        onFilterStarters(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/starters/filter');
    });
}

export function starterViewed(idOfStarter) {
  logger.trace('starterViewed');

  axios.get(`/starter/viewed/${idOfStarter}`).catch((error) => {
    logger.error(error);
  });
}
