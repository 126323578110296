import PropTypes from 'prop-types';

import './LessonStarterCard.scss';

import StarterImg from './StarterImg';
import Stars from '../../components/common/Stars';
import Tags from '../../components/common/Tags';
import Hierarchy from '../../components/common/Hierarchy';
import HeartCard from '../../components/common/HeartCard';

function LessonStarterCard(props) {
  LessonStarterCard.propTypes = {
    starter: PropTypes.object.isRequired,
    changeFavourite: PropTypes.func.isRequired
  };

  const { starter, changeFavourite } = props;

  return (
    <div className="lesson-starter-card">
      <div className="image">
        <StarterImg starter={starter} />
      </div>

      <div className="description">
        <h3 className="title">
          <a className="card-link" href={`/lessonstarter/${starter.starterid}`}>
            {starter.name}
          </a>
        </h3>
        <div>
          <HeartCard
            id={starter.starterid}
            favourite={starter.data.favourite !== undefined ? starter.data.favourite : false}
            changeFavourite={(id, state) => {
              changeFavourite(id, state);
            }}
          />
        </div>
        <div>{starter.data.tags !== undefined && starter.data.tags.length > 0 && <Tags tags={starter.data.tags} />}</div>

        {starter.data.hierarchies.map((hierarchy, index) => (
          <div key={index} className="m1">
            <Hierarchy hierarchy={hierarchy} />
          </div>
        ))}

        <div>
          <div className="m2">
            <Stars numOfStars={starter.rating !== null ? starter.rating : 0} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonStarterCard;
