import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/SignInPage.css';

const user = require('../../helpers/user');
const logger = require('../../logger');

const usersApi = require('../../helpers/api/users');
const activityApi = require('../../helpers/api/activity');

function SignIn() {
  logger.trace('SignIn');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [errormsg, setErrorMsg] = useState('');

  const teacher = user.getStoredTeacher();
  const nav = useNavigate();

  function validateForm() {
    logger.trace('validateForm');
    return email.length > 0 && password.length > 0;
  }

  function onLogin(success, data) {
    if (success === true) {
      user.addStoredTeacher(data);
      activityApi.logActivity(data.teacherid, 'SignInPage', 'Login');
      nav('/lessonstarters');
    } else {
      setErrorMsg(data);
    }
  }

  useEffect(() => {
    if (teacher !== undefined && teacher !== null) {
      nav('/lessonstarters');
    }
    setShowPassword(false);
  }, [teacher]);

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');
    event.preventDefault();
    usersApi.login(email, password, onLogin);
  }

  return (
    <div className="silayout">
      <form className="siform" onSubmit={(event) => handleFormSubmit(event)}>
        <img className="silogo" src="/images/dressCodeLogo.png" alt="logo" />
        <div className="siline1" />
        <h3 className="siheadline">Log In</h3>
        {errormsg.length > 0 && <span>{errormsg}</span>}
        <input
          className="siinputText simailPlaceholder"
          type="email"
          id="emailAddress"
          placeholder="Email Address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="sipasswordContainer sipassPlaceholder">
          <div>
            <input
              className="siinputPass sipassPlaceholder"
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <span className="sishowPassword">
              <i
                className="bi bi-eye text-primary"
                onClick={() => {
                  console.log('clicked');
                  setShowPassword(!showPassword);
                }}
              />
            </span>
          </div>
        </div>
        <input className="silogin_button btnText" type="submit" disabled={!validateForm()} value="Log In" />
        <div className="siline2" />
        <p className="sisignup">
          Don’t have an account? <a href="/signup">Sign up</a>
        </p>
        <p className="siforgotpass">
          <a href="/forgotpassword">Forgot Password</a>
        </p>
      </form>
    </div>
  );
}

export default SignIn;
