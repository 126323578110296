/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import DiaryCard from './DiaryCard';
import AddToDiaryFromDiaryModal from './AddToDiaryFromDiaryModal';

import { formatDateForId, formatForFetch } from '../../helpers/diary';
import { fetchStarters } from '../../helpers/api/starters';

const logger = require('../../logger');
const diaryApi = require('../../helpers/api/diary');

function DiaryComponent({ currentWeekDates, currentTeacher, setRefreshRequired, refreshRequired, startersArray }) {
  logger.trace('DiaryComponent');

  const [starterBeingDragged, setStarterBeingDragged] = useState(undefined);
  const [startListDate, setStartListDate] = useState(undefined);
  const [currentIndex, setCurrentIndex] = useState(undefined);
  const [postRequired, setPostRequired] = useState(false);
  const [diaryNotification, setDiaryNotification] = useState({ show: false, message: '' });
  const [dateForModal, setDateForModal] = useState(undefined);
  const [modalopen, setModalOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState('');
  const [dateForUpdate, setDateForUpdate] = useState(undefined);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [modalUpdateClassName, setModalUpdateClassName] = useState('');
  const [allStarters, setAllStarters] = useState([]);
  const [modalUpdateDate, setModalUpdateDate] = useState('');
  const [starterForModal, setStarterForModal] = useState(undefined);
  const [titleForModal, setTitleForModal] = useState(undefined);

  const modalStyles = {
    content: {
      height: '30rem',
      width: '30vw',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      zIndex: 4
    },
    overlay: {
      zIndex: 4
    }
  };

  const updateModalStyles = {
    content: {
      height: '40rem',
      width: '30vw',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      zIndex: 4
    },
    overlay: {
      zIndex: 4
    }
  };

  const selectOptions = [];
  (function generateSelectOptions() {
    logger.trace('generateSelectOptions');

    allStarters.forEach((starter) => {
      selectOptions.push({ label: starter.name, value: starter });
    });
  })();

  const nav = useNavigate();

  function handleDragStart(starterDetails, startingList) {
    logger.trace('handleDragStart');
    setCurrentIndex(undefined);

    setStarterBeingDragged(starterDetails);
    switch (startingList) {
      case 'monday':
        setStartListDate(formatForFetch(currentWeekDates[0]));
        break;
      case 'tuesday':
        setStartListDate(formatForFetch(currentWeekDates[1]));
        break;
      case 'wednesday':
        setStartListDate(formatForFetch(currentWeekDates[2]));
        break;
      case 'thursday':
        setStartListDate(formatForFetch(currentWeekDates[3]));
        break;
      case 'friday':
        setStartListDate(formatForFetch(currentWeekDates[4]));
        break;
      default:
        break;
    }
  }

  function handleModalOpen(day, date) {
    logger.trace('handleModalOpen');

    setCurrentDay(day);
    setDateForModal(formatForFetch(date));
    setModalOpen(true);
  }

  function closeModal() {
    logger.trace('closeModal');

    setIsModalUpdate(false);
    setModalOpen(false);
  }

  function handleListUpdate() {
    logger.trace('handleListUpdate');
  }

  function handleDragDrop(list, date) {
    logger.trace('handleDragDrop');

    setDateForUpdate(formatForFetch(date));

    setPostRequired(postRequired === false);
  }

  useEffect(() => {
    if (currentTeacher === null || currentTeacher === undefined) {
      nav('/signin');
    }
  }, [currentTeacher]);

  useEffect(() => {
    if (currentTeacher === null || currentTeacher === undefined) {
      nav('/signin');
    }
    fetchStarters(setAllStarters);
  }, []);

  useEffect(() => {
    logger.trace('useEffect');

    if (!starterBeingDragged) {
      return;
    }
    diaryApi.moveDiaryEntry(starterBeingDragged.entryid, startListDate, dateForUpdate, currentIndex, setRefreshRequired, refreshRequired, setDiaryNotification);
  }, [postRequired]);

  const dayListRelationship = {
    0: 'monday',
    1: 'tuesday',
    2: 'wednesday',
    3: 'thursday',
    4: 'friday'
  };

  return (
    <div className="diary-list-container">
      {/* MODAL */}
      <Modal isOpen={modalopen} onRequestClose={closeModal} style={isModalUpdate === false ? modalStyles : updateModalStyles} dateForModal={dateForModal}>
        <AddToDiaryFromDiaryModal
          closeModal={closeModal}
          dateForModal={dateForModal}
          selectOptions={selectOptions}
          setDiaryNotification={setDiaryNotification}
          setRefreshRequired={setRefreshRequired}
          refreshRequired={refreshRequired}
          starterBeingDragged={starterBeingDragged}
          isModalUpdate={isModalUpdate}
          modalUpdateClassName={modalUpdateClassName}
          modalUpdateDate={modalUpdateDate}
          currentDay={currentDay}
          starterForModal={starterForModal}
          titleForModal={titleForModal}
        />
      </Modal>

      {/* NOTIFICATION */}
      {diaryNotification.show && diaryNotification.warn === false ? (
        <div className="diaryNotification diaryNotification-good">{diaryNotification.message}</div>
      ) : diaryNotification.show && diaryNotification.warn === true ? (
        <div className="diaryNotification diaryNotification-bad">{diaryNotification.message}</div>
      ) : (
        <div />
      )}

      {startersArray &&
        startersArray.map((starterList, index1) => {
          return (
            <div className="list-container" key={currentWeekDates[index1]}>
              <div
                className="flex-grow-1 date-col-container"
                id={formatDateForId(currentWeekDates[index1])}
                onChange={() => handleListUpdate(dayListRelationship[index1])}
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => handleDragDrop(dayListRelationship[index1], currentWeekDates[index1])}
              >
                <div className="diary-list-title">{dayListRelationship[index1].charAt(0).toUpperCase() + dayListRelationship[index1].substring(1)}</div>
                {starterList !== undefined && starterList.length > 0 ? (
                  starterList.map((starter, index2) => (
                    <div
                      key={starter.entryid}
                      draggable
                      onDragOver={() => setCurrentIndex(index2)}
                      onDragLeave={() => setCurrentIndex(undefined)}
                      onDragStart={() => handleDragStart(starter, dayListRelationship[index1])}
                    >
                      <DiaryCard
                        starter={starter}
                        setDiaryNotification={setDiaryNotification}
                        setRefreshRequired={setRefreshRequired}
                        refreshRequired={refreshRequired}
                        date={currentWeekDates[0]}
                        setStarterBeingDragged={setStarterBeingDragged}
                        setIsModalUpdate={setIsModalUpdate}
                        setModalOpen={setModalOpen}
                        setModalUpdateClassName={setModalUpdateClassName}
                        setModalUpdateDate={setModalUpdateDate}
                        setStarterForModal={setStarterForModal}
                        setTitleForModal={setTitleForModal}
                      />
                    </div>
                  ))
                ) : (
                  <h6 className="centered-text diary-no-cards">You don&apos;t have any lessons scheduled for today</h6>
                )}
              </div>
              <div className="diary-list-add-button" onClick={() => handleModalOpen('monday', currentWeekDates[index1])}>
                +
              </div>
            </div>
          );
        })}
    </div>
  );
}

DiaryComponent.propTypes = {
  currentWeekDates: PropTypes.array,
  dateRangeStarterData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  releventStarters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  currentTeacher: PropTypes.object.isRequired,
  setRefreshRequired: PropTypes.func.isRequired,
  refreshRequired: PropTypes.bool.isRequired,
  startersArray: PropTypes.array.isRequired
};

DiaryComponent.defaultProps = {
  dateRangeStarterData: {
    'January 1 2000': undefined,
    'January 2 2000': undefined,
    'January 3 2000': undefined,
    'January 4 2000': undefined,
    'January 5 2000': undefined,
    'January 6 2000': undefined,
    'January 7 2000': undefined
  },
  currentWeekDates: ['2023-01-01', '2023-01-02', '2023-01-03', '2023-01-04', '2023-01-05', '2023-01-06', '2023-01-07'],
  releventStarters: {}
};

export default DiaryComponent;
