import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const tagsApi = require('../../helpers/api/tags');
const activityApi = require('../../helpers/api/activity');

function AdminTag() {
  logger.trace('AdminTag');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [tagid, setTagID] = useState(0);

  const [tags, setTags] = useState([]);

  const [title, setTitle] = useState('');

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');
    setTitle(data.title);
  }

  function resetVariables() {
    logger.trace('resetVariables');
    setTitle('');
  }

  function onFetchTags(listOfTags) {
    logger.trace('onFetchTags');
    setTags(listOfTags);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (title == null || title.length === 0) {
      errors.push('Title cannot be blank!');
    }

    return errors;
  }

  function onCreateTag() {
    logger.trace('onCreateTag');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Create');

    setTitle('');
    tagsApi.fetchTags(onFetchTags);
  }

  function onUpdateTag() {
    logger.trace('onUpdateTag');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Update');

    setCreate(true);
    setTitle('');
    tagsApi.fetchTags(onFetchTags);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        title
      };

      if (create === true) {
        tagsApi.createTag(postData, onCreateTag);
      } else {
        postData.tagid = tagid;
        tagsApi.updateTag(postData, onUpdateTag);
      }
    }
  }

  function onDeleteTag() {
    logger.trace('onDeleteTag');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Delete');

    setTitle('');
    tagsApi.fetchTags(onFetchTags);
  }

  function onEditTag(tagData) {
    logger.trace('onEditTag');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setTagID(tagData.tagid);
    setCreate(false);
    setVariables(tagData);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    logger.info('Cancelling Edit');
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    tagsApi.fetchTags(onFetchTags);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Tag', prop: 'title', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => tagsApi.editTag(row.tag.tagid, onEditTag)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => tagsApi.deleteTag(row.tag.tagid, onDeleteTag)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  tags.forEach((tag) => {
    body.push({
      tag,
      id: tag.tagid,
      title: tag.title,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>

          <div className="col md-10 bg-white">
            {create === true && <h2>New Tag</h2>}
            {create === false && <h2>Update Tag</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newtag-title">
                  Title:
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    id="newtag-title"
                    placeholder="Enter tag title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Tag" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Tag" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Tags</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="tags.csv" data={tags}>
                    Download Tags
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminTag;
