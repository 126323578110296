const CryptoJS = require('react-native-crypto-js');
const { constants } = require('dresscode-common');
const logger = require('../logger');

const { ACCESS_LEVELS } = constants;

function getLevelsForSelect() {
  return [ACCESS_LEVELS.ADMIN_ACCESS, ACCESS_LEVELS.CREATOR_ACCESS, ACCESS_LEVELS.TEACHER_ACCESS];
}

function formatLevel(level) {
  switch (level) {
    case ACCESS_LEVELS.ADMIN_ACCESS:
      return 'Admin';
    case ACCESS_LEVELS.CREATOR_ACCESS:
      return 'Creator';
    case ACCESS_LEVELS.TEACHER_ACCESS:
      return 'User';
    default:
      return 'Unknown';
  }
}

function addStoredTeacher(teacher) {
  try {
    if (process.env.REACT_APP_LOCAL_ENCRYPT === 'true') {
      const result = CryptoJS.AES.encrypt(JSON.stringify(teacher), process.env.REACT_APP_LOCAL_KEY).toString();
      localStorage.setItem(process.env.REACT_APP_LOCAL_NAME, result);
    } else {
      localStorage.setItem(process.env.REACT_APP_LOCAL_NAME, JSON.stringify(teacher));
    }
  } catch (error) {
    logger.error(error, 'Failed to set teacher');
  }
}

function getStoredTeacher() {
  const storedTeacher = localStorage.getItem(process.env.REACT_APP_LOCAL_NAME);
  if (storedTeacher !== null) {
    try {
      if (process.env.REACT_APP_LOCAL_ENCRYPT === 'true') {
        const result = CryptoJS.AES.decrypt(storedTeacher, process.env.REACT_APP_LOCAL_KEY).toString(CryptoJS.enc.Utf8);
        return JSON.parse(result);
      }

      return JSON.parse(storedTeacher);
    } catch (error) {
      logger.error(error, 'Get teacher failed');
    }
  }
  return null;
}

function addStoredTeacherFromDatabase(dbteacher) {
  const teacherdata = {
    teacherid: dbteacher.teacherid,
    firstname: dbteacher.firstname,
    lastname: dbteacher.lastname,
    email: dbteacher.email,
    level: dbteacher.level
  };
  addStoredTeacher(teacherdata);
}

function removeStoredTeacher() {
  localStorage.removeItem(process.env.REACT_APP_LOCAL_NAME);
}

function isTeacherAdmin(teacher) {
  return teacher.level === ACCESS_LEVELS.ADMIN_ACCESS;
}

function isTeacherCreator(teacher) {
  return teacher.level === ACCESS_LEVELS.CREATOR_ACCESS;
}

function validatePassword(password) {
  // Checkf or length
  if (password.length < 8) {
    logger.error('Password less than 8 characters');
    return false;
  }

  // Check for mixed case
  if (password.match(/[a-z]/) && password.match(/[A-Z]/) === null) {
    logger.error('Passowrd using all one case');
    return false;
  }

  // Check for numbers
  if (password.match(/\d/) === null) {
    logger.error('Passowrd does not include numbers');
    return false;
  }

  // Check for special characters
  if (password.match(/[^a-zA-Z\d]/) === null) {
    logger.error('Passowrd does not include at least one special character');
    return false;
  }

  return true;
}

function validName(name) {
  if (name.length < 2) {
    logger.error('Name < 2');
    return false;
  }

  console.log('Checking name characters');
  const chars = '@#£$%^&*()+={}[]:;|<>?/~`±§';
  for (let i = 0; i < chars.length; i += 1) {
    if (name.indexOf(chars[i]) !== -1) {
      logger.error('Name contains invalid character');
      return false;
    }
  }

  return true;
}

function validateEmail(email) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

function formatTeacherName(teacher) {
  if (teacher === null || teacher === undefined) {
    return 'Unknown';
  }

  if (teacher.firstname === 'admin' && teacher.lastname === 'admin') {
    return 'Administrator';
  }

  return `${teacher.firstname} ${teacher.lastname}`;
}

module.exports = {
  ACCESS_LEVELS,

  getLevelsForSelect,
  formatLevel,

  addStoredTeacher,
  addStoredTeacherFromDatabase,
  getStoredTeacher,
  removeStoredTeacher,
  isTeacherAdmin,
  isTeacherCreator,

  validatePassword,
  validateEmail,
  validName,

  formatTeacherName
};
