import PropTypes from 'prop-types';

const imageHelper = require('../../helpers/image');

function StarterImg(props) {
  StarterImg.propTypes = {
    starter: PropTypes.object.isRequired
  };

  const { starter } = props;

  let imgSrc = null;
  if (starter.data.thumb === undefined) {
    if (starter.data.src !== undefined) {
      imgSrc = starter.data.src;
    } else if (starter.data.image !== undefined) {
      imgSrc = starter.data.image;
    }
  } else {
    imgSrc = starter.data.thumb;
  }

  imgSrc = imageHelper.replaceImagePaths(imgSrc);

  return <img id={starter.starterid} src={imgSrc} alt="" />;
}

export default StarterImg;
