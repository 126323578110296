import PropTypes from 'prop-types';
import { FullScreen } from 'react-full-screen';
import React, { useState } from 'react';

import './QuestionAnswerStarterPrev.css';

import logger from '../../../logger';

const imageHelper = require('../../../helpers/image');

function QuestionAnswerStarterPreview(props) {
  logger.trace('QuestionAnswerStarterPreview');

  QuestionAnswerStarterPreview.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    src2: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    handle: PropTypes.object.isRequired
  };

  const [showFirst, setShowFirst] = useState(true);

  const { title, src, src2, imageAlt, handle } = props;
  console.log(title, src, src2, imageAlt, handle);

  const width = handle !== undefined && handle.active === true ? '100%' : '840px';
  const height = handle !== undefined && handle.active === true ? '100%' : '480px';

  const imgSrc1 = imageHelper.replaceImagePaths(src);
  const imgSrc2 = imageHelper.replaceImagePaths(src2);

  function handleClick() {
    setShowFirst(!showFirst);
  }

  return (
    <div>
      {handle === undefined || (handle.active === false && <h1>{title}</h1>)}
      <div>
        {handle !== undefined && (
          <FullScreen handle={handle}>
            <div className="imagebox" onClick={handleClick}>
              {showFirst === true && <img className="center-fit" height={height} width={width} src={imgSrc1} alt={imageAlt} />}
              {showFirst === false && <img className="center-fit" height={height} width={width} src={imgSrc2} alt={imageAlt} />}
            </div>
          </FullScreen>
        )}
        {handle === undefined && <img height={height} width={width} src={src} alt={imageAlt} />}
      </div>
    </div>
  );
}

export default QuestionAnswerStarterPreview;
