import axios from 'axios';

const logger = require('../../logger');

export function fetchFavourites(onFetchFavourites) {
  logger.trace('fetchFavourites');

  axios
    .get('/favourites')
    .then((response) => {
      if (response.status === 200) {
        onFetchFavourites(response.data.favourites);
      }
    })
    .catch((error) => {
      logger.error(error, 'GET /favourites');
    });
}

export function fetchFavouriteForStarter(starterid, teacherid, onFetchFavouriteForStarter) {
  logger.trace('fetchFavourites');

  axios
    .get(`/favourite/starter/${starterid}/teacher/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchFavouriteForStarter(true);
      } else {
        onFetchFavouriteForStarter(false);
      }
    })
    .catch((error) => {
      logger.error(error, `/favourite/starter/${starterid}/teacher/${teacherid}`);
      onFetchFavouriteForStarter(false);
    });
}

export function deleteFavourite(idOfFavourite, onDeleteFavourite) {
  logger.trace('deleteFavourite');

  if (window.confirm('Are you sure you wish to delete this favourite?')) {
    logger.info('Deleting Favourite: ', idOfFavourite);

    axios
      .delete(`/favourite/${idOfFavourite}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Favourite deleted...');
          onDeleteFavourite();
        }
      })
      .catch((error) => {
        logger.error(error, `DELETE /favourite/${idOfFavourite}`);
      });
  }
}

export function deleteFavouriteNoCheck(idOfFavourite, onDeleteFavourite) {
  logger.trace('deleteFavourite');

  axios
    .delete(`/favourite/${idOfFavourite}`)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Favourite deleted...');
        onDeleteFavourite();
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /favourite/${idOfFavourite}`);
    });
}

export function deleteFavouritesForTeacher(teacherid, onDeleteFavourites) {
  axios
    .delete(`/favourites/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        if (onDeleteFavourites !== null) {
          onDeleteFavourites();
        }
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /favourites/${teacherid}`);
    });
}

export function editFavourite(idOfFavourite, onEditFavourite) {
  logger.trace('editFavourite');

  logger.info('Editing Favourite: ', idOfFavourite);

  axios
    .get(`/favourite/${idOfFavourite}`)
    .then((response) => {
      if (response.status === 200) {
        onEditFavourite(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `GET /favourite/${idOfFavourite}`);
    });
}

export function createFavourite(data, onCreateFavourite) {
  logger.trace('createFavourite');

  axios
    .post('/favourite/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Favourite created...');
        onCreateFavourite();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /favourite/create');
    });
}

export function updateFavourite(data, onUpdateFavourite) {
  logger.trace('updateFavourite');

  axios
    .post('/favourite/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Favourite updated...');
        onUpdateFavourite();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /favourite/update');
    });
}

export function manageFavourite(starterid, teacherid, onDeleteFavourite, onCreateFavourite) {
  axios
    .get(`/favourite/starter/${starterid}/teacher/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        logger.info(`Found favourite for starter: ${starterid} and teacher: ${teacherid}`);
        deleteFavouriteNoCheck(response.data.favouriteid, onDeleteFavourite);
      }
    })
    .catch((error) => {
      if (error.response.status === 404) {
        logger.info(`Favourite starter: ${starterid} teacher: ${teacherid} does not exist, so creating it`);
        const data = { starterid, teacherid };
        createFavourite(data, onCreateFavourite);
      } else {
        logger.error(error, `/favourite/starter/${starterid}/teacher/${teacherid}`);
      }
    });
}

export function fetchTeachersFavourites(onFetchFavourites, teacherid) {
  logger.trace('fetchTeachersFavourites');

  axios
    .get(`/favourites/teacher/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        const { favourites } = response.data;

        const favouritesArray = [];
        const getLessonStarter = favourites.map((favourite) => {
          return axios
            .get(`/starter/${favourite.starterid}`)
            .then((res) => {
              favouritesArray.push(res.data);
            })
            .catch((error) => {
              logger.error(error, `Error for favourite: ${favourite.id}`);
              return null;
            });
        });

        Promise.all(getLessonStarter)
          .then((results) => {
            onFetchFavourites(favouritesArray);
            logger.debug('Results:', results);
          })
          .catch((error) => {
            logger.error(error, 'Error in Promise.all:');
          });
      }
    })
    .catch((error) => {
      logger.error(error, 'Error fetching favourites:');
    });
}
