import PropTypes from 'prop-types';

const imageHelper = require('../../helpers/image');

function LessonImg(props) {
  LessonImg.propTypes = {
    starter: PropTypes.object.isRequired
  };

  const { starter } = props;

  let height = 0;
  let width = 0;
  let imgSrc = null;
  const altImage = '';

  // TODO Consolidate src, image and thumb into single
  if (starter.data.src !== undefined) {
    height = '720';
    width = '1024';
    imgSrc = starter.data.src;
  } else if (starter.data.image !== undefined) {
    height = '720';
    width = '1024';
    imgSrc = starter.data.image;
  } else if (starter.data.thumb !== undefined) {
    height = '180';
    width = '320';
    imgSrc = starter.data.thumb;
  }

  // TODO Move these into environment variables
  imgSrc = imageHelper.replaceImagePaths(imgSrc);

  if (imgSrc !== null) {
    return <img id={starter.starterid} height={height} width={width} src={imgSrc} alt={altImage} />;
  }
  // TODO Create a default image for lesson starters
  return <img id={starter.starterid} height={height} width={width} src="Default Image" alt="Default" />;
}

export default LessonImg;
