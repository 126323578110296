const { constants } = require('dresscode-common');
const user = require('../../helpers/user');
const logger = require('../../logger');

const { ACCESS_LEVELS } = constants;

function CheckAccessToAdminScreen() {
  const teacherToCheck = user.getStoredTeacher();
  if (teacherToCheck === null) {
    logger.error('No teacher logged in, no access to Admin');
    return false;
  }

  if (teacherToCheck.level !== ACCESS_LEVELS.ADMIN_ACCESS) {
    logger.error('Logged in teacher is not Admin, no access to Admin');
    return false;
  }
  return true;
}

module.exports = {
  CheckAccessToAdminScreen
};
