import './Footer.css';

const logger = require('../../logger');

function Footer() {
  logger.trace('Footer');

  return (
    <footer className="footer">
      <div className="container p-1 text-center">
        Copyright &copy; DresscodeHQ 2003{' '}
        <a href="https://twitter.com/dressCodeHQ" target="_blank" rel="noreferrer">
          <i className="bi bi-twitter text-primary" />
        </a>{' '}
        <a href="https://www.linkedin.com/company/dresscodehq" target="_blank" rel="noreferrer">
          <i className="bi bi-linkedin text-primary" />
        </a>
      </div>
      <div className="container p-2 text-center">
        dressCode is a Scottish Charitable Incorporated Organisaction (SCIO) regulated by the Scottish Charity Regulator (OSCR), Scottish Charity number SCO48363 .{' '}
        <a href="/privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
}

export default Footer;
