/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStoredTeacher } from '../../helpers/user';
import { fetchStartersInDateRange, getDatesOfCurrentWeek } from '../../helpers/api/diary';

import Header from '../../components/common/Header';
import DiaryBanner from './DiaryBanner';
import DiaryComponent from './DiaryComponent';
import { formatForFetch } from '../../helpers/diary';

import './Diarypage.css';

const logger = require('../../logger');
const activityApi = require('../../helpers/api/activity');

function DiaryPage() {
  logger.trace('DiartPage');

  //
  // STATE
  const [currentTeacher, setCurrentTeacher] = useState(getStoredTeacher());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [startersArray, setStartersArray] = useState([]);
  const [currentWeekDates, setCurrentWeekDates] = useState([
    'January 1 2000',
    'January 2 2000',
    'January 3 2000',
    'January 4 2000',
    'January 5 2000',
    'January 6 2000',
    'January 7 2000'
  ]);

  const navigate = useNavigate();

  // Runs only once on component load or reload
  useEffect(() => {
    logger.trace('useEffect');

    setCurrentWeekDates(getDatesOfCurrentWeek(setCurrentDate));
    setCurrentTeacher(getStoredTeacher());
    if (currentTeacher === null) {
      navigate('/signin');
    }
  }, []);

  // Runs on first load of component and every time the value of currentWeekDates or currentTeacher changes
  useEffect(() => {
    logger.trace('useEffect');

    const check = formatForFetch(currentWeekDates[0]).split('-');
    if (check[0] === 'undefined') {
      return;
    }

    activityApi.logActivity(currentTeacher !== null && currentTeacher !== undefined ? currentTeacher.teacherid : 0, 'Diary', 'View');

    const searchDates = [
      formatForFetch(currentWeekDates[0]),
      formatForFetch(currentWeekDates[1]),
      formatForFetch(currentWeekDates[2]),
      formatForFetch(currentWeekDates[3]),
      formatForFetch(currentWeekDates[4])
    ];

    fetchStartersInDateRange(currentTeacher, searchDates, setStartersArray);
  }, [currentWeekDates, refreshRequired]);

  return (
    <>
      <Header />
      <DiaryBanner currentWeekDates={currentWeekDates} setCurrentWeekDates={setCurrentWeekDates} currentDate={currentDate} />
      <DiaryComponent
        currentWeekDates={currentWeekDates}
        currentTeacher={currentTeacher}
        setRefreshRequired={setRefreshRequired}
        refreshRequired={refreshRequired}
        startersArray={startersArray}
      />
    </>
  );
}

export default DiaryPage;
