import PropTypes from 'prop-types';

const logger = require('../../logger');

function PageButtons(props) {
  logger.trace('PageButton');

  PageButtons.propTypes = {
    pages: PropTypes.number.isRequired,
    startIndex: PropTypes.number.isRequired,
    endIndex: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
  };

  const { pages, startIndex, endIndex, count, page, onPageChange } = props;

  function getPageButtons() {
    logger.trace('getPageButtons');

    const content = [];

    content.push(
      <li key="-1" className={startIndex === 0 ? 'page-item disabled' : 'page-item'}>
        <button type="submit" className="page-link" aria-disabled={startIndex === 0 ? 'true' : 'false'} id="-1" onClick={onPageChange} style={{ cursor: 'pointer' }}>
          Previous
        </button>
      </li>
    );

    for (let i = 0; i < pages; i += 1) {
      const classname = i === page ? 'page-item disabled' : 'page-item';
      content.push(
        <li key={i} className={classname}>
          <button type="submit" className="page-link cursor" id={i} onClick={onPageChange} style={{ cursor: 'pointer' }}>
            {i + 1}
          </button>
        </li>
      );
    }

    content.push(
      <li key="+1" className={endIndex === count ? 'page-item disabled' : 'page-item'}>
        <button type="submit" className="page-link" aria-disabled={endIndex === count ? 'true' : 'false'} id="+1" onClick={onPageChange} style={{ cursor: 'pointer' }}>
          Next
        </button>
      </li>
    );

    return content;
  }

  return (
    <nav aria-label="Starter Paging">
      <ul className="pagination justify-content-center">{getPageButtons()}</ul>
    </nav>
  );
}

export default PageButtons;
