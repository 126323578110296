import axios from 'axios';

const logger = require('../../logger');

export function fetchMetrics(onFetchMetrics) {
  logger.trace('fetchRatings');

  axios
    .get('/metrics')
    .then((response) => {
      if (response.status === 200) {
        onFetchMetrics(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/metrics');
    });
}

export function dummy() {}
