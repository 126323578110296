/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import { AiOutlineClose } from 'react-icons/ai';
import { apiDiaryAppend } from '../../helpers/api/diary';

import { isSelectedDateWeekend, formatForUpdate } from '../../helpers/diary';

const logger = require('../../logger');

function AddToDiaryModal({ starter, closeModal }) {
  logger.trace('AddToDiaryModal');

  const [className, setClassName] = useState('');
  const [valueForDateInput, setValueForDateInput] = useState('');
  const [dateNotification, setDateNotification] = useState({ show: false, message: '' });
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
  });

  function onAddDiary(result) {
    logger.trace('onAddDiary');

    closeModal(result, false);
  }

  function onClassNameChange(value) {
    setClassName(value);
  }

  function handleSubmit() {
    apiDiaryAppend(starter.starterid, starter.name, className, selectedDate, onAddDiary);
  }

  return (
    <div>
      {/* NOTIFICATION */}
      {dateNotification.show && dateNotification.warn === false ? (
        <div className="diaryNotification diaryNotification-good">{dateNotification.message}</div>
      ) : dateNotification.show && dateNotification.warn === true ? (
        <div className="diaryNotification diaryNotification-bad">{dateNotification.message}</div>
      ) : (
        <div />
      )}
      <div className="diary-add-modal-header">
        <h2>Add to Diary</h2>
        <AiOutlineClose className="close-modal-button" onClick={() => closeModal(true, true)} />
      </div>
      <hr />
      <div>
        <div className="center-contents">
          <Calendar
            type="date"
            id="date"
            value={valueForDateInput}
            onChange={(selected) => isSelectedDateWeekend(selected, setSelectedDate, setDateNotification, setValueForDateInput)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="className" className="diary-modal-label">
          Enter your class name:
        </label>
        <br />
        <input
          className="diary-modal-input"
          type="text"
          maxLength={100}
          placeholder="-- Enter class name --"
          onChange={(e) => onClassNameChange(e.target.value)}
          value={className}
        />
      </div>
      <div className="diary-modal-buttons-container center-contents">
        <button className="diary-modal-submit-button" type="submit" onClick={() => handleSubmit()}>
          Add Lesson Starter
        </button>
      </div>
    </div>
  );
}

AddToDiaryModal.propTypes = {
  starter: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default AddToDiaryModal;
