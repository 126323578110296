import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const subUnitsApi = require('../../helpers/api/subunits');
const teachingUnitsApi = require('../../helpers/api/teachingunits');
const coursesApi = require('../../helpers/api/courses');
const subjectsApi = require('../../helpers/api/subjects');
const activityApi = require('../../helpers/api/activity');

function AdminSubUnit() {
  logger.trace('AdminSubUnit');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);

  const [subunitid, setSubUnitID] = useState(0);

  const [subunits, setSubUnits] = useState([]);

  const [subjects, setSubjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachingUnits, setTeachingUnits] = useState([]);
  const [selCourses, setSelectableCourses] = useState([]);
  const [selTeachingUnits, setSelectableTeachingUnits] = useState([]);

  const [subjectid, setSubjectID] = useState(0);
  const [courseid, setCourseID] = useState(0);
  const [teachingunitid, setTeachingunitid] = useState(0);
  const [name, setName] = useState('');

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');
    setSubjectID(data.subjectid);
    setCourseID(data.courseid);
    setTeachingunitid(data.teachingunitid);
    setName(data.name);
  }

  function resetVariables() {
    logger.trace('resetVariables');
    setSubjectID(0);
    setCourseID(0);
    setTeachingunitid(0);
    setName('');
  }

  function onFetchSubUnits(listOfSubjects) {
    logger.trace('onFetchSubUnits');
    setSubUnits(listOfSubjects);
  }

  function onFetchSubjects(listOfSubjects) {
    logger.trace('onFetchSubjects');
    setSubjects(listOfSubjects);
  }

  function onFetchCourses(listOfCourses) {
    logger.trace('onFetchCourses');
    setCourses(listOfCourses);
  }

  function onFetchTeachingUnits(listOfTeachingUnits) {
    logger.trace('onFetchTeachingUnits');
    setTeachingUnits(listOfTeachingUnits);
  }

  function onChangeSubject(idOfSubject, listOfCourses) {
    logger.trace('onChangeSubject');
    setSelectableCourses(listOfCourses);
    setSubjectID(idOfSubject);
  }

  function onChangeCourse(idOfCourse, listOfTeachingUnits) {
    logger.trace('onChangeCourse');
    setSelectableTeachingUnits(listOfTeachingUnits);
    setCourseID(idOfCourse);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (subjectid == null || subjectid === 0) {
      errors.push('Invalid Subject!');
    }

    if (courseid == null || courseid === 0) {
      errors.push('Invalid Course!');
    }

    if (teachingunitid == null || teachingunitid === 0) {
      errors.push('Invalid Teaching Unit!');
    }

    if (name == null || name.length === 0) {
      errors.push('Name cannot be blank!');
    }

    return errors;
  }

  function onCreateSubUnit() {
    logger.trace('onCreateSubUnit');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnit', 'Create');

    resetVariables();

    subUnitsApi.fetchSubUnits(onFetchSubUnits);
    subjectsApi.fetchSubjects(onFetchSubjects);

    setSelectableCourses([]);
    setSelectableTeachingUnits([]);

    setCreate(true);
  }

  function onUpdateSubUnit() {
    logger.trace('onUpdateSubUnit');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnit', 'Update');

    setSubUnitID(0);
    resetVariables();
    setCreate(true);
    subUnitsApi.fetchSubUnits(onFetchSubUnits);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const data = {
        subjectid,
        courseid,
        teachingunitid,
        name
      };

      if (create === true) {
        subUnitsApi.createSubUnit(data, onCreateSubUnit);
      } else {
        data.subunitid = subunitid;
        subUnitsApi.updateSubUnit(data, onUpdateSubUnit);
      }
    }
  }

  function onEditSubUnit(subUnitData) {
    logger.trace('onEditSubUnit');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnit', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setSubUnitID(subUnitData.subunitid);
    setCreate(false);
    setVariables(subUnitData);
    subjectsApi.changeSubject(subUnitData.subjectid, onChangeSubject);
    coursesApi.changeCourse(subUnitData.courseid, onChangeCourse);
  }

  function onDeleteSubUnit() {
    logger.trace('onDeleteSubUnit');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnit', 'Delete');

    setCreate(true);
    subUnitsApi.fetchSubUnits(onFetchSubUnits);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    logger.info('Cancelling Edit');
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnit', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    subUnitsApi.fetchSubUnits(onFetchSubUnits);
    subjectsApi.fetchSubjects(onFetchSubjects);
    coursesApi.fetchCourses(onFetchCourses);
    teachingUnitsApi.fetchTeachingUnits(onFetchTeachingUnits);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Subject', prop: 'subject', isSortable: true, isFilterable: true },
    { title: 'Course', prop: 'course', isSortable: true, isFilterable: true },
    { title: 'Teaching Unit', prop: 'teachingunit', isSortable: true, isFilterable: true },
    { title: 'Sub Unit', prop: 'name', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => subUnitsApi.editSubUnit(row.subunit.subunitid, onEditSubUnit)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => subUnitsApi.deleteSubUnit(row.subunit.subunitid, onDeleteSubUnit)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  subunits.forEach((subunit) => {
    body.push({
      subunit,
      id: subunit.subunitid,
      subject: subjectsApi.lookupSubject(subjects, subunit.subjectid),
      course: coursesApi.lookupCourse(courses, subunit.courseid),
      teachingunit: teachingUnitsApi.lookupTeachingUnit(teachingUnits, subunit.teachingunitid),
      name: subunit.name,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Sub Unit</h2>}
            {create === false && <h2>Update Sub Unit</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newsubunit-subjectid">
                  Subject
                  <select
                    className="form-control"
                    name="subjectid"
                    id="newsubunit-subjectid"
                    onChange={(e) => subjectsApi.changeSubject(e.target.value, onChangeSubject)}
                    value={subjectid}
                  >
                    <option key="0" value="">
                      --Please choose a subject--
                    </option>
                    {subjects.map((subject) => (
                      <option key={subject.subjectid} value={subject.subjectid}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newsubunit-courseid">
                  Course
                  <select
                    className="form-control"
                    name="courseid"
                    id="newsubunit-courseid"
                    onChange={(e) => coursesApi.changeCourse(e.target.value, onChangeCourse)}
                    value={courseid}
                  >
                    <option key="0" value="">
                      --Please choose a course--
                    </option>
                    {selCourses.map((course) => (
                      <option key={course.courseid} value={course.courseid}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newsubunit-teachingunit">
                  Teaching Unit
                  <select
                    className="form-control"
                    name="newsubunit-teachingunit"
                    id="newsubunit-teachingunitid"
                    onChange={(e) => setTeachingunitid(e.target.value)}
                    value={teachingunitid}
                  >
                    <option key="0" value="">
                      --Please choose a teaching unit--
                    </option>
                    {selTeachingUnits.map((teachingUnit) => (
                      <option key={teachingUnit.teachingunitid} value={teachingUnit.teachingunitid}>
                        {teachingUnit.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newsubunit-name">
                  Name
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="newsubunit-name"
                    placeholder="Enter sub unit name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Sub Unit" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Sub Unit" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Sub Units</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="subunits.csv" data={subunits}>
                    Download Sub Units
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminSubUnit;
