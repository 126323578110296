import PropTypes from 'prop-types';

const logger = require('../../logger');

function Tags(props) {
  logger.trace('Tags');

  Tags.propTypes = {
    tags: PropTypes.array.isRequired
  };

  const { tags } = props;

  return (
    <>
      {tags.map((tag, index) => {
        return (
          <span key={index} className="badge rounded-pill bg-secondary me-1">
            {tag}
          </span>
        );
      })}
    </>
  );
}

export default Tags;
