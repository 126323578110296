const logger = require('../logger');

export function formatYesNo(boolVal) {
  logger.trace('formatYesNo');
  return boolVal === 0 ? 'No' : 'Yes';
}

export function formatIsBlocked(isBlocked) {
  logger.trace('formatIsBlocked');
  return isBlocked < 3 ? 'No' : 'Yes';
}
