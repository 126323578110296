import axios from 'axios';

const logger = require('../../logger');

export function fetchSubjects(onFetchSubjects) {
  logger.trace('fetchSubjects');

  axios
    .get('/subjects')
    .then((response) => {
      if (response.status === 200) {
        onFetchSubjects(response.data.subjects);
      }
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function lookupSubject(listOfubjects, idOfSubject) {
  logger.trace('lookupSubjects');

  for (let i = 0; i < listOfubjects.length; i += 1) {
    if (listOfubjects[i].subjectid === idOfSubject) {
      return listOfubjects[i].name;
    }
  }
  return idOfSubject;
}

export function changeSubject(idOfSubject, onChangeSubject) {
  logger.trace('changeSubject');

  axios
    .get(`/courses/subjectid/${idOfSubject}`)
    .then((response) => {
      if (response.status === 200) {
        onChangeSubject(idOfSubject, response.data.courses);
      }
    })
    .catch((error) => {
      logger.error(error, '/courses/subjectid/');
    });
}

export function createSubject(data, onCreateSubject) {
  logger.trace('createSubject');

  axios
    .post('/subject/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Subject created...');
        onCreateSubject();
      }
    })
    .catch((error) => {
      logger.error(error, '/subject/create');
    });
}

export function updateSubject(data, onUpdateSubject) {
  logger.trace('updateSubject');
  axios
    .post('/subject/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Subject updated...');
        onUpdateSubject();
      }
    })
    .catch((error) => {
      logger.error(error, '/subject/update');
    });
}

export function editSubject(idOfSubject, onEditSubject) {
  logger.trace('editSubject');

  logger.info('Editing Subject:', idOfSubject);
  axios
    .get(`/subject/${idOfSubject}`)
    .then((response) => {
      if (response.status === 200) {
        onEditSubject(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/subject/');
    });
}

export function deleteSubject(idOfSubject, onDeleteSubject) {
  logger.trace('deleteSubject');

  if (window.confirm('Are you sure you wish to delete this Subject?')) {
    logger.info('Deleting Subject:', idOfSubject);

    axios
      .delete(`/subject/${idOfSubject}`)
      .then((response) => {
        if (response.status === 200) {
          onDeleteSubject();
        }
      })
      .catch((error) => {
        logger.error(error, '/subject/');
      });
  }
}
