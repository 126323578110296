import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/ForgotPassword.css';

const user = require('../../helpers/user');
const logger = require('../../logger');

const usersApi = require('../../helpers/api/users');
const activityApi = require('../../helpers/api/activity');

function ForgotPassword() {
  logger.trace('ForgotPassword');

  const [email, setEmail] = useState('');
  const [errormsg, setErrorMsg] = useState('');

  const teacher = user.getStoredTeacher();
  const nav = useNavigate();

  function validateForm() {
    logger.trace('validateForm');
    return email.length > 0;
  }

  function onResetPassword(message) {
    logger.trace('onResetPassword');
    activityApi.logActivity(0, 'ForgotPassword', 'ForgotPassword');
    setErrorMsg(message);
  }

  function resetPassword(emailOfTeacher, whenResetPassword) {
    logger.trace('resetPassword');
    usersApi.resetPassword(emailOfTeacher, whenResetPassword);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');
    event.preventDefault();
    resetPassword(email, onResetPassword);
  }

  useEffect(() => {
    if (teacher !== undefined && teacher !== null) {
      nav('/lessonstarters');
    }
  }, [teacher]);

  return (
    <div className="fplayout">
      <form className="fpform" onSubmit={(event) => handleFormSubmit(event)}>
        <img className="fplogo" src="/images/dressCodeLogo.png" alt="logo" />
        <div className="fpline1" />
        <h3 className="fpheadline">Reset Password</h3>
        {errormsg.length === 0 && <span className="msg">Enter a valid email address and we will send you a reset link.</span>}
        {errormsg.length > 0 && <span style={{ textAlign: 'center' }}>{errormsg}</span>}
        <input className="fpinputText" type="text" id="email" placeholder="Email Address" required value={email} onChange={(e) => setEmail(e.target.value)} />
        <button className="fpbutton" type="submit" disabled={!validateForm()}>
          Reset password
        </button>

        <p className="fpsignup">
          Don’t have an account? <a href="/signup">Sign up</a>
        </p>
      </form>
    </div>
  );
}

export default ForgotPassword;
