/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { GrLinkPrevious, GrLinkNext } from 'react-icons/gr';
import { PropTypes } from 'prop-types';

// Component imports
import Calendar from 'react-calendar';

import { formatWeekViewDate, formatDateRemoveYear, handleNextWeekClick, handlePreviousWeekClick, handleDatePicked } from '../../helpers/diary';

const logger = require('../../logger');

function DiaryBanner({ currentWeekDates, setCurrentWeekDates, currentDate }) {
  logger.trace('DiaryBanner');
  const [showDiaryDatePicker, setShowDiaryDatePicker] = useState(false);

  return (
    <div className="diary-banner-container">
      {showDiaryDatePicker && (
        <div className="custom-date-picker">
          <Calendar onChange={(e) => handleDatePicked(e, setShowDiaryDatePicker, setCurrentWeekDates)} />
        </div>
      )}
      <div className="d-inline-flex justify-content-start ps-5 pb-3 diary-banner-title">
        <h1>Diary</h1>&nbsp;
        <p>{formatWeekViewDate(currentDate)}</p>
      </div>
      <div className="diary-banner-week-display">
        <h3 className="m-2 pe-5" onClick={() => setShowDiaryDatePicker(!showDiaryDatePicker)}>{`${formatDateRemoveYear(currentWeekDates[0])} - ${formatDateRemoveYear(
          currentWeekDates[currentWeekDates.length - 3]
        )}`}</h3>
        <GrLinkPrevious onClick={() => handlePreviousWeekClick(currentWeekDates, setCurrentWeekDates)} className="date-nav-btn" />
        <GrLinkNext onClick={() => handleNextWeekClick(currentWeekDates, setCurrentWeekDates)} className="date-nav-btn " />
      </div>
    </div>
  );
}

DiaryBanner.propTypes = {
  currentWeekDates: PropTypes.array.isRequired,
  setCurrentWeekDates: PropTypes.func.isRequired,
  currentDate: PropTypes.instanceOf(Date).isRequired
};

export default DiaryBanner;
