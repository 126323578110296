import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';

import AdminMenu from './AdminMenu';
import AdminMetrics from './AdminMetrics';
import AdminReports from './AdminReports';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const activityApi = require('../../helpers/api/activity');

function AdminHome() {
  logger.trace('AdminHome');

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminHome', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }
  }, [teacher.teacherid]);

  return (
    <>
      <Header />
      <div className="container bg-white">
        <div className="row">
          <div className="col adminsidebar">
            <AdminMenu />
          </div>
          <div className="col adminmetrics">
            <AdminMetrics />
          </div>
          <div className="col adminreportss">
            <AdminReports />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminHome;
