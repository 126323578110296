/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { BsCalendarDate } from 'react-icons/bs';
import { useParams, useNavigate } from 'react-router-dom';
import { useFullScreenHandle } from 'react-full-screen';
import { Modal, Button, Form } from 'react-bootstrap';
import './LessonStarter.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Stars from '../../components/common/Stars';
import Tags from '../../components/common/Tags';
import Comments from '../../components/common/Comments';
import Hierarchy from '../../components/common/Hierarchy';
import Starter from './Starter';
import AddToDiaryModal from '../diary/AddToDiaryModal';
import Heart from '../../components/common/Heart';

const startersApi = require('../../helpers/api/starters');
const tagsApi = require('../../helpers/api/tags');
const favouritesApi = require('../../helpers/api/favourites');
const ratingsApi = require('../../helpers/api/ratings');
const activityApi = require('../../helpers/api/activity');
const messageApi = require('../../helpers/api/messages');
const user = require('../../helpers/user');
const logger = require('../../logger');

function LessonStarter() {
  logger.trace('LessonStarter');
  let { starterid } = useParams();
  starterid = Number(starterid);

  const [showDiaryModal, setShowDiaryModal] = useState(false);

  const modalStyles = {
    content: {
      height: '35rem',
      width: '30vw',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      zIndex: 10
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [rating, setRating] = useState('5');
  const [feedback, setFeedback] = useState('');

  const [starter, setStarter] = useState(null);
  const [tags, setTags] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [isFavourite, setIsFavourite] = useState(false);
  const [views, setViews] = useState(0);
  const [firstTime, setFirstTime] = useState(true);

  const [diaryNotification, setDiaryNotification] = useState({ show: false, message: '' });

  const teacher = user.getStoredTeacher();
  const nav = useNavigate();

  function onFetchStarter(theStarter) {
    logger.trace('onFetchStarter');
    setStarter(theStarter);
    setViews(theStarter.views);

    if (firstTime === true) {
      startersApi.starterViewed(theStarter.starterid);
      setFirstTime(false);
    }
  }

  function onFetchFavouriteForStarter(theFavourite) {
    logger.trace('onFetchFavouritesForStarter');
    setIsFavourite(theFavourite);
  }

  function onFetchTagsForStarter(theTags) {
    logger.trace('onFetchTagsForStarter');
    setTags(theTags.sort());
  }

  function onFetchHierarchy(theHierarchies) {
    logger.trace('onFetchHierarchy');
    setHierarchies(theHierarchies);
  }

  function onFetchRatingsForStarter(theRatings) {
    logger.trace('onFetchRatingsForStarter');
    setRatings(theRatings);
  }

  function onDeleteFavourite() {
    activityApi.logActivity(teacher !== undefined ? teacher.teacherid : 0, 'LessonStartersPage', 'Favourite Deleted');
    setIsFavourite(false);
  }

  function onCreateFavourite() {
    activityApi.logActivity(teacher !== undefined ? teacher.teacherid : 0, 'LessonStartersPage', 'ChangeFavourite Added');
    setIsFavourite(true);
  }

  function changeFavourite(theStarterid) {
    logger.trace('changeFavourite');

    if (teacher !== undefined && teacher !== null) {
      favouritesApi.manageFavourite(theStarterid, teacher.teacherid, onDeleteFavourite, onCreateFavourite);
    } else {
      nav('/signin');
    }
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher !== null && teacher !== undefined ? teacher.teacherid : 0, 'LessonStarter', 'View');

    startersApi.fetchStarter(starterid, onFetchStarter);
    startersApi.fetchHierarchy(starterid, onFetchHierarchy);
    if (teacher === null) {
      setIsFavourite(false);
    } else {
      favouritesApi.fetchFavouriteForStarter(starterid, teacher.teacherid, onFetchFavouriteForStarter);
    }
    tagsApi.fetchTagsForStarter(starterid, onFetchTagsForStarter);
    ratingsApi.fetchRatingsForStarter(starterid, onFetchRatingsForStarter);
  }, []);

  const handle = useFullScreenHandle();

  function addFeedback() {
    logger.trace('addFeedback');
    setRating('5');
    setFeedback('');
    setModalShow(true);
  }

  function handleModalClose() {
    logger.trace('handleModalClose');
    setModalShow(false);
  }

  function handleFeedbackChange(e) {
    logger.trace('handleFeedbackChange');
    setFeedback(e.target.value);
  }

  function handleRatingChange(e) {
    logger.trace('handleFeedbackChange');
    setRating(e.target.value);
  }

  function validateForm() {
    logger.trace('validateForm');
    return feedback.length > 0;
  }

  function onCreateMessage() {
    logger.info('Message created');
    setStarter(starter);
  }

  function onCreateRating() {
    activityApi.logActivity(teacher !== null && teacher !== undefined ? teacher.teacherid : 0, 'LessonStarters', 'Added Feedback');

    const messagedata = {
      senderid: 0, // System generated message
      receiverid: teacher !== null ? teacher.teacherid : 0,
      datesent: new Date(),
      dateread: null,
      archived: 0,
      title: '{{FEEDBACK}}',
      content: '',
      type: 'plain'
    };

    messageApi.createMessage(messagedata, onCreateMessage);

    ratingsApi.fetchRatingsForStarter(starterid, onFetchRatingsForStarter);
  }

  function handleSubmitFeedback() {
    logger.trace('handleSubmitFeedback');
    setModalShow(false);

    const data = { starterid, teacherid: teacher.teacherid, rating, comment: feedback };
    ratingsApi.createRating(data, onCreateRating);
  }

  function enterFullScreen() {
    activityApi.logActivity(teacher !== null && teacher !== undefined ? teacher.teacherid : 0, 'LessonStarter', 'Fullscreen');
    try {
      handle.enter();
    } catch (error) {
      logger.error(error, 'Full screen not supported on this device');
    }
  }

  function closeModal(result, cancelNotification) {
    setShowDiaryModal(false);

    if (cancelNotification === true) {
      return;
    }
    if (result === true) {
      setDiaryNotification({ show: true, warn: false, message: 'Diary updated successfully' });
      setTimeout(() => {
        setDiaryNotification({ show: false, warn: false, message: '' });
      }, 3000);
    } else {
      setDiaryNotification({ show: true, warn: true, message: 'Something went wrong updating the diary' });
      setTimeout(() => {
        setDiaryNotification({ show: false, warn: false, message: '' });
      }, 3000);
    }
  }

  function showAddToDiary() {
    if (teacher === null) {
      nav('/signin');
    } else {
      setShowDiaryModal(true);
    }
  }

  return (
    <>
      <Header />

      <div className="container main-content">
        {starter === null && (
          <span>
            <br />
            <br />
            <h2>No Starter for that ID exists</h2>
            <br />
            <br />
          </span>
        )}

        {/* NOTIFICATION */}
        {diaryNotification.show && diaryNotification.warn === false ? (
          <div className="diaryNotification diaryNotification-good">{diaryNotification.message}</div>
        ) : diaryNotification.show && diaryNotification.warn === true ? (
          <div className="diaryNotification diaryNotification-bad">{diaryNotification.message}</div>
        ) : (
          <div />
        )}

        {starter !== null && (
          <>
            <div className="row lsrow">
              <ReactModal isOpen={showDiaryModal} onRequestClose={() => setShowDiaryModal(false)} style={modalStyles}>
                <AddToDiaryModal starter={starter} closeModal={closeModal} />
              </ReactModal>
              <div className="fs-h-outer">
                <div className="add-to-diary-button-container">
                  <BsCalendarDate onClick={showAddToDiary} size={30} className="add-to-diary-button" color="white" />
                </div>
                <div onClick={enterFullScreen}>
                  <img src="/images/fullScreen.png" alt="full screen" style={{ cursor: 'pointer' }} />
                </div>
                <div>
                  <Heart id={starterid} favourite={isFavourite} changeFavourite={(id, state) => changeFavourite(id, state)} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div>
                  <Starter starter={starter} handle={handle} />
                </div>
                <div className="description">
                  {starter.type === 'Image' && (
                    <div>
                      <br />
                      <h3>Image</h3>
                      <p>This is an Image Lesson Starter</p>
                      <p>
                        Click on the full screen icon in the top right to switch to full screen mode.
                        <br />
                        Leave us feedback on how you found the content or its suitability.
                      </p>
                    </div>
                  )}
                  {starter.type === 'Video' && (
                    <div>
                      <br />
                      <h3>Video</h3>
                      <p>This is a Video Lesson Starter</p>
                      <p>
                        Click on the video to watch it now, click again to pause, or click on the full screen icon in the top right to switch to full screen mode. You can then play
                        the video by clicking it.
                        <br />
                        Leave us feedback on how you found the content or its suitability.
                      </p>
                    </div>
                  )}
                  {starter.type === 'MultiChoice' && (
                    <div>
                      <br />
                      <h3>Multiple Choice</h3>
                      <p>This is a multiple choice Lesson Starter</p>
                      <p>
                        Click on the image at any time to toggle between the question and answer images. To view in full screen click the full screen icon in the top right. In full
                        screen mode you cam toggle between the 2 screens by clicking once.
                        <br />
                        Leave us feedback on how you found the content or its suitability.
                      </p>
                    </div>
                  )}
                  {starter.type === 'QuestionAnswer' && (
                    <div>
                      <br />
                      <h3>Question & Answer</h3>
                      <p>This is a Question & Answer Lesson Starter</p>
                      <p>
                        Click on the image at any time to toggle between the question and answer images. To view in full screen click the full screen icon in the top right. In full
                        screen mode you cam toggle between the 2 screens by clicking once.
                        <br />
                        Leave us feedback on how you found the content or its suitability.
                      </p>
                    </div>
                  )}
                </div>
                <div className="tags">
                  <Tags tags={tags} />
                </div>
                <div className="row">
                  {hierarchies.map((hierarchy, index) => (
                    <div key={index}>
                      <Hierarchy hierarchy={hierarchy} />
                    </div>
                  ))}
                  <div className="rating">
                    <Stars numOfStars={starter.rating} />
                  </div>
                  <div className="views">
                    <span key={0} className="badge rounded-pill bg-primary me-1">
                      Views: {views}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h4>Comments/Feedback</h4>

                <div>
                  {teacher !== null && (
                    <button type="button" className="btn btn-primary" onClick={addFeedback}>
                      Add your feedback
                    </button>
                  )}
                </div>
                <div className="lscomments">
                  <Comments ratings={ratings} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Footer />

      <Modal
        show={modalShow}
        onHide={(e) => handleModalClose(e)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ opacity: 2 }}
        size="xl"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Leave us your feedback...</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>Tell us what you think of this Lesson Starter</Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Feedback:</Form.Label>
            <Form.Control as="textarea" rows={3} className="form-control" value={feedback} placeholder="Enter your feedback here" onChange={(e) => handleFeedbackChange(e)} />
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Rate the lesson starter 1-5:</Form.Label>
            <Form.Control as="select" value={rating} onChange={(e) => handleRatingChange(e)}>
              <option value="1">Could do better</option>
              <option value="2">Getting there</option>
              <option value="3">Good</option>
              <option value="4">Great</option>
              <option value="5">Amazing</option>
            </Form.Control>{' '}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={(e) => handleSubmitFeedback(e)} disabled={!validateForm()}>
            Submit
          </Button>
          <Button onClick={(e) => handleModalClose(e)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LessonStarter;
