import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const logger = require('../../logger');
const user = require('../../helpers/user');
const adminAccess = require('./access');

const metricsApi = require('../../helpers/api/metrics');
const activityApi = require('../../helpers/api/activity');

function AdminMetrics() {
  logger.trace('AdminMetrics');

  const [schoolTotal, setSchoolTotal] = useState(0);
  const [teacherTotal, setTeacherTotal] = useState(0);
  const [starterTotal, setStarterTotal] = useState(0);
  const [starterCounts, setStarterCounts] = useState({});
  const [messagesTotal, setMessagesTotal] = useState(0);
  const [ratingsTotal, setRatingsTotal] = useState(0);
  const [feedbacksTotal, setFeedbacksTotal] = useState(0);

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function onFetchMetrics(metrics) {
    setSchoolTotal(metrics.schools.total);
    setTeacherTotal(metrics.teachers.total);
    setStarterTotal(metrics.starters.total);
    setStarterCounts(metrics.starters.counts);
    setMessagesTotal(metrics.messages.total);
    setRatingsTotal(metrics.ratings.total);
    setFeedbacksTotal(metrics.feedbacks.total);
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminMetrics', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    metricsApi.fetchMetrics(onFetchMetrics);
  }, [teacher.teacherid]);

  return (
    <div>
      <h2>Metrics</h2>
      <div className="container">
        <div className="row">
          <div className="col md-3 metricscards">
            <div className="metriccardheader">
              <span>Schools</span>
            </div>
            <div className="metriccardvalue">
              <span>{schoolTotal}</span>
            </div>
          </div>
          <div className="col md-3 metricscards">
            <div className="metriccardheader">
              <span>Teachers</span>
            </div>
            <div className="metriccardvalue">
              <span>{teacherTotal}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col md-3 metricscards">
            <div className="metriccardheader">
              <span>Lesson Starters</span>
            </div>
            <div className="metriccardvalue">
              <span>{starterTotal}</span>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col md-2 m-1 p-1 metricscards">
                <div className="metriccardheader">
                  <span>Videos</span>
                </div>
                <div className="metriccardvalue">
                  <span>{starterCounts.Video}</span>
                </div>
              </div>
              <div className="col md-2 m-1 p-1 metricscards">
                <div className="metriccardheader">
                  <span>Image</span>
                </div>
                <div className="metriccardvalue">
                  <span>{starterCounts.Image}</span>
                </div>
              </div>
              <div className="col md-2 m-1 p-1 metricscards">
                <div className="metriccardheader">
                  <span>Multi Choice</span>
                </div>
                <div className="metriccardvalue">
                  <span>{starterCounts.MultiChoice}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col md-3 metricscards">
            <div className="metriccardheader">
              <span>Messages</span>
            </div>
            <div className="metriccardvalue">
              <span>{messagesTotal}</span>
            </div>
          </div>
          <div className="col md-3 metricscards">
            <div className="metriccardheader">
              <span>Ratings</span>
            </div>
            <div className="metriccardvalue">
              <span>{ratingsTotal}</span>
            </div>
          </div>
          <div className="col md-3 metricscards">
            <div className="metriccardheader">
              <span>Feedbacks</span>
            </div>
            <div className="metriccardvalue">
              <span>{feedbacksTotal}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMetrics;
