import PropTypes from 'prop-types';
import { FullScreen } from 'react-full-screen';

import './ImageStarterPrev.css';

import logger from '../../../logger';

const imageHelper = require('../../../helpers/image');

function ImageStarterPreview(props) {
  logger.trace('ImageStarterPreview');

  ImageStarterPreview.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    handle: PropTypes.object.isRequired
  };

  const { title, src, imageAlt, handle } = props;

  const width = handle !== undefined && handle.active === true ? '100%' : '840px';
  const height = handle !== undefined && handle.active === true ? '100%' : '480px';

  const imgSrc = imageHelper.replaceImagePaths(src);

  return (
    <div>
      {handle === undefined || (handle.active === false && <h1>{title}</h1>)}
      <div>
        {handle !== undefined && (
          <FullScreen handle={handle}>
            <div className="imagebox">
              <img className="center-fit" height={height} width={width} src={imgSrc} alt={imageAlt} />
            </div>
          </FullScreen>
        )}
        {handle === undefined && <img height={height} width={width} src={src} alt={imageAlt} />}
      </div>
    </div>
  );
}

export default ImageStarterPreview;
