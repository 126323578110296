import React from 'react';
import PropTypes from 'prop-types';

import './Courses.css';

function Courses(props) {
  Courses.propTypes = {
    courseList: PropTypes.array.isRequired,
    courseChange: PropTypes.func.isRequired,
    selectCourses: PropTypes.object.isRequired
  };

  const { courseList, courseChange, selectCourses } = props;

  function hierarchyChange(courseid, teachingunitid, subunitid) {
    courseChange([courseid, teachingunitid, subunitid]);
  }

  function isCourseChecked(courseid) {
    if (selectCourses.has(courseid)) {
      return true;
    }
    return false;
  }

  function isTeachingUnitChecked(courseid, teachunitid) {
    if (selectCourses.has(courseid)) {
      if (selectCourses.get(courseid).has(teachunitid)) {
        return true;
      }
    }
    return false;
  }

  function isSubUnitChecked(courseid, teachunitid, subunitid) {
    if (selectCourses.has(courseid)) {
      if (selectCourses.get(courseid).has(teachunitid)) {
        if (selectCourses.get(courseid).get(teachunitid).has(subunitid)) {
          return true;
        }
      }
    }
    return false;
  }

  function isHierarchyLevel(level) {
    if (process.env.REACT_APP_HIERACHY_LEVEL === undefined || process.env.REACT_APP_HIERACHY_LEVEL === null) {
      return true;
    }
    return Number(process.env.REACT_APP_HIERACHY_LEVEL) > level;
  }

  return (
    <>
      <div>
        <h4>Courses</h4>
      </div>

      <ul className="course">
        {courseList.map((course) => {
          return (
            <li key={`ckey${course.course.courseid}`}>
              <label>
                <input
                  id={`cid${course.course.courseid}`}
                  name={course.course.name}
                  type="checkbox"
                  onChange={() => {
                    hierarchyChange(course.course.courseid, null, null);
                  }}
                  checked={!!isCourseChecked(course.course.courseid)}
                />
                <span className="p-2">{course.course.name}</span>
              </label>

              {selectCourses.has(course.course.courseid) && isHierarchyLevel(0) && (
                <React.Fragment key={`tukey1${course.course.courseid}`}>
                  <ul className="teachingunit">
                    {course.teachingunits.map((teachingunit) => {
                      return (
                        <li key={`tukey2${teachingunit.teachingunit.teachingunitid}`}>
                          <label>
                            <input
                              id={`tuid${teachingunit.teachingunit.teachingunitid}`}
                              name={teachingunit.teachingunit.name}
                              type="checkbox"
                              onChange={() => {
                                hierarchyChange(course.course.courseid, teachingunit.teachingunit.teachingunitid, null);
                              }}
                              checked={!!isTeachingUnitChecked(course.course.courseid, teachingunit.teachingunit.teachingunitid)}
                            />
                            <span className="p-2">{teachingunit.teachingunit.name}</span>
                          </label>

                          <React.Fragment key={`tukey3${teachingunit.teachingunit.teachingunitid}`}>
                            <ul className="subunit">
                              {teachingunit.subunits.map((subunit) => {
                                return (
                                  <li key={`sukey${subunit.subunitid}`}>
                                    <label>
                                      <input
                                        id={`suid${subunit.subunitid}`}
                                        name={subunit.name}
                                        type="checkbox"
                                        onChange={() => {
                                          hierarchyChange(course.course.courseid, teachingunit.teachingunit.teachingunitid, subunit.subunitid);
                                        }}
                                        checked={!!isSubUnitChecked(course.course.courseid, teachingunit.teachingunit.teachingunitid, subunit.subunitid)}
                                      />
                                      <span className="p-2">{subunit.name}</span>
                                    </label>
                                  </li>
                                );
                              })}
                            </ul>
                          </React.Fragment>
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Courses;
