import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../styles/SignUpPage.css';

const user = require('../../helpers/user');
const logger = require('../../logger');

const teachersApi = require('../../helpers/api/teachers');
const activityApi = require('../../helpers/api/activity');

function SignUp() {
  logger.trace('SignUp');

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [privacy, setPrivacy] = useState(false);

  const [errormsg, setErrorMsg] = useState('');

  const teacher = user.getStoredTeacher();
  const nav = useNavigate();

  function validateForm() {
    logger.trace('validateForm');
    return firstname.length > 0 && lastname.length > 0 && email.length > 0 && password.length > 0 && password2.length > 0 && privacy === true;
  }

  function onCreateTeacher(teacherdata) {
    if (teacherdata !== null) {
      user.addStoredTeacher(teacherdata);
      activityApi.logActivity(teacherdata.teacherid, 'SignUpPage', 'SignUp');
      nav('/lessonstarters');
    } else {
      setErrorMsg('Failed to create account!');
    }
  }

  function onEmailExists(result) {
    logger.trace('onEmailExists');
    if (result === false) {
      const data = { firstname, lastname, email, password, level: user.ACCESS_LEVELS.TEACHER_ACCESS, is_blocked: 0 };
      teachersApi.createTeacher(data, onCreateTeacher);
    } else {
      setErrorMsg('Email address already exists!');
    }
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    if (user.validName(firstname) === false) {
      setErrorMsg('Not a valid firstname!');
      return;
    }

    if (user.validName(lastname) === false) {
      setErrorMsg('Not a valid lastname!');
      return;
    }

    if (user.validateEmail(email) === false) {
      setErrorMsg('Not a valid email address!');
      return;
    }
    if (password !== password2) {
      setErrorMsg('Passwords do not match!');
      return;
    }
    if (user.validatePassword(password) === false) {
      setErrorMsg('Password must use a combination of A-Z, a-z, 0-9 plus atleast 1 special character and be longer than 8');
      return;
    }

    teachersApi.doesEmailExist(email, onEmailExists);
  }

  function firstNameChange(text) {
    logger.trace('firstNameChange');
    setFirstname(text);
    validateForm();
  }

  function lastNameChange(text) {
    logger.trace('lastNameChange');
    setLastname(text);
    validateForm();
  }

  function emailChange(text) {
    logger.trace('emailChange');
    setEmail(text);
    validateForm();
  }

  function passwordChange(text) {
    logger.trace('passwordChange');
    setPassword(text);
    validateForm();
  }

  function password2Change(text) {
    logger.trace('password2Change');
    setPassword2(text);
    validateForm();
  }

  function privacyChange() {
    logger.trace('privacyChange');
    setPrivacy(!privacy);
    validateForm();
  }

  useEffect(() => {
    if (teacher !== undefined && teacher !== null) {
      nav('/lessonstarters');
    }
  }, [teacher]);

  return (
    <div className="sulayout">
      <form className="suform" onSubmit={(event) => handleFormSubmit(event)}>
        <img className="sulogo" src="/images/dressCodeLogo.png" alt="logo" />
        <div className="suline1" />
        <h3 className="suheadline">Sign Up</h3>
        {errormsg.length > 0 && <span>{errormsg}</span>}
        <input className="suinputText" type="text" id="firstname" placeholder="First name" required onChange={(e) => firstNameChange(e.target.value)} />
        <input className="suinputText" type="text" id="lastnamer" placeholder="Last name" required onChange={(e) => lastNameChange(e.target.value)} />
        <input className="suinputText" type="text" id="emailAddress" placeholder="Email Address" required onChange={(e) => emailChange(e.target.value)} />
        <input className="suinputText" type="password" id="password" placeholder="Enter Password" required onChange={(e) => passwordChange(e.target.value)} />
        <input className="suinputText" type="password" id="password2" placeholder="Enter Password Again" required onChange={(e) => password2Change(e.target.value)} />
        <label className="sulabel">
          <input className="sucheckbox" type="checkbox" id="privavy" required onChange={() => privacyChange()} checked={privacy ? 'checked' : ''} />
          <span>
            <a href="/privacy" target="_blank" rel="noreferrer">
              Accept Privacy Terms
            </a>
          </span>
        </label>
        <button className="sulogin_button" type="submit">
          Create Account
        </button>
      </form>
    </div>
  );
}

export default SignUp;
