import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const schoolsApi = require('../../helpers/api/schools');
const activityApi = require('../../helpers/api/activity');

function AdminSchool() {
  logger.trace('AdminSchool');

  const [errorMessages, setErrorMessages] = useState([]);

  const [schools, setSchools] = useState([]);

  const [create, setCreate] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [schoolid, setSchoolID] = useState(0);

  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [town, setTown] = useState('');
  const [postcode, setPostCode] = useState('');
  const [country, setCountry] = useState('Scotland');
  const [phoneNo, setPhoneNo] = useState('');
  const [contact, setContact] = useState('');

  const countries = ['Scotland', 'England', 'Wales', 'Northern Ireland'];

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function resetVariables() {
    logger.trace('resetVariables');

    setName('');
    setAddress1('');
    setAddress2('');
    setTown('');
    setPostCode('');
    setCountry('Scotland');
    setPhoneNo('');
    setContact('');
  }

  function setVariables(data) {
    logger.trace('setVariables');

    setName(data.name);
    setAddress1(data.address1);
    setAddress2(data.address2);
    setTown(data.town);
    setPostCode(data.postcode);
    setCountry(data.country);
    setPhoneNo(data.phone_no);
    setContact(data.contact);
  }

  function onFetchSchools(listOfSchools) {
    logger.trace('onFetchSchools');
    setSchools(listOfSchools);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (name == null || name.length === 0) {
      errors.push('Name cannot be blank!');
    }

    if (address1 == null || address1.length === 0) {
      errors.push('Address Line 1 cannot be blank!');
    }

    if (town == null || town.length === 0) {
      errors.push('Town cannot be blank!');
    }

    if (postcode == null || postcode.length === 0) {
      errors.push('Postcode cannot be blank!');
    } else {
      const postcodeRegex = /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/;
      if (postcode.match(postcodeRegex) === null) {
        errors.push('Invald Postcode!');
      }
    }

    if (country == null || country.length === 0) {
      errors.push('Country cannot be blank!');
    }

    if (phoneNo == null || phoneNo.length === 0) {
      errors.push('Phone No cannot be blank!');
    } else {
      // const ukPhoneNumberRegex = /^(?:\+44|0)(?:\d{9}|\d{10}|\d{11})$/;
      const ukPhoneNumberRegex =
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?(\d{4}|\d{3}))?$/;
      if (phoneNo.match(ukPhoneNumberRegex) === null) {
        errors.push('Invald Phone No!');
      }
    }

    if (contact == null || contact.length === 0) {
      errors.push('Contact cannot be blank!');
    }

    return errors;
  }

  function onCreateSchool() {
    logger.trace('onCreateSchool');

    activityApi.logActivity(teacher.teacherid, 'AdminSchool', 'Create');

    resetVariables();
    schoolsApi.fetchSchools(onFetchSchools);
    setErrorMessages([]);
  }

  function onUpdateSchool() {
    logger.trace('onUpdateSchool');

    activityApi.logActivity(teacher.teacherid, 'AdminSchool', 'Update');

    schoolsApi.fetchSchools(onFetchSchools);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');
    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const data = {
        name,
        address1,
        address2,
        town,
        postcode,
        country,
        phone_no: phoneNo,
        contact
      };

      if (create === true) {
        schoolsApi.createSchool(data, onCreateSchool);
      } else {
        data.schoolid = schoolid;
        schoolsApi.updateSchool(data, onUpdateSchool);
      }
    }
  }

  function onEditSchool(schoolData) {
    logger.trace('onEditSchool');

    activityApi.logActivity(teacher.teacherid, 'AdminSchool', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setSchoolID(schoolData.schoolid);
    setCreate(false);
    setVariables(schoolData);
  }

  function onDeleteSchool() {
    logger.trace('onDeleteSchool');

    activityApi.logActivity(teacher.teacherid, 'AdminSchool', 'Delete');

    setCreate(true);
    setSchoolID(0);
    resetVariables();
    schoolsApi.fetchSchools(onFetchSchools);
    setErrorMessages([]);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');

    logger.info('Cancelling Edit');
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminSchool', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    schoolsApi.fetchSchools(onFetchSchools);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'School', prop: 'name', isSortable: true, isFilterable: true },
    { title: 'Address1', prop: 'address1', isSortable: true, isFilterable: true },
    { title: 'Address2', prop: 'address2', isSortable: true, isFilterable: true },
    { title: 'Town', prop: 'town', isSortable: true, isFilterable: true },
    { title: 'Post Code', prop: 'postcode', isSortable: true, isFilterable: true },
    { title: 'Country', prop: 'country', isSortable: true, isFilterable: true },
    { title: 'Phone No', prop: 'phone_no', isSortable: true, isFilterable: true },
    { title: 'contact', prop: 'contact', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => schoolsApi.editSchool(row.school.schoolid, onEditSchool)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => schoolsApi.deleteSchool(row.school.schoolid, onDeleteSchool)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  schools.forEach((school) => {
    body.push({
      school,
      id: school.schoolid,
      name: school.name,
      address1: school.address1,
      address2: school.address2,
      town: school.town,
      postcode: school.postcode,
      country: school.country,
      phone_no: school.phone_no,
      contact: school.contact
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New School</h2>}
            {create === false && <h2>Update School</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-name">
                  Name
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="newschool-name"
                    placeholder="Enter a school name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-address1">
                  Address Line 1
                  <input
                    className="form-control"
                    type="text"
                    name="address1"
                    id="newschool-address1"
                    placeholder="Enter address line 1"
                    onChange={(e) => setAddress1(e.target.value)}
                    value={address1}
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-address2">
                  Address Line 2
                  <input
                    className="form-control"
                    type="text"
                    name="address2"
                    id="newschool-address2"
                    placeholder="Enter address line 2"
                    onChange={(e) => setAddress2(e.target.value)}
                    value={address2}
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-town">
                  Town
                  <input
                    className="form-control"
                    type="text"
                    name="town"
                    id="newschool-town"
                    placeholder="Enter school town"
                    onChange={(e) => setTown(e.target.value)}
                    value={town}
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-postcode">
                  Post Code
                  <input
                    className="form-control"
                    type="text"
                    name="postcode"
                    id="newschool-postcode"
                    placeholder="Enter school postcode"
                    onChange={(e) => setPostCode(e.target.value)}
                    value={postcode}
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-country">
                  Country
                  <select className="form-control" name="country" id="newcourse-country" value={country} onChange={(e) => setCountry(e.target.value)}>
                    <option value="">--Please choose a country--</option>
                    {countries.map((mapCountry) => (
                      <option key={mapCountry} value={mapCountry}>
                        {mapCountry}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-phoneNo">
                  Phone No
                  <input
                    className="form-control"
                    type="text"
                    name="phoneNo"
                    id="newschool-phoneNo"
                    placeholder="Enter school phone "
                    onChange={(e) => setPhoneNo(e.target.value)}
                    value={phoneNo}
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newschool-contact">
                  Contact
                  <input
                    className="form-control"
                    type="text"
                    name="contact"
                    id="newschool-contact"
                    placeholder="Enter school contact"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                  />
                </label>
              </div>

              {create === true && <input className="btn btn-primary" type="submit" value="Create School" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update School" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>
            <hr />
            <div className="row p-2 bg-white">
              <div>
                <div>
                  <h2>Schools</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="schools.csv" data={schools}>
                    Download Schools
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminSchool;
