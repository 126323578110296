import { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { CSVLink } from 'react-csv';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const messagesApi = require('../../helpers/api/messages');
const teachersApi = require('../../helpers/api/teachers');
const datetimeApi = require('../../helpers/datetime');
const archivedApi = require('../../helpers/archived');
const activityApi = require('../../helpers/api/activity');

const logger = require('../../logger');
const user = require('../../helpers/user');

const messagetypes = ['Plain', 'HTML', 'Markup'];

function AdminMessage() {
  logger.trace('AdminMessage');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [messageid, setMessageID] = useState(0);

  const [teachers, setTeachers] = useState([]);

  const [messages, setMessages] = useState([]);

  const [senderid, setSenderID] = useState('');
  const [receiverid, setReceiverID] = useState('');
  const [datesent, setDateSent] = useState(new Date());
  const [dateread, setDateRead] = useState('');
  const [archived, setArchived] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('');

  const theTeacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');

    setSenderID(data.senderid);
    setReceiverID(data.receiverid);
    setDateSent(data.datesent);
    setDateRead(data.dateread);
    setArchived(data.archived);
    setTitle(data.title);
    setContent(data.content);
    setType(data.type);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setSenderID('');
    setReceiverID('');
    setDateSent(new Date());
    setDateRead('');
    setArchived(false);
    setTitle('');
    setContent('');
    setType('');
  }

  function onfetchAllMessages(listOfMessages) {
    logger.trace('fetchAllMessages');
    setMessages(listOfMessages);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (title == null || title.length === 0) {
      errors.push('Title cannot be blank!');
    }

    if (content == null || content.length === 0) {
      errors.push('Content of message cannot be blank!');
    }

    return errors;
  }

  function onCreateMessage() {
    logger.trace('onCreateMessage');

    activityApi.logActivity(theTeacher.teacherid, 'AdminMessage', 'Create');

    resetVariables();
    messagesApi.fetchAllMessages(onfetchAllMessages);
  }

  function onUpdateMessage() {
    logger.trace('onUpdateMessage');

    activityApi.logActivity(theTeacher.teacherid, 'AdminMessage', 'Update');

    setCreate(true);
    resetVariables();
    messagesApi.fetchAllMessages(onfetchAllMessages);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        senderid,
        receiverid,
        datesent: new Date(datesent),
        dateread: dateread !== null ? new Date(dateread) : null,
        archived,
        title,
        content,
        type
      };

      if (create === true) {
        messagesApi.createMessage(postData, onCreateMessage);
      } else {
        postData.messageid = messageid;
        messagesApi.updateMessage(postData, onUpdateMessage);
      }
    }
  }

  function onDeleteMessage() {
    logger.trace('onDeleteMessage');

    activityApi.logActivity(theTeacher.teacherid, 'AdminMessage', 'Delete');

    messagesApi.fetchAllMessages(onfetchAllMessages);
  }

  function onEditMessage(messageData) {
    logger.trace('onEditMessage');

    activityApi.logActivity(theTeacher.teacherid, 'AdminMessage', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setMessageID(messageData.messageid);
    setCreate(false);
    setVariables(messageData);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    setCreate(true);
    resetVariables();
  }

  function onFetchTeachers(listOfTeachers) {
    logger.trace('onFetchTeachers');
    setTeachers(listOfTeachers);
  }

  const handleArchived = () => {
    logger.trace('handleArchived');
    setArchived(!archived);
  };

  useEffect(() => {
    logger.trace('useEffect');
    activityApi.logActivity(theTeacher.teacherid, 'AdminMessage', 'View');
    teachersApi.fetchTeachers(onFetchTeachers);
  }, [theTeacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Title', prop: 'title', isSortable: true },
    { title: 'Content', prop: 'content', isSortable: true },
    { title: 'Sent By', prop: 'sentby', isSortable: true, isFilterable: true },
    { title: 'Sent To', prop: 'sentto', isSortable: true, isFilterable: true },
    { title: 'Date Sent', prop: 'datesent', isSortable: true, isFilterable: true },
    { title: 'Date Read', prop: 'dateread', isSortable: true, isFilterable: true },
    { title: 'Archived?', prop: 'archived', isSortable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => messagesApi.editMessage(row.message.messageid, onEditMessage)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => messagesApi.deleteMessage(row.message.messageid, onDeleteMessage)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  messages.forEach((message) => {
    body.push({
      message,
      id: message.messageid,
      sentby: teachersApi.lookupTeacher(teachers, message.senderid),
      sentto: teachersApi.lookupTeacher(teachers, message.receiverid),
      datesent: datetimeApi.formatDateTime(message.datesent),
      dateread: datetimeApi.formatDateTime(message.dateread),
      archived: archivedApi.formatArchived(message.archived),
      title: message.title,
      content: message.content,
      type: message.type,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col-10 bg-white">
            {create === true && <h2>New Message</h2>}
            {create === false && <h2>Update Message</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-senderid">
                  Sender:
                  <select className="form-control" name="senderid" id="newmessage-senderid" onChange={(e) => setSenderID(e.target.value)} value={senderid}>
                    <option value="">--Please choose a teacher--</option>
                    {teachers.map((teacher) => (
                      <option key={teacher.teacherid} value={teacher.teacherid}>
                        {teacher.firstname} {teacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-receiverid">
                  Recipient:
                  <select className="form-control" name="receiverid" id="newmessage-receiverid" onChange={(e) => setReceiverID(e.target.value)} value={receiverid}>
                    <option value="">--Please choose a teacher--</option>
                    {teachers.map((teacher) => (
                      <option key={teacher.teacherid} value={teacher.teacherid}>
                        {teacher.firstname} {teacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-datesent">
                  Date Sent:
                </label>
                <div>
                  <DateTimePicker format="y-MM-dd h:mm:ss a" disableClock onChange={(e) => setDateSent(e)} value={datesent} />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-dateread">
                  Date Read:
                </label>
                <div>
                  <DateTimePicker format="y-MM-dd h:mm:ss a" disableClock onChange={(e) => setDateRead(e)} value={dateread} />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-title">
                  Title:
                </label>
                <input type="text" className="form-control" name="title" id="message-Title" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title} />
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-content">
                  Content:
                </label>
                <textarea
                  className="form-control"
                  rows="4"
                  cols="50"
                  name="content"
                  id="message-content"
                  placeholder="Enter Message"
                  onChange={(e) => setContent(e.target.value)}
                  value={content}
                />
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newmessage-type">
                  Type
                  <select className="form-control" name="type" id="newmessage-type" value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="">--Please choose a message type--</option>
                    {messagetypes.map((msgtype) => (
                      <option key={msgtype} value={msgtype}>
                        {msgtype}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3 form-check">
                <label className="form-check-label" htmlFor="newmessage-archived">
                  Archived
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="archived"
                    id="newmessage-archived"
                    placeholder="Enter is archived"
                    onChange={handleArchived}
                    checked={archived}
                  />
                </label>
              </div>

              {create === true && <input className="btn btn-primary" type="submit" value="Create Message" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Message" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>
            <hr />

            <div className="container bg-white">
              <div>
                <div>
                  <h2>Messages</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="messages.csv" data={messages}>
                    Download Messages
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminMessage;
