const logger = require('../../logger');

export default function AdminMenu() {
  logger.trace('AdminMenu');

  return (
    <ul className="adminmenu">
      <li>
        <a href="/admin">Metrics/Reports</a>
      </li>
      <li>
        <hr />
      </li>
      <li>
        <a href="/adminschool">Schools</a>
      </li>
      <li>
        <a href="/adminteacher">Teachers</a>
      </li>
      <li>
        <a href="/adminschoolteacher">School Teachers</a>
      </li>
      <li>
        <a href="/admindiary">Diary</a>
      </li>
      <li>
        <hr />
      </li>
      <li>
        <a href="/adminsubject">Subjects</a>
      </li>
      <li>
        <a href="/admincourse">Courses</a>
      </li>
      <li>
        <a href="/adminteachingunit">Teaching Units</a>
      </li>
      <li>
        <a href="/adminsubunit">Sub Units</a>
      </li>
      <li>
        <a href="/adminsubunitstarter">Sub Unit Starters</a>
      </li>
      <li>
        <hr />
      </li>
      <li>
        <a href="/adminstarter">Lesson Starters</a>
      </li>
      <li>
        <a href="/admincurriculum">Create Curriculum</a>
      </li>
      <li>
        <hr />
      </li>
      <li>
        <a href="/admintag">Tags</a>
      </li>
      <li>
        <a href="/adminstartertag">Starter Tags</a>
      </li>
      <li>
        <hr />
      </li>
      <li>
        <a href="/adminfavourite">Favourites</a>
      </li>
      <li>
        <a href="/adminrating">Ratings</a>
      </li>
      <li>
        <hr />
      </li>
      <li>
        <a href="/adminmessage">Messages</a>
      </li>
      <li>
        <a href="/adminfeedback">Feedback</a>
      </li>
    </ul>
  );
}
