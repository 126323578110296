import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const teachersApi = require('../../helpers/api/teachers');
const stringApi = require('../../helpers/string');
const activityApi = require('../../helpers/api/activity');

function AdminTeacher() {
  logger.trace('AdminTeacher');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [teacherid, setTeacherID] = useState(0);

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [level, setLevel] = useState(0);
  const [isBlocked, setIsBlocked] = useState(false);

  const [teachers, setTeachers] = useState([]);

  const levels = user.getLevelsForSelect();

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function resetVariables() {
    logger.trace('resetVariables');

    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setLevel(0);
    setIsBlocked(false);
  }

  function setVariables(data) {
    logger.trace('setVariables');

    setFirstName(data.firstname);
    setLastName(data.lastname);
    setEmail(data.email);
    // setPassword(data.password);
    setLevel(data.level);
    setIsBlocked(data.is_blocked);
  }

  function onFetchTeachersWithSchool(listOfTeachers) {
    logger.trace('onFetchTeachersWithSchool');
    setTeachers(listOfTeachers);
  }

  function onCreateTeacher() {
    logger.trace('onCreateTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminTeacher', 'Create');

    resetVariables();
    teachersApi.fetchTeachersWithSchool(onFetchTeachersWithSchool);
  }

  function onUpdateTeacher() {
    logger.trace('onUpdateTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminTeacher', 'Update');

    setCreate(true);
    setTeacherID(0);
    resetVariables();
    teachersApi.fetchTeachersWithSchool(onFetchTeachersWithSchool);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (firstname == null || firstname.length === 0) {
      errors.push('First name cannot be blank!');
    }

    if (lastname == null || lastname.length === 0) {
      errors.push('Last name cannot be blank!');
    }

    if (email == null || email.length === 0) {
      errors.push('Email cannot be blank!');
    }

    if (create === true) {
      if (password === null || password.length === 0) {
        errors.push('Password cannot be blank!');
      }
    }

    if (password.length > 0) {
      if (user.validatePassword(password) === false) {
        errors.push('Password must use a combination of A-Z, a-z, 0-9 plus atleast 1 special character and be longer than 8');
      }
    }

    return errors;
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const data = {
        firstname,
        lastname,
        email,
        level,
        is_blocked: isBlocked === true ? 1 : 0
      };

      if (password.length > 0) {
        data.password = password;
      }

      if (create === true) {
        teachersApi.createTeacher(data, onCreateTeacher);
      } else {
        data.teacherid = teacherid;

        teachersApi.updateTeacher(data, onUpdateTeacher);
      }
    }
  }

  const handleBlocked = () => {
    logger.trace('handleBlocked');
    setIsBlocked(!isBlocked);
  };

  function onEditTeacher(teacherData) {
    logger.trace('onEditTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminTeacher', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setTeacherID(teacherData.teacherid);
    setCreate(false);
    setVariables(teacherData);
    setErrorMessages([]);
  }

  function onDeleteTeacher() {
    logger.trace('onDeleteTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminTeacher', 'Delete');

    setCreate(true);
    setTeacherID(0);
    resetVariables();
    teachersApi.fetchTeachersWithSchool(onFetchTeachersWithSchool);
    setErrorMessages([]);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    logger.info('Cancelling Edit');
    setCreate(true);
    resetVariables();
  }

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'First Name', prop: 'firstname', isSortable: true, isFilterable: true },
    { title: 'Last Name', prop: 'lastname', isSortable: true, isFilterable: true },
    { title: 'Email', prop: 'email', isSortable: true, isFilterable: true },
    { title: 'School', prop: 'school', isSortable: true, isFilterable: true },
    { title: 'Level', prop: 'level', isSortable: true, isFilterable: true },
    { title: 'Blocked ?', prop: 'blocked', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => teachersApi.editTeacher(row.theTeacher.teacherid, onEditTeacher)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => teachersApi.deleteTeacher(row.theTeacher.teacherid, onDeleteTeacher)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  teachers.forEach((theTeacher) => {
    body.push({
      theTeacher,
      id: theTeacher.teacherid,
      firstname: theTeacher.firstname,
      lastname: theTeacher.lastname,
      email: theTeacher.email,
      school: theTeacher.school,
      level: user.formatLevel(theTeacher.level),
      blocked: stringApi.formatYesNo(theTeacher.is_blocked),
      options: ''
    });
  });

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminTeacher', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    teachersApi.fetchTeachersWithSchool(onFetchTeachersWithSchool);
  }, [teacher.teacherid]);

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Teacher</h2>}
            {create === false && <h2>Update Teacher</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newteach-firstname">
                  First Name
                  <input
                    className="form-control"
                    type="text"
                    name="firstname"
                    id="newteach-firstname"
                    placeholder="Enter teachers firstname"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstname}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newteach-lastname">
                  Last Name
                  <input
                    className="form-control"
                    type="text"
                    name="lastname"
                    id="newteach-lastname"
                    placeholder="Enter teachers lastname"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastname}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newteach-email">
                  Emai:
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    id="newteach-email"
                    placeholder="Enter email address"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newteach-password">
                  Password:
                  <input
                    className="form-control"
                    type="text"
                    name="password"
                    id="newteach-password"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newteach-level">
                  Level
                  <select className="form-control" name="level" id="newteach-level" onChange={(e) => setLevel(e.target.value)} value={level}>
                    <option value="">--Please choose a level--</option>
                    {levels.map((mapLevel) => (
                      <option key={mapLevel} value={mapLevel}>
                        {user.formatLevel(mapLevel)}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3 form-check">
                <label className="form-check-label" htmlFor="newteach-isBlocked">
                  Is Blocked
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isBlocked"
                    id="newteach-isblocked"
                    placeholder="Enter is blocked"
                    onChange={handleBlocked}
                    checked={isBlocked}
                  />
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Teacher" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Teacher" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Teachers</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="teachers.csv" data={teachers}>
                    Download Teachers
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminTeacher;
