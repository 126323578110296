import { useState, useEffect } from 'react';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Modal, Button, Col, Row, Table } from 'react-bootstrap';

import './MessagePage.css';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const logger = require('../logger');
const user = require('../helpers/user');
const datetime = require('../helpers/datetime');
const messageApi = require('../helpers/api/messages');
const teacherApi = require('../helpers/api/teachers');
const activityApi = require('../helpers/api/activity');

function MessagePage() {
  logger.trace('MessagePage');

  const teacher = user.getStoredTeacher();

  const [messages, setMessages] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalValue, setModalValue] = useState('');

  function OnFetchMessages(msgList) {
    logger.trace('OnFetchMessages');
    setMessages(msgList);
  }

  function OnFetchTeachers(teachersList) {
    logger.trace('OnFetchTeachers');
    setTeachers(teachersList);
  }

  function onMessageDelete() {
    logger.trace('onMessageDelete');
    messageApi.fetchMessages(teacher.teacherid, OnFetchMessages);
  }

  function deleteMessage(messageid) {
    logger.trace('deleteMessage', messageid);

    activityApi.logActivity(teacher.teacherid, 'MessagePage', 'Delete');

    messageApi.deleteMessage(messageid, onMessageDelete);
  }

  function OnMarkedAsRead() {
    logger.trace('OnMarkedAsRead');
    messageApi.fetchMessages(teacher.teacherid, OnFetchMessages);
  }

  function markMessageAsRead(messageid) {
    logger.trace('markMessageAsRead', messageid);

    activityApi.logActivity(teacher.teacherid, 'MessagePage', 'MarkAsRead');

    messageApi.markAsRead(messageid, OnMarkedAsRead);
  }

  function showModal(message) {
    logger.trace('showModal');

    setModalValue(message);
    setModalShow(true);
  }

  function handleModalClose() {
    logger.trace('handleModalClose');
    setModalShow(false);
    messageApi.markAsRead(modalValue.messageid, OnMarkedAsRead);
  }

  function onRefresh() {
    logger.trace('onRefresh');
    messageApi.fetchMessages(teacher.teacherid, OnFetchMessages);
  }

  useEffect(() => {
    logger.trace('useEffect');

    if (teacher !== null && teacher !== undefined) {
      activityApi.logActivity(teacher.teacherid, 'MessagePage', 'View');
    }

    messageApi.fetchMessages(teacher.teacherid, OnFetchMessages);
    teacherApi.fetchTeachers(OnFetchTeachers);
  }, [teacher.teacherid]);

  const headers = [
    { title: 'Title', prop: 'title', isSortable: true },
    { title: 'Message', prop: 'content', isSortable: true, isFilterable: true },
    { title: 'Date', prop: 'date', isSortable: true },
    {
      title: 'Status',
      prop: 'status',
      isSortable: true,
      /* eslint-disable-next-line */
      cell: (row) => {
        if (row.message.archived === 1) {
          return <span className="badge rounded-pill bg-primary">Archived</span>;
        }
        if (row.message.dateread === null) {
          return <span className="badge rounded-pill bg-primary">Unread</span>;
        }
        return <span className="badge rounded-pill bg-primary">Read</span>;
      }
    },
    {
      title: 'Options',
      prop: 'options',
      isSortable: true,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button value="Delete" type="submit" onClick={() => deleteMessage(row.message.messageid)}>
              <i className="bi bi-trash" />
            </button>

            {row.message.dateread === null && (
              <button value="MarkRead" type="submit" onClick={() => markMessageAsRead(row.message.messageid)}>
                <i className="bi bi-envelope-open" />
              </button>
            )}
          </div>
        );
      }
    }
  ];

  const body = [];
  messages.forEach((message) => {
    body.push({
      message,
      title: message.title,
      content: message.content,
      name: teacherApi.lookupTeacher(teachers, message.senderid),
      date: datetime.formatDateTime(message.datesent),
      status: 'Unread',
      options: 'Delete | Mark'
    });
  });

  function onRowClick(row) {
    showModal(row.message);
  }

  return (
    <>
      <Header refresh={onRefresh} />
      <div className="container main-content bg-light messages">
        <div className="row">
          <span className="messageheadline">Notifications</span>
        </div>

        <div className="row">
          <DatatableWrapper
            body={body}
            headers={headers}
            paginationOptionsProps={{
              initialState: {
                rowsPerPage: 25,
                options: [10, 25, 50, 100]
              }
            }}
          >
            <Row className="mb-4">
              <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                <Filter />
              </Col>
              <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                <PaginationOptions />
              </Col>
              <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                <Pagination />
              </Col>
            </Row>
            <Table>
              <TableHeader />
              <TableBody onRowClick={onRowClick} />
            </Table>
          </DatatableWrapper>
        </div>
      </div>

      <Footer />

      <Modal
        show={modalShow}
        onHide={(e) => handleModalClose(e)}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        style={{ opacity: 2 }}
        size="l"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{modalValue.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body dangerouslySetInnerHTML={{ __html: modalValue.content }} />

        <Modal.Footer>
          <Button onClick={(e) => handleModalClose(e)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MessagePage;
