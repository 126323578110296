import axios from 'axios';

const logger = require('../../logger');

export function createFeedback(feedbackdata, onCreateFeedback) {
  axios
    .post('/feedback/create', feedbackdata)
    .then((response) => {
      if (response.status === 200) {
        onCreateFeedback();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /feedback/create');
    });
}

export function fetchFeedbacks(onFetchFeedbacks) {
  axios
    .get('/feedbacks')
    .then((response) => {
      if (response.status === 200) {
        onFetchFeedbacks(response.data.feedbacks);
      }
    })
    .catch((error) => {
      logger.error(error, 'GET /feedbacks');
    });
}

export function deleteFeedbacksForTeacher(teacherid, onDeleteFeedbacks) {
  axios
    .delete(`/feedbacks/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        if (onDeleteFeedbacks !== null) {
          onDeleteFeedbacks();
        }
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /feedbacks/${teacherid}`);
    });
}
