import axios from 'axios';

const logger = require('../../logger');

export function fetchTeachingUnits(onFetchTeachingUnits) {
  logger.trace('fetchTeachingUnits');

  axios
    .get('/teachingunits')
    .then((response) => {
      if (response.status === 200) {
        onFetchTeachingUnits(response.data.teachingunits);
      }
    })
    .catch((error) => {
      logger.error(error, '/teachingunits');
    });
}

export function changeTeachingUnit(idOfSubject, idOfCourse, idTeachingUnit, onChangeTeachingUnit) {
  logger.trace('changeTeachingUnit');

  axios
    .get(`/subunits/${idOfSubject}/${idOfCourse}/${idTeachingUnit}`)
    .then((response) => {
      if (response.status === 200) {
        onChangeTeachingUnit(idTeachingUnit, response.data.subunits);
      }
    })
    .catch((error) => {
      logger.error(error, `GET /subunits/${idOfSubject}/${idOfCourse}/${idTeachingUnit}`);
    });
}

export function createTeachingUnit(data, onCreateTeachingUnit) {
  logger.trace('createTeachingUnit');

  logger.info('Creating Teaching Unit');

  axios
    .post('/teachingunit/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Teaching Unit created...');
        onCreateTeachingUnit();
      }
    })
    .catch((error) => {
      logger.error(error, '/teachingunit/create');
    });
}

export function updateTeachingUnit(data, onUpdateTeachingUnit) {
  logger.trace('updateTeachingUnit');

  axios
    .post('/teachingunit/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Teaching Unit updated...');
        onUpdateTeachingUnit();
      }
    })
    .catch((error) => {
      logger.error(error, '/teachingunit/update');
    });
}

export function deleteTeachingUnit(idOfTeachingUnit, onDeleteTeachingUnit) {
  logger.trace('deleteTeachingUnit');

  if (window.confirm('Are you sure you wish to delete this teaching unit?')) {
    logger.info('Deleting teaching unit:', idOfTeachingUnit);

    axios
      .delete(`/teachingunit/${idOfTeachingUnit}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Teaching Unit deleted');
          onDeleteTeachingUnit();
        }
      })
      .catch((error) => {
        logger.error(error, '/teachingunit/');
      });
  }
}

export function editTeachingUnit(idOfTeachingUnit, onEditTeachingUnit) {
  logger.trace('editTeachingUnit');

  logger.info('Editing teaching unit:', idOfTeachingUnit);
  axios
    .get(`/teachingunit/${idOfTeachingUnit}`)
    .then((response) => {
      if (response.status === 200) {
        onEditTeachingUnit(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/teachingunit/');
    });
}

export function lookupTeachingUnit(listOfTeachingUnits, idOfTeachingUnit) {
  logger.trace('lookupTeachingUnit');

  for (let i = 0; i < listOfTeachingUnits.length; i += 1) {
    if (listOfTeachingUnits[i].teachingunitid === idOfTeachingUnit) {
      return listOfTeachingUnits[i].name;
    }
  }
  return idOfTeachingUnit;
}
