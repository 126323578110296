import axios from 'axios';

const logger = require('../../logger');

export function fetchAllMessages(onFetchMessages) {
  logger.trace('fetchMessages');

  axios
    .get('/messages/')
    .then((response) => {
      if (response.status === 200) {
        onFetchMessages(response.data.messages);
      }
    })
    .catch((error) => {
      logger.error(error, 'GET /messages');
    });
}

export function fetchMessages(teacherid, onFetchMessages) {
  logger.trace('fetchMessages');

  axios
    .get(`/messages/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchMessages(response.data.messages);
      }
    })
    .catch((error) => {
      logger.error(error, `GET /messages/${teacherid}`);
    });
}

export function fetchMessageCount(teacherid, onFetchMessageCount) {
  logger.trace('fetchMessageCount');

  axios
    .get(`/messages/count/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchMessageCount(response.data.messages.count);
      }
    })
    .catch((error) => {
      logger.error(error, '/messages/count/');
    });
}

export function fetchUnreadMessageCount(teacherid, onFetchUnreadMessageCount) {
  logger.trace('fetchUnreadMessageCount');

  axios
    .get(`/messages/count/unread/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        onFetchUnreadMessageCount(response.data.messages.count);
      }
    })
    .catch((error) => {
      logger.error(error, '/messages/count/');
    });
}

export function deleteMessage(messageid, onDeleteMesage) {
  axios
    .delete(`/message/${messageid}`)
    .then((response) => {
      if (response.status === 200) {
        onDeleteMesage();
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /message/${messageid}`);
    });
}

export function deleteMessagesForTeacher(teacherid, onDeleteMesage) {
  axios
    .delete(`/messages/${teacherid}`)
    .then((response) => {
      if (response.status === 200) {
        if (onDeleteMesage !== null) {
          onDeleteMesage();
        }
      }
    })
    .catch((error) => {
      logger.error(error, `DELETE /messages/${teacherid}`);
    });
}

export function markAsRead(messageid, onMarkAsRead) {
  const postdata = {
    messageid,
    dateread: new Date()
  };

  axios
    .post('/message/update', postdata)
    .then((response) => {
      if (response.status === 200) {
        onMarkAsRead();
      }
    })
    .catch((error) => {
      logger.error(error, 'POST /message/update');
    });
}

export function createMessage(data, onCreateMessage) {
  logger.trace('createMessage');

  logger.info('Creating Message:');

  axios
    .post('/message/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Message created...');
        onCreateMessage();
      }
    })
    .catch((error) => {
      logger.error(error, '/message/create');
    });
}

export function updateMessage(data, onUpdateMessage) {
  logger.trace('updateMessage');

  logger.info('Updating Message: ', data.messageid);

  axios
    .post('/message/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Message updated...');
        onUpdateMessage();
      }
    })
    .catch((error) => {
      logger.error(error, '/message/update');
    });
}

export function editMessage(idOfMessage, onEditMessage) {
  logger.trace('editMessage');

  logger.info('Editing Message: ', idOfMessage);

  axios
    .get(`/message/${idOfMessage}`)
    .then((response) => {
      if (response.status === 200) {
        onEditMessage(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/message/');
    });
}
