/* eslint-disable */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFullScreenHandle } from 'react-full-screen';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import { CSVLink } from 'react-csv';

import './AdminHome.css';

import VideoStarterPreview from '../../components/starter/youtube/VideoStarterPrev';
import VideoStarterEdit from '../../components/starter/youtube/VideoStarterEdit';
import ImageStarterPreview from '../../components/starter/image/ImageStarterPrev';
import ImageStarterEdit from '../../components/starter/image/ImageStarterEdit';
import MultiChoiceStarterPreview from '../../components/starter/multichoice/MultiChoiceStarterPrev';
import MultiChoiceStarterEdit from '../../components/starter/multichoice/MultiChoiceStarterEdit';
import QuestionAnswerStarterPreview from '../../components/starter/questionanswer/QuestionAnswerStarterPrev';
import QuestionAnswerStarterEdit from '../../components/starter/questionanswer/QuestionAnswerStarterEdit';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';
import logger from '../../logger';

const user = require('../../helpers/user');
const adminAccess = require('./access');

const startersApi = require('../../helpers/api/starters');
const starterTypesApi = require('../../helpers/api/startertypes');
const activityApi = require('../../helpers/api/activity');

function AdminStarter() {
  logger.trace('AdminStarter');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [starterid, setStarterID] = useState(0);

  const [starters, setStarters] = useState([]);
  const [types, setTypes] = useState([]);

  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const [views, setViews] = useState(0);
  const [rating, setRating] = useState(0);

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();
  const handle = useFullScreenHandle();

  function setVariables(setdata) {
    logger.trace('setVariables');

    setName(setdata.name);
    setType(setdata.type);
    setData(setdata.data);
    setViews(setdata.views);
    setRating(setdata.rating);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setName('');
    setType('');
    setData('');
    setViews(0);
    setRating(0);
  }

  function onFetchStarters(listOfStarters) {
    logger.trace('onFetchStarters');
    setStarters(listOfStarters);
  }

  function onFetchStarterTypes(listOfStarterTypes) {
    logger.trace('onFetchStarterTypes');
    console.log(listOfStarterTypes);
    setTypes(listOfStarterTypes);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (name == null || name.length === 0) {
      errors.push('Name cannot be blank!');
    }

    if (name.includes(',')) {
      errors.push("Name cannot include commas! - I know, we'll fix it!");
    }

    if (type == null || type.length === 0) {
      errors.push('Invalid Type');
    }

    if (data == null || data.length === 0) {
      errors.push('Data cannot be blank!');
    }

    return errors;
  }

  function onCreateStarter() {
    logger.trace('onCreateStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminStarter', 'Crceate');

    resetVariables();
    startersApi.fetchStarters(onFetchStarters);
    starterTypesApi.fetchStarterTypes(onFetchStarterTypes);
  }

  function onUpdateStarter() {
    logger.trace('onUpdateStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminStarter', 'Update');

    resetVariables();
    startersApi.fetchStarters(onFetchStarters);
    starterTypesApi.fetchStarterTypes(onFetchStarterTypes);
    setCreate(true);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmission');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        name,
        type,
        data,
        views,
        rating
      };

      if (create === true) {
        startersApi.createStarter(postData, onCreateStarter);
      } else {
        postData.starterid = starterid;
        startersApi.updateStarter(postData, onUpdateStarter);
      }
    }
  }

  function onEditStarter(starteData) {
    logger.trace('onEditStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminStarter', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setStarterID(starteData.starterid);
    setCreate(false);
    setVariables(starteData);
  }

  function onDeleteStarter() {
    logger.trace('onDeleteStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminStarter', 'Delete');

    resetVariables();
    startersApi.fetchStarters(onFetchStarters);
    starterTypesApi.fetchStarterTypes(onFetchStarterTypes);
    setCreate(true);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    resetVariables();
    setCreate(true);
  }

  function onChildChange(changedData) {
    logger.trace('onChildChange');
    setData(changedData);
  }

  function changeType(value) {
    logger.trace('changeType');

    if (value === 'Video') {
      setData({ title: 'Enter title', src: 'YouTube Vide URL', thumb: 'Video Thumbnail' });
    }
    if (value === 'Image') {
      setData({ title: 'Enter title', src: 'Image URL', imageAlt: 'Image alternative' });
    }

    setType(value);
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminStarter', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    startersApi.fetchStarters(onFetchStarters);
    starterTypesApi.fetchStarterTypes(onFetchStarterTypes);
  }, []);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Name', prop: 'name', isSortable: true, isFilterable: true },
    { title: 'Type', prop: 'type', isSortable: true, isFilterable: true },
    { title: 'Views', prop: 'views', isSortable: true },
    { title: 'Rating', prop: 'rating', isSortable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => startersApi.editStarter(row.starter.starterid, onEditStarter)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => startersApi.deleteStarter(row.starter.starterid, onDeleteStarter)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  starters.forEach((starter) => {
    body.push({
      starter,
      id: starter.starterid,
      name: starter.name,
      type: starter.type,
      views: starter.views,
      rating: starter.rating,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>

          <div className="col md-10 bg-white">
            <div className="row bg-white">
              {data !== '' && type === 'Video' && <VideoStarterPreview title={data.title} src={data.src} thumb={data.thumb} handle={handle} />}
              {data !== '' && type === 'Image' && <ImageStarterPreview title={data.title} src={data.src} thumb={data.thumb} handle={handle} />}
              {data !== '' && type === 'MultiChoice' && <MultiChoiceStarterPreview title={data.title} src={data.src} src2={data.src2} thumb={data.thumb} handle={handle} />}
              {data !== '' && type === 'QuestionAnswer' && <QuestionAnswerStarterPreview title={data.title} src={data.src} src2={data.src2} thumb={data.thumb} handle={handle} />}
            </div>

            <div className="row bg-white">
              {create === true && <h2>New Lesson Starter</h2>}
              {create === false && <h2>Update Lesson Starter</h2>}

              {errorMessages.length > 0 && (
                <div>
                  <ul>
                    {errorMessages.map((message) => (
                      <li key={message}>{message}</li>
                    ))}
                  </ul>
                </div>
              )}

              <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                  <label className="form-label" htmlFor="newcon-name">
                    Name:
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      id="newcon-name"
                      placeholder="Enter lesson starter name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </label>
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="newcon-type">
                    Type:
                    <select className="form-control" name="type" id="newcon-type" onChange={(e) => changeType(e.target.value)} value={type}>
                      <option value="">--Please choose an lesson starter type--</option>
                      {types.map((t) => (
                        <option key={t} value={t}>
                          {t}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>

                {data !== '' && type === 'Video' && <VideoStarterEdit title={data.title} src={data.src} thumb={data.thumb} onChildChange={onChildChange} />}
                {data !== '' && type === 'Image' && <ImageStarterEdit title={data.title} src={data.src} imageAlt={data.imageAlt} onChildChange={onChildChange} />}
                {data !== '' && type === 'MultiChoice' && (
                  <MultiChoiceStarterEdit title={data.title} src={data.src} src2={data.src2} imageAlt={data.imageAlt} onChildChange={onChildChange} />
                )}
                {data !== '' && type === 'QuestionAnswer' && (
                  <QuestionAnswerStarterEdit title={data.title} src={data.src} src2={data.src2} imageAlt={data.imageAlt} onChildChange={onChildChange} />
                )}

                <div className="mb-3">
                  <label className="form-label" htmlFor="newcon-views">
                    Views:
                    <input
                      className="form-control"
                      type="text"
                      size="10"
                      name="views"
                      id="newcon-views"
                      placeholder="Enter views"
                      onChange={(e) => setViews(e.target.value)}
                      value={views}
                    />
                  </label>
                </div>

                <div className="mb-3">
                  <label className="form-label" htmlFor="newcon-rating">
                    Rating:
                    <input
                      className="form-control"
                      type="text"
                      size="10"
                      name="rating"
                      id="newcon-rating"
                      placeholder="Enter rating"
                      onChange={(e) => setRating(e.target.value)}
                      value={rating}
                    />
                  </label>
                </div>

                {create === true && <input className="btn btn-primary" type="submit" value="Create Lesson Starter" />}
                {create === false && (
                  <>
                    <input className="btn btn-primary" type="submit" value="Update Lesson Starter" />
                    <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                      Cancel
                    </button>
                  </>
                )}
              </form>
            </div>

            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Lesson Starters</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="lessonstarters.csv" data={starters}>
                    Download Lesson Starters
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminStarter;
