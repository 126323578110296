import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './LessonStarters.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import SearchFilterBar from './SearchFilterBar';
import SideBar from './SideBar';
import LessonStarterCard from './LessonStarterCard';
import PageButtons from '../../components/common/PageButtons';

const user = require('../../helpers/user');
const logger = require('../../logger');

const startersApi = require('../../helpers/api/starters');
const tagsApi = require('../../helpers/api/tags');
const typesApi = require('../../helpers/api/startertypes');
const coursesApi = require('../../helpers/api/courses');
const favouritesApi = require('../../helpers/api/favourites');
const activityApi = require('../../helpers/api/activity');

function LessonStarters() {
  logger.trace('LessonStarters');
  const [starters, setStarters] = useState([]);

  const [count, setCount] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [pages, setPages] = useState(0);

  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [courses, setCourses] = useState([]);

  // Search Criteria
  const [search, setSearch] = useState('');
  const [selectTypes, setSelectedTypes] = useState([]);
  const [selectFavourites, setSelectFavourites] = useState(false);
  const [selectRatings, setSelectRatings] = useState('');
  const [selectTags, setSelectTags] = useState([]);
  const [selectCourses, setSelectCourses] = useState(new Map());

  // Sort Criteria
  const [sortBy, setSortBy] = useState(useParams().sortByRating === 'sortByRating' ? '3' : '1');
  // Pagination
  const [page, setPage] = useState(0);

  const columnDepth = 5;
  const rowWidth = 3;

  const nav = useNavigate();

  const theTeacher = user.getStoredTeacher();

  function mapReplacer(key, value) {
    if (value instanceof Map || value instanceof Set) {
      return [...value];
    }
    return value;
  }

  function onFilterStarters(filteredData) {
    const listOfStarters = filteredData.starters;

    let x = 0;
    let total = 0;
    const grid = [];
    let line = [];
    grid.push(line);

    listOfStarters.forEach((starter) => {
      if (x > 2) {
        line = [];
        grid.push(line);
        x = 0;
      }
      line.push(starter);
      x += 1;
      total += 1;
    });

    setStarters(grid);
    setCount(filteredData.total);
    setStartIndex(filteredData.startIndex);
    setEndIndex(filteredData.startIndex + total);
    setPages(filteredData.pages);
  }

  function filterStarters() {
    logger.trace('filterStarters');

    const teacher = user.getStoredTeacher();

    const filter = {
      teacherid: teacher !== null ? teacher.teacherid : -1,
      search,
      types: selectTypes,
      favourites: selectFavourites,
      ratings: selectRatings,
      tags: selectTags,
      courses: JSON.stringify(selectCourses, mapReplacer),
      sortBy,
      page,
      columnDepth,
      rowWidth
    };
    logger.debug(filter);

    startersApi.filterStarters(filter, onFilterStarters);
  }

  function onFetchTags(listOfTags) {
    setTags(listOfTags);
  }

  function onFetchTypes(listOfTypes) {
    logger.trace('fetchTypes');
    setTypes(listOfTypes);
  }

  function onFetchCourseHierarchy(listOfCourses) {
    logger.trace('onFetchCourseHierarchy');
    setCourses(listOfCourses);
  }

  const deps = [selectTypes, selectFavourites, selectRatings, selectTags, selectCourses, sortBy, page];
  if (theTeacher !== null && theTeacher !== undefined) {
    deps.push(theTeacher.teacherid);
  }
  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(theTeacher !== null && theTeacher !== undefined ? theTeacher.teacherid : 0, 'LessonStartersPage', 'View');

    filterStarters();
    tagsApi.fetchTags(onFetchTags);
    typesApi.fetchStarterTypes(onFetchTypes);
    coursesApi.fetchCourseHierarchy(onFetchCourseHierarchy);
  }, deps);

  function typeChange(target) {
    logger.trace('typeChange');

    activityApi.logActivity(theTeacher !== null && theTeacher !== undefined ? theTeacher.teacherid : 0, 'LessonStartersPage', 'ChangeType');

    let arr = [];
    if (selectTypes.includes(target.name)) {
      arr = selectTypes.filter((item) => {
        return item !== target.name;
      });
    } else {
      arr = [...selectTypes, target.name];
    }

    setPage(0);
    setSelectedTypes(arr);
  }

  function selectFavouritesChange() {
    logger.trace('');

    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'FilterFavourites');

    const teacher = user.getStoredTeacher();
    if (teacher === null) {
      nav('/signin');
    }

    setPage(0);
    setSelectFavourites(!selectFavourites);
  }

  function selectRatingsChange(rating) {
    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'FilterRating');

    setPage(0);
    setSelectRatings(rating);
  }

  function courseChange(change) {
    logger.trace('courseChange');

    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'FilterCourse');

    const [courseid, teachid, subid] = change;

    // courseid, null, null           -> Course Change
    if (courseid !== null && teachid === null && subid === null) {
      if (selectCourses.has(courseid)) {
        // Remove it
        setSelectCourses(selectCourses.delete(courseid));
      } else {
        // Add it
        setSelectCourses(selectCourses.set(courseid, new Map()));
      }

      setPage(0);
      setSelectCourses(new Map(selectCourses));
    }
    // courseid, teachid, null        -> Teaching Unit change
    else if (courseid !== null && teachid !== null && subid === null) {
      // Assumption is course is defined, otherwise tezching unit not displayed
      const course = selectCourses.get(courseid);
      if (course.has(teachid)) {
        course.delete(teachid);
      } else {
        course.set(teachid, new Map());
      }

      setPage(0);
      setSelectCourses(new Map(selectCourses));
    }
    // courseid, teachid, subunitid   -> Subunit Change
    else if (courseid !== null && teachid !== null && subid !== null) {
      // Assumption is course is defined, otherwise tezching unit not displayed
      const course = selectCourses.get(courseid);
      let teachunit = course.get(teachid);

      // This occurs when we click a subunit when the parent is not selected, so
      // we create the parent first and then add the sub unit
      if (teachunit === undefined) {
        teachunit = new Map();
        course.set(teachid, teachunit);
      }

      if (teachunit.has(subid)) {
        teachunit.delete(subid);
      } else {
        teachunit.set(subid, new Map());
      }

      setPage(0);
      setSelectCourses(new Map(selectCourses));
    }
  }

  function tagsChange(target) {
    logger.trace('tagChange');

    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'FilterTags');

    const newTags = [];
    target.forEach((tag) => {
      newTags.push(tag.value);
    });

    setSelectTags(newTags);
  }

  function doSearch() {
    logger.trace('doSearch');

    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'Search');

    setPage(0);
    filterStarters();
  }

  function handleSearchChange(e) {
    logger.trace('handleSearchChange');

    setSearch(e.target.value);
  }

  useEffect(() => {
    doSearch();
  }, [search]);

  function onDeleteFavourite() {
    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'Favourite Removed');
    filterStarters();
  }

  function onCreateFavourite() {
    activityApi.logActivity(theTeacher !== undefined && theTeacher !== null ? theTeacher.teacherid : 0, 'LessonStartersPage', 'Favourite Added');
    filterStarters();
  }

  function changeFavourite(starterid) {
    logger.trace('changeFavourite');

    const teacher = user.getStoredTeacher();
    if (teacher == null) {
      logger.info('No teacher, sending to signin');
      nav('/signin');
      return;
    }

    favouritesApi.manageFavourite(starterid, teacher.teacherid, onDeleteFavourite, onCreateFavourite);
  }

  function onPageChange(event) {
    logger.trace('onPageChange');

    switch (event.target.id) {
      case '+1':
        if (endIndex < count) {
          setPage(Number(page + 1));
        }
        break;
      case '-1':
        if (startIndex > 0) {
          setPage(Number(page - 1));
        }
        break;
      default:
        setPage(Number(event.target.id));
    }

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });
  }

  return (
    <>
      <Header />

      <div className="container main-content">
        <h2>Lesson Starters</h2>

        <div className="row pb-4">
          <SearchFilterBar
            handleSearchChange={(e) => {
              handleSearchChange(e);
            }}
            doSearch={() => {
              doSearch();
            }}
            sortBy={sortBy}
            setSortBy={(v) => setSortBy(v)}
            startIndex={startIndex}
            endIndex={endIndex}
            count={count}
          />
        </div>

        <div className="row">
          <div className="col sidebar">
            <SideBar
              typeChange={(target) => {
                typeChange(target);
              }}
              types={types}
              selectTypes={selectTypes}
              selectFavouritesChange={() => {
                selectFavouritesChange();
              }}
              showSelectFavourites
              selectFavourites={selectFavourites}
              selectRatingsChange={(value) => {
                selectRatingsChange(value);
              }}
              selectRatings={selectRatings}
              tagsChange={(target) => {
                tagsChange(target);
              }}
              tags={tags}
              selectTags={selectTags}
              courses={courses}
              selectCourses={selectCourses}
              courseChange={(course, teach, sub) => {
                courseChange(course, teach, sub);
              }}
            />
          </div>
          <div className="col cardcontainer">
            {starters.length === 1 && starters[0].length === 0 && (
              <div>
                <h2>I am sorry, there are no lesson starters that match your current search critieria....</h2>
              </div>
            )}
            {starters.map((line, key1) => (
              <div key={key1} className="row">
                {line.map((starter, key2) => (
                  <div key={key2} className="col-sm-4 mb-4">
                    <LessonStarterCard
                      starter={starter}
                      changeFavourite={(id, state) => {
                        changeFavourite(id, state);
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          {starters.length > 0 && starters[0].length > 0 && (
            <div className="pagintioncontainer h-100 d-flex align-items-center justify-content-center">
              <div className="pagination">
                <PageButtons
                  pages={pages}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  count={count}
                  page={page}
                  onPageChange={(event) => {
                    onPageChange(event);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LessonStarters;
