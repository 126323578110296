import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const adminAccess = require('../../pages/admin/access');
const logger = require('../../logger');

export default function AdminRoute(props) {
  logger.trace('AdminRoute');

  AdminRoute.propTypes = {
    children: PropTypes.object.isRequired
  };

  if (adminAccess.CheckAccessToAdminScreen() === true) {
    return props.children;
  }

  return <Navigate to="/lessonstarters" />;
}
