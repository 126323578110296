/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { AiOutlineClose } from 'react-icons/ai';
import Calendar from 'react-calendar';

import { apiDiaryAppend, updateExistingEntry } from '../../helpers/api/diary';
import { formatForUpdate, isSelectedDateWeekendForDiaryModal } from '../../helpers/diary';

const logger = require('../../logger');

function AddToDiaryFromDiaryModal({
  closeModal,
  dateForModal,
  selectOptions,
  setDiaryNotification,
  setRefreshRequired,
  refreshRequired,
  starterBeingDragged,
  isModalUpdate,
  modalUpdateClassName,
  modalUpdateDate,
  starterForModal,
  titleForModal
}) {
  logger.trace('AddToDiaryFromDiaryModal');

  const [starter, setStarter] = useState(isModalUpdate ? starterBeingDragged.lessonid : undefined);
  const [className, setClassName] = useState(isModalUpdate ? modalUpdateClassName : '');
  const [entryDate, setEntryDate] = useState(isModalUpdate ? modalUpdateDate : undefined);
  const [title, setTitle] = useState(isModalUpdate ? titleForModal : undefined);
  const [isDateUpdate, setIsDateUpdate] = useState(false);
  const [dateNotification, setDateNotification] = useState({ show: false, message: '' });

  function validateForm() {
    logger.trace('validateForm');
    return starter !== undefined;
  }

  function handleChange(selected) {
    logger.trace('handleChange');

    setTitle(selected.value.name);
    setStarter(selected.value.starterid);
  }

  async function handleSubmit() {
    logger.trace('handleSubmit');

    apiDiaryAppend(starter, title, className, dateForModal, setRefreshRequired, refreshRequired, setDiaryNotification, closeModal);
  }

  function handleUpdate() {
    logger.trace('hanldeUpdate');

    updateExistingEntry(
      className,
      starterBeingDragged.entryid,
      starter,
      formatForUpdate(entryDate),
      title,
      setRefreshRequired,
      refreshRequired,
      setDiaryNotification,
      closeModal,
      isDateUpdate,
      setIsDateUpdate
    );
  }

  return (
    <div className="diary-modal-container">
      {/* NOTIFICATION */}
      {dateNotification.show && dateNotification.warn === false ? (
        <div className="diaryNotification diaryNotification-good">{dateNotification.message}</div>
      ) : dateNotification.show && dateNotification.warn === true ? (
        <div className="diaryNotification diaryNotification-bad">{dateNotification.message}</div>
      ) : (
        <div />
      )}
      <div className="diary-add-modal-header">
        {isModalUpdate === false && <h2>Add to Diary</h2>}
        {isModalUpdate && <h2>Update Entry</h2>}
        <AiOutlineClose className="close-modal-button" onClick={closeModal} />
      </div>
      <hr />
      <div className="diary-modal-container">
        <div>
          <label htmlFor="date" className="diary-modal-label">
            Select a Starter:
          </label>
          {!isModalUpdate && <Select className="react-select" options={selectOptions} onChange={handleChange} maxMenuHeight={200} placeholder="Please select a starter" />}
          {isModalUpdate && <Select className="react-select" options={selectOptions} onChange={handleChange} maxMenuHeight={200} placeholder={starterForModal.title} />}
        </div>
        {isModalUpdate === false && (
          <div>
            <label htmlFor="className" className="diary-modal-label">
              Enter your class name:
            </label>
            <input
              type="text"
              className="diary-modal-input"
              maxLength={100}
              placeholder="Enter your class name"
              onChange={(event) => setClassName(event.target.value)}
              value={className}
            />
          </div>
        )}
        &nbsp;
        {isModalUpdate && (
          <div className="center-contents">
            <Calendar
              onChange={(event) => {
                isSelectedDateWeekendForDiaryModal(event, setEntryDate, setDateNotification);
                setIsDateUpdate(true);
              }}
            />
          </div>
        )}
        {isModalUpdate && (
          <div>
            <label htmlFor="className" className="diary-modal-label">
              Class name:
            </label>
            <input type="text" className="diary-modal-input" maxLength={100} onChange={(event) => setClassName(event.target.value)} defaultValue={modalUpdateClassName} />
          </div>
        )}
        <div className="diary-modal-buttons-container center-contents">
          {!isModalUpdate && (
            <button className="diary-modal-submit-button" disabled={!validateForm()} type="submit" onClick={() => handleSubmit()}>
              Add Lesson Starter
            </button>
          )}
          {isModalUpdate && (
            <button type="submit" className="diary-modal-submit-button" onClick={handleUpdate}>
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

AddToDiaryFromDiaryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  dateForModal: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selectOptions: PropTypes.array.isRequired,
  currentDay: PropTypes.string.isRequired,
  setDiaryNotification: PropTypes.func.isRequired,
  setRefreshRequired: PropTypes.func.isRequired,
  refreshRequired: PropTypes.bool.isRequired,
  isModalUpdate: PropTypes.bool.isRequired,
  starterBeingDragged: PropTypes.object,
  modalUpdateClassName: PropTypes.string,
  modalUpdateDate: PropTypes.string,
  starterForModal: PropTypes.object,
  titleForModal: PropTypes.string
};

AddToDiaryFromDiaryModal.defaultProps = {
  dateForModal: '2000-01-01',
  starterBeingDragged: PropTypes.undefined,
  modalUpdateClassName: '',
  modalUpdateDate: PropTypes.undefined,
  titleForModal: PropTypes.undefined,
  starterForModal: undefined
};

export default AddToDiaryFromDiaryModal;
