import axios from 'axios';

const logger = require('../../logger');

export function fetchSubUnits(setSubUnits) {
  logger.trace('fetchSubUnits');

  axios
    .get('/subunits')
    .then((response) => {
      if (response.status === 200) {
        setSubUnits(response.data.subunits);
      }
    })
    .catch((error) => {
      logger.error(error, '/subunits');
    });
}

export function lookupSubUnit(subunits, idOfSubUnit) {
  logger.trace('lookupSubUnit');

  for (let i = 0; i < subunits.length; i += 1) {
    if (subunits[i].subunitid === idOfSubUnit) {
      return subunits[i].name;
    }
  }
  return idOfSubUnit;
}

export function createSubUnit(data, onCreateSubUnit) {
  logger.trace('createSubUnit');

  axios
    .post('/subunit/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Sub Unit created...');
        onCreateSubUnit();
      }
    })
    .catch((error) => {
      logger.error(error, '/subunit/create');
    });
}

export function updateSubUnit(data, onUpdateSubUnit) {
  logger.trace('updateSubUnit');

  axios
    .post('/subunit/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Sub Unit updated...');
        onUpdateSubUnit();
      }
    })
    .catch((error) => {
      logger.error(error, '/subunit/update');
    });
}

export function editSubUnit(idOfSubUnit, onEditSubUnit) {
  logger.trace('editSubUnit');

  logger.info('Editing sub unit:', idOfSubUnit);
  axios
    .get(`/subunit/${idOfSubUnit}`)
    .then((response) => {
      if (response.status === 200) {
        onEditSubUnit(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/subunit/');
    });
}

export function deleteSubUnit(idOfSubUnit, onDeleteSubUnit) {
  logger.trace('deleteSubUnit');

  if (window.confirm('Are you sure you wish to delete this sub unit?')) {
    logger.info('Deleting sub unit:', idOfSubUnit);

    axios
      .delete(`/subunit/${idOfSubUnit}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Sub Unit deleted');
          onDeleteSubUnit();
        }
      })
      .catch((error) => {
        logger.error(error, '/subunit/');
      });
  }
}
