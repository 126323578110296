import PropTypes from 'prop-types';
import YouTubeEmbed from './YouTubeEmbed';
import logger from '../../../logger';

function VideoStarterPreview(props) {
  logger.trace('VideoStarterPreview');

  VideoStarterPreview.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    handle: PropTypes.object.isRequired
  };
  const { src, handle } = props;

  return (
    <div>
      {handle !== undefined && <h2>{props.title}</h2>}
      <YouTubeEmbed embedUrl={src} handle={handle} />
    </div>
  );
}

export default VideoStarterPreview;
