import PropTypes from 'prop-types';

import './SearchFilterBar.css';

function SearchFilterBar(props) {
  SearchFilterBar.propTypes = {
    handleSearchChange: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    setSortBy: PropTypes.func.isRequired,
    startIndex: PropTypes.number.isRequired,
    endIndex: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired
  };

  const { handleSearchChange, doSearch, sortBy, setSortBy, startIndex, endIndex, count } = props;

  return (
    <>
      <div className="col-md-4">
        <div className="input-group rounded">
          <input
            type="search"
            className="form-control rounded"
            placeholder="Search for lesson starters"
            aria-label="Search"
            aria-describedby="search-addon"
            onChange={handleSearchChange}
          />
          <button type="submit" className="input-group-text border-0" id="search-addon" onClick={doSearch}>
            <i className="bi bi-search" />
          </button>
        </div>
      </div>

      <div className="col-md-4">
        <div className="input-group rounded">
          <label htmlFor="sortby" className="col-sm-2 col-form-label">
            Sort By
          </label>
          <select id="sortby" className="form-select rounded" onChange={(e) => setSortBy(e.target.value)} value={sortBy}>
            <option key="0" value="1">
              Name A-Z
            </option>
            <option key="1" value="2">
              Name Z-A
            </option>
            <option key="2" value="3">
              Ratings High - Low
            </option>
            <option key="3" value="4">
              Ratings Low - High
            </option>
          </select>
        </div>
      </div>

      <div className="col-md-4">
        <div className="input-group rounded">
          <div className="col-sm-12 col-form-label">
            Showing {startIndex + 1} - {endIndex} of {count} results
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchFilterBar;
