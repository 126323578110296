import React from 'react';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

import './PrivacyPage.css';

const logger = require('../logger');
const activityApi = require('../helpers/api/activity');

function PrivacyPage() {
  logger.trace('PrivacyPage');

  activityApi.logActivity(0, 'PrivacyPage', 'View');

  return (
    <>
      <Header />

      <div className="privacy">
        <h4>
          This Policy explains when and why we collect personal information about people who visit our website, how we use it, the conditions under which we may disclose it to
          others, and how we keep it secure.
        </h4>

        <p>
          We will change this Policy in line with legislative changes, so please check this policy on an ongoing basis to keep abreast of any changes. By using our website, you’re
          agreeing to be bound by this Policy.
        </p>

        <p>Any questions regarding this Policy and our privacy practices you can contact us here.</p>

        <h4>Who are we?</h4>
        <p>
          dressCode are a charity that is working to raise awareness of Computing Science in schools, with a particular focus on closing the gender gap. dressCode is a Scottish
          Charitable Incorporated Organisation (SCIO) regulated by the Scottish Charity Regulator (OSCR), Scottish Charity number: SC048363
        </p>

        <h4>How do we collect information from you?</h4>
        <p>We only obtain information about you when you contact us and sign up to join.</p>

        <h4>What type of information is collected from you?</h4>
        <p>The personal information we collect may include your name, address, email address, and any other personal information you choose to give to us.</p>

        <h4>How is your information used?</h4>
        <p>We may use your information to:</p>

        <p>Seek your views, comments, or feedback about dressCode.</p>

        <p>Notify you of changes to our services;</p>

        <p>Send you communications which you have requested and that may be of interest to you;</p>

        <p>To gather statistics of a number of teachers, schools, councils, pupils and male/female entries for each competition.</p>

        <h4>Information Retention</h4>
        <p>
          We review our retention periods for personal information on a regular basis. We will hold your personal information on our systems for as long as is necessary, to
          complete the purpose for which it was gathered.
        </p>

        <h4>Who has access to your information?</h4>
        <p>We will not sell or rent your information to third parties.</p>

        <p>We will not share your information with third parties for marketing purposes.</p>

        <p>
          We may transfer your personal information to a third party as part of a sale of some or all of our business and assets to any third party or as part of any business
          restructuring or reorganisation, or if we’re under a duty to disclose or share your personal data in order to comply with any legal obligation or to enforce or apply our
          terms of use or to protect the rights, property or safety of our staff and clients. However, we will take steps with the aim of ensuring that your privacy rights continue
          to be protected.
        </p>

        <h4>Consent</h4>
        <p>You have a choice about whether or not you wish to receive information from us.</p>

        <p>
          We will not continue to contact you for marketing purposes by email, phone or text message unless you have given your explicit prior consent. We will not continue to
          contact you for marketing purposes by post if you have indicated that you do not wish to be contacted. You can change your marketing preferences at any time by contacting
          us by email: toni.scullion@dresscode.org.uk
        </p>

        <h4>16 or Under</h4>
        <p>
          We are obliged to protect the privacy of children aged 16 or under. If you are aged 16 or under‚ please ensure you obtain your parent/guardian’s prior permission whenever
          you provide us with your personal information.
        </p>

        <h4>Accessing and Updating your Information</h4>
        <p>You have the right of access to your personal information held by dressCode. You also have the right to request that your personal information is permanently erased.</p>

        <p>
          Anyone wishing to access or erase their personal information should put their request in writing to the company or use the capabilities in the Account section of the App
        </p>

        <p>dressCode will endeavour to respond to any such written requests as soon as is reasonably practicable within 31 calendar days, in compliance with the GDPR.</p>

        <h4>Exemptions</h4>
        <p>Certain data is exempt from the provisions of the GDPR which includes the following: –</p>

        <p>National security and the prevention or detection of crime</p>

        <p>The assessment of any tax or duty</p>

        <p>Where the processing is necessary to exercise a right or obligation conferred or imposed by law upon the company.</p>

        <p>The above are examples only of some of the exemptions under the Act. Any further information on exemptions should be sought directly from the company</p>

        <h4>Accuracy</h4>
        <p>
          The accuracy of your information is important to us. We’re always working on ways to make it easier for you to review and correct the personal information that the
          company holds. If you change your email address, or if any of the other information we hold is inaccurate or out of date, please email us at:
          toni.scullion@dresscode.org.uk.
        </p>

        <h4>Breach</h4>
        <p>Security precautions are in place to protect the loss, misuse or alteration of your information.</p>

        <p>
          When you give us your personal information, we take steps to ensure that it is treated securely. Please be aware that whilst we take all reasonable precautions to protect
          any data in transit, we cannot 100% guarantee the security of any information you transmit to us via this medium, and you do so at your own risk. Once we receive your
          information, we make our best effort to ensure its security on our systems.
        </p>

        <p>
          If you believe your information has been compromised and would like to lodge a complaint, please contact our Data Protection Officer by emailing:
          support@dresscode.org.uk. Alternatively, you can lodge your complaint directly with the Information Commissioners Office.
        </p>

        <h3>Use of ‘cookies’</h3>
        <h4>Analytics</h4>
        <p>This website collects personal data to power our site analytics, including:</p>

        <p>Information about your browser network and device</p>

        <p>Web pages you visited prior to coming to this website</p>

        <h4>Your IP address</h4>

        <p>This information may also include details about your use of this website, including:</p>

        <p>Clicks</p>

        <p>Internal links</p>

        <p>Pages visited</p>

        <p>We share this information with AWS, our website analytics provider, to learn about site traffic and activity.</p>

        <h4>Cookies</h4>
        <p>
          This website uses cookies and similar technologies, which are small files or pieces of text that download to a device when a visitor accesses a website or app. For
          information about viewing the cookies dropped on your device, visit The cookies AWS uses.
        </p>

        <p>These functional and required cookies are always used, which allow AWS, our hosting platform, to securely serve this website to you.</p>

        <p>These analytics and performance cookies are used on this site, as described below. We use analytics cookies to view site traffic, activity, and other data.</p>

        <h4>Teacher dressCode accounts</h4>

        <p>If you sign up to run a dressCode App, we only need an email address, first and last name and appropriate password.</p>

        <h4>For website visitors</h4>

        <p>This website is hosted by Amazon AWS. AWS may collect personal data when you visit this website, including:</p>

        <p>Information about your browser, network and device</p>

        <p>Web pages you visited prior to coming to this website</p>

        <p>Your IP address</p>

        <p>AWS needs the data to run this website, and to protect and improve its platform and services. AWS analyzes the data in a de-personalized form.</p>

        <h4>Links to other websites</h4>

        <p>
          dressCode website contains links to other websites. Once you have used these links to leave our site, you should note that we do not have any control over other websites.
          We cannot be responsible for the protection and privacy of any personal information which you provide whilst visiting such sites and such sites are not governed by this
          policy.
        </p>

        <h4>Review of this Policy</h4>
        <p>We keep this Policy under regular review. This Policy was last updated in September 2023</p>

        <Footer />
      </div>
    </>
  );
}

export default PrivacyPage;
