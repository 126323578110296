import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';

import './MyAccountPage.css';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const user = require('../helpers/user');
const logger = require('../logger');
const activityApi = require('../helpers/api/activity');

const teachersApi = require('../helpers/api/teachers');

function MyAccountPage() {
  logger.trace('MyAccountPage');

  const nav = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [modalOperation, setModalOperation] = useState('');
  const [modalValue, setModalValue] = useState('Value');
  const [errorMessage, setErrorMessage] = useState('');

  let teacher = user.getStoredTeacher();

  function showModal(operation) {
    logger.trace('showModal');

    setModalOperation(operation);
    setErrorMessage('');

    switch (operation) {
      case 'Firstname':
        setModalValue(teacher.firstname);
        break;
      case 'Lastname':
        setModalValue(teacher.lastname);
        break;
      case 'Email':
        setModalValue(teacher.email);
        break;
      case 'Password':
        setModalValue('');
        break;
      default:
        setModalValue('');
        break;
    }
    setModalShow(true);
  }

  function handleModalClose() {
    setModalShow(false);
  }

  function onUpdateTeacher() {
    logger.info('Teacher updated');
  }

  function updateTeacher() {
    const data = { teacherid: teacher.teacherid, firstname: null, lastname: null, email: null, password: null, level: null, is_blocked: null };

    switch (modalOperation) {
      case 'Firstname':
        data.firstname = modalValue;
        teacher.firstname = modalValue;
        break;
      case 'Lastname':
        data.lastname = modalValue;
        teacher.lastname = modalValue;
        break;
      case 'Email':
        data.email = modalValue.toLowerCase();
        teacher.email = modalValue.toLowerCase();
        break;
      case 'Password':
        data.password = modalValue;
        break;
      default:
        logger.info('Unknown operation');
        break;
    }

    teachersApi.updateTeacher(data, onUpdateTeacher);

    teacher = user.addStoredTeacher(teacher);
  }

  function onEmailExists(result) {
    if (result === false) {
      updateTeacher();
    } else {
      logger.error('Email already exists!');
    }
  }

  function validateModalValue() {
    switch (modalOperation) {
      case 'Email':
        if (user.validateEmail(modalValue) === false) {
          logger.error('Invalid email');
          setErrorMessage('Invalid email');
          return false;
        }
        break;
      case 'Password':
        if (user.validatePassword(modalValue) === false) {
          logger.error('Invalid password');
          setErrorMessage('Invalid password');
          return false;
        }
        break;
      default:
        logger.info('Unknown operation');
        break;
    }
    return true;
  }

  function handleModalSave() {
    activityApi.logActivity(teacher.teacherid, 'MyAccountPage', `Save ${modalOperation}`);

    if (validateModalValue() === true) {
      switch (modalOperation) {
        case 'Email':
          if (modalValue.toLowerCase() !== teacher.email) {
            teachersApi.doesEmailExist(modalValue.toLowerCase(), onEmailExists);
          }
          break;
        default:
          updateTeacher();
          break;
      }

      setErrorMessage('');
      setModalValue('');

      setModalShow(false);
    }
  }

  useEffect(() => {
    logger.trace('useEffect');

    if (teacher !== null && teacher !== undefined) {
      activityApi.logActivity(teacher.teacherid, 'MyAccountPage', 'View');
    }
  });

  function onDeleteAccount() {
    logger.trace('onDeleteAccount');
    user.removeStoredTeacher();
    nav('/lessonstarters');
  }

  function deleteAccount() {
    logger.trace('deleteAccount');
    teachersApi.deleteAccount(teacher.teacherid, onDeleteAccount);
  }

  function validateForm() {
    return modalValue.length > 0;
  }

  function onValueChange(value) {
    setModalValue(value);
  }

  return (
    <>
      <Header />

      <div className="container main-content bg-light myaccount">
        <div className="row">
          <div className="col-md-3">
            <div className="row p-1">
              <h4>Your Account</h4>
            </div>
            <div className="row p-1">
              <div>First name:</div>
              <div>
                {teacher.firstname}
                <div style={{ float: 'right' }}>
                  <button type="submit" onClick={() => showModal('Firstname')}>
                    <i className="bi bi-pencil-fill text-primary" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row p-1">
              <div>Last name:</div>
              <div>
                {teacher.lastname}
                <div style={{ float: 'right' }}>
                  <button type="submit" onClick={() => showModal('Lastname')}>
                    <i className="bi bi-pencil-fill text-primary" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row p-1">
              <div>Email:</div>
              <div>
                {teacher.email}
                <div style={{ float: 'right' }}>
                  <button type="submit" onClick={() => showModal('Email')}>
                    <i className="bi bi-pencil-fill text-primary" />
                  </button>
                </div>
              </div>
            </div>
            <div className="row p-1">
              <div>Password:</div>
              <div>
                ****************
                <div style={{ float: 'right' }}>
                  <button type="submit" onClick={() => showModal('Password')}>
                    <i className="bi bi-pencil-fill text-primary" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <br />
              <br />
            </div>
            <div className="row p-1">
              <div>
                <button type="button" className="btn btn-primary" onClick={deleteAccount}>
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={modalShow}
          onHide={(e) => handleModalClose(e)}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          style={{ opacity: 2 }}
          size="l"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Edit {modalOperation}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <Form.Label>
                {modalOperation} : {errorMessage}{' '}
              </Form.Label>
              <input type="text" className="form-control" value={modalValue} placeholder={modalValue} onChange={(e) => onValueChange(e.target.value)} />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={(e) => handleModalClose(e)}>Cancel</Button>
            <Button onClick={(e) => handleModalSave(e)} disabled={!validateForm()}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Footer />
    </>
  );
}

export default MyAccountPage;
