import Select from 'react-select';

import PropTypes from 'prop-types';
import React from 'react';
import Stars from '../../components/common/Stars';
import Courses from './Courses';

function SideBar(props) {
  SideBar.propTypes = {
    typeChange: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    selectTypes: PropTypes.array.isRequired,
    showSelectFavourites: PropTypes.bool.isRequired,
    selectFavouritesChange: PropTypes.func.isRequired,
    selectFavourites: PropTypes.bool.isRequired,
    selectRatingsChange: PropTypes.func.isRequired,
    selectRatings: PropTypes.string.isRequired,
    tagsChange: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    courses: PropTypes.array.isRequired,
    selectCourses: PropTypes.object.isRequired,
    courseChange: PropTypes.func.isRequired
  };

  const {
    typeChange,
    types,
    selectTypes,
    showSelectFavourites,
    selectFavouritesChange,
    selectFavourites,
    selectRatingsChange,
    selectRatings,
    tagsChange,
    tags,
    courses,
    selectCourses,
    courseChange
  } = props;

  const options = [];
  tags.forEach((tag) => {
    options.push({ value: tag.tagid, label: tag.title });
  });

  return (
    <div className="col">
      <div className="row">
        <div className="col pb-2">
          <h4>Types</h4>
          {types.map((atype, no) => {
            return (
              <div key={no} className="checkbox">
                <label>
                  <input id={no} name={atype} key={atype} type="checkbox" onChange={(e) => typeChange(e.target)} checked={selectTypes.includes(atype) ? 'checked' : ''} /> {atype}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row">
        {showSelectFavourites === true && (
          <div className="col pb-2">
            <h4>Favourites</h4>
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={selectFavourites} onChange={selectFavouritesChange} /> Only show favourites
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col pb-2">
          <div>
            <h4>Ratings</h4>
          </div>
          <div
            className="checkbox"
            id="5"
            onClick={() => {
              selectRatingsChange('5');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Stars numOfStars={5} />
          </div>

          <div
            className="checkbox"
            id="4"
            onClick={() => {
              selectRatingsChange('4');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Stars numOfStars={4} />
            <span> & up</span>
          </div>
          <div
            className="checkbox"
            id="3"
            onClick={() => {
              selectRatingsChange('3');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Stars numOfStars={3} />
            <span> & up</span>
          </div>
          <div
            className="checkbox"
            id="2"
            onClick={() => {
              selectRatingsChange('2');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Stars numOfStars={2} />
            <span> & up</span>
          </div>
          <div
            className="checkbox"
            id="1"
            onClick={() => {
              selectRatingsChange('1');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Stars numOfStars={1} />
            <span> & up</span>
          </div>
          <div
            className="checkbox"
            id="0"
            onClick={() => {
              selectRatingsChange('0');
            }}
            style={{ cursor: 'pointer' }}
          >
            <Stars numOfStars={0} />
            <span> & up</span>
          </div>
          <div className="p-1">
            {selectRatings !== '' && (
              <button
                type="submit"
                onClick={() => {
                  selectRatingsChange('');
                }}
              >
                Clear
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col pb-2">
          <div>
            <h4>Tags</h4>
          </div>
          <Select options={options} isMulti onChange={(e) => tagsChange(e)} />
        </div>
      </div>

      <div className="row">
        <div className="col pb-2">
          <Courses courseList={courses} courseChange={(target) => courseChange(target)} selectCourses={selectCourses} />
        </div>
      </div>
    </div>
  );
}

export default SideBar;
