import { useState } from 'react';
import { Dropdown, Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Header.css';

const user = require('../../helpers/user');
const logger = require('../../logger');
const messageApi = require('../../helpers/api/messages');
const feedbackApi = require('../../helpers/api/feedback');
const activityApi = require('../../helpers/api/activity');
const sysPropApi = require('../../helpers/api/sysprops');

function Header(props) {
  logger.trace('Header');

  Header.propTypes = {
    refresh: PropTypes.func
  };

  Header.defaultProps = {
    refresh: null
  };

  const [messageCount, setMessageCount] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  const [rating, setRating] = useState('0');
  const [feedback, setFeedback] = useState('');
  const [motd, setMotd] = useState(null);

  const theTeacher = user.getStoredTeacher();

  function validateForm() {
    return feedback.length > 0;
  }

  function handleNotifications() {
    logger.trace('handleNotifications');
  }

  function handleFavourites() {
    logger.trace('handleFavourites');
  }

  function handleLessonStarters() {
    logger.trace('handleLessonStarters');
  }

  function handleMyAccount() {
    logger.trace('handleMyAccount');
  }

  function handleMyDiary() {
    logger.trace('handleMyDiary');
  }

  function handleLogout() {
    logger.trace('handleLogout');

    activityApi.logActivity(theTeacher.teacherid, 'Header', 'Logout');

    user.removeStoredTeacher();
  }

  function handleAdmin() {
    logger.trace('handleAdmin');
  }

  function showModal() {
    logger.trace('showModal');
    setModalShow(true);
  }

  function handleModalClose() {
    logger.trace('handleModalClose');
    setModalShow(false);
  }

  function handleFeedback() {
    logger.trace('handleFeedback');
    showModal();
  }

  function handleFeedbackChange(e) {
    logger.trace('handleFeedbackChange');
    setFeedback(e.target.value);
  }

  function onSetMessageCount(count) {
    logger.trace('onSetMessageCount');
    setMessageCount(count);
  }

  function onCreateMessage() {
    logger.trace('onCreateMessage');
    activityApi.logActivity(theTeacher.teacherid, 'Feedback', 'Created');
    messageApi.fetchUnreadMessageCount(theTeacher.teacherid, onSetMessageCount);
  }

  function onCreateFeedback() {
    logger.trace('onCreateFeedback');

    const messagedata = {
      senderid: 0, // System generated message
      receiverid: theTeacher !== null ? theTeacher.teacherid : 0,
      datesent: new Date(),
      dateread: null,
      archived: 0,
      title: '{{FEEDBACK}}',
      content: '',
      type: 'plain'
    };

    messageApi.createMessage(messagedata, onCreateMessage);

    setModalShow(false);

    if (props.refresh !== null) {
      props.refresh();
    }
  }

  function handleSubmitFeedback() {
    logger.trace('handleSubmitFeedback');

    const feedbackdata = {
      senderid: theTeacher !== null ? theTeacher.teacherid : 0,
      datesent: new Date(),
      feedback,
      rating
    };

    feedbackApi.createFeedback(feedbackdata, onCreateFeedback);
  }

  if (theTeacher !== null) {
    messageApi.fetchUnreadMessageCount(theTeacher.teacherid, onSetMessageCount);
  }

  function onGetMessageOfTheDay(value) {
    setMotd(value);
  }

  sysPropApi.getSysProp(sysPropApi.MOTD, onGetMessageOfTheDay);

  return (
    <header>
      <div className="container">
        {motd !== null && (
          <div className="row motd">
            <div className="col">{motd}</div>
          </div>
        )}
        <div className="row">
          <div className="col logo justify-content-start">
            <a href="/">
              <img src="/images/dressCodeLogo.png" alt="dresscode Logo" />
            </a>
          </div>

          <div className="col menu">
            <div className="row">
              <div className="col-sm menuitem p-3">
                <a href="/">Home</a>
              </div>
              <div className="col-sm menuitem p-3">
                <a href="/lessonstarters">Lesson Starters</a>
              </div>
              <div className="col-sm menuitem p-3">
                <a href="/diary">My Diary</a>
              </div>
            </div>
          </div>

          <div className="col account">
            <div className="row flex-grow-1">
              <div className="col menuitem d-flex justify-content-end align-items-center">
                {theTeacher !== null ? (
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" variant="link">
                      {theTeacher.firstname} {theTeacher.lastname}
                      <div className="bi bi-person-fill text-primary user-badge" />
                      {messageCount !== 0 && <div className="notification">{messageCount}</div>}
                    </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark">
                      <Dropdown.Item href="/lessonstarters" onClick={handleLessonStarters} className="dropdown-item">
                        Lesson Starters
                      </Dropdown.Item>
                      <Dropdown.Item href="/favourites" onClick={handleFavourites} className="dropdown-item">
                        My Favourites
                      </Dropdown.Item>
                      <Dropdown.Item href="/diary" onClick={handleMyDiary} className="dropdown-item">
                        My Diary
                      </Dropdown.Item>
                      <Dropdown.Item href="/messages" onClick={handleNotifications} className="dropdown-item">
                        Notifications
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="/home" onClick={handleMyAccount} className="dropdown-item">
                        Account
                      </Dropdown.Item>
                      {theTeacher !== null && theTeacher.level === user.ACCESS_LEVELS.ADMIN_ACCESS && (
                        <Dropdown.Item href="/admin" onClick={handleAdmin}>
                          Administration
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item type="button" onClick={handleFeedback}>
                        Feedback
                      </Dropdown.Item>
                      <Dropdown.Item href="/" onClick={handleLogout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <>
                    <a href="/signin" className="btn btn-outline-secondary me-4">
                      Log In
                    </a>
                    <a href="/signup" className="btn btn-primary">
                      Sign Up
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={modalShow}
            onHide={(e) => handleModalClose(e)}
            animation={false}
            aria-labelledby="contained-modal-title-vcenter"
            style={{ opacity: 2 }}
            size="xl"
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Leave us your feedback...</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group>We love all feedback, so let us know what you think of the app, or if you have found a bug or want to suggest a change or a new feature</Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Feedback:</Form.Label>
                <Form.Control as="textarea" rows={3} className="form-control" value={feedback} placeholder="Enter your feedback here" onChange={(e) => handleFeedbackChange(e)} />
              </Form.Group>
              <br />
              <Form.Group>
                <Form.Label>Rate the app</Form.Label>
                <Form.Control as="select" placeholder="Rate the App">
                  <option value="1">Could do better</option>
                  <option value="2">Getting there</option>
                  <option value="3">Good</option>
                  <option value="4">Great</option>
                  <option value="5">Amazing</option>
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                </Form.Control>{' '}
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={(e) => handleSubmitFeedback(e)} disabled={!validateForm()}>
                Submit
              </Button>
              <Button onClick={(e) => handleModalClose(e)}>Cancel</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </header>
  );
}

export default Header;
