import PropTypes from 'prop-types';
import './Heart.css';

const logger = require('../../logger');

function Heart(props) {
  logger.trace('Heart');

  Heart.propTypes = {
    id: PropTypes.number.isRequired,
    favourite: PropTypes.bool.isRequired,
    changeFavourite: PropTypes.func.isRequired
  };

  const { id, favourite, changeFavourite } = props;

  return (
    <>
      {favourite === false && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            changeFavourite(e.target.id, false);
          }}
        >
          <img className="heart" id={id} src="/images/heart.png" alt="heart" style={{ cursor: 'pointer' }} />
        </span>
      )}
      {favourite === true && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            changeFavourite(e.target.id, false);
          }}
        >
          <img className="heart" id={id} src="/images/heart-fill.png" alt="heart-fill" style={{ cursor: 'pointer' }} />
        </span>
      )}
    </>
  );
}

export default Heart;
