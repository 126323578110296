import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const subUnitStartersApi = require('../../helpers/api/subunitstarters');
const subUnitsApi = require('../../helpers/api/subunits');
const startersApi = require('../../helpers/api/starters');
const activityApi = require('../../helpers/api/activity');

function AdminSubUnitStarters() {
  logger.trace('AdminSubUnitStarters');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);

  const [subunitstarterid, setSubUnitStarterID] = useState(0);

  const [subunitstarters, setSubUnitStarters] = useState([]);

  const [subunits, setSubUnits] = useState([]);
  const [starters, setStarters] = useState([]);

  const [subunitid, setSubUnitID] = useState(0);
  const [starterid, setStarterID] = useState(0);

  const nav = useNavigate();
  if (!adminAccess.CheckAccessToAdminScreen) {
    nav('/lessonstarters');
  }
  const teacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');

    setSubUnitID(data.subunitid);
    setStarterID(data.starterid);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setSubUnitID(0);
    setStarterID(0);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (subunitid == null || subunitid === 0) {
      errors.push('Invalid Sub Unit!');
    }

    if (starterid == null || starterid === 0) {
      errors.push('Invalid Starter!');
    }

    return errors;
  }

  function onFetchSubUnitStarters(listOfSubUnitStarters) {
    logger.trace('onFetchSubUnitStarters');
    setSubUnitStarters(listOfSubUnitStarters);
  }

  function onFetchSubUnits(listOfSubUnits) {
    logger.trace('onFetchSubUnits');
    setSubUnits(listOfSubUnits);
  }

  function onFetchStarters(listOfStarters) {
    logger.trace('onFetchStarters');
    setStarters(listOfStarters);
  }

  function onCreateSubUnitStarter() {
    logger.trace('onCreateSubUnitStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Create');

    resetVariables();
    subUnitStartersApi.fetchSubUnitStarters(onFetchSubUnitStarters);
    subUnitsApi.fetchSubUnits(onFetchSubUnits);
    startersApi.fetchStarters(onFetchStarters);
  }

  function onUpdateSubUnitStarter() {
    logger.trace('onUpdateSubUnitStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Update');

    setSubUnitStarterID(0);
    resetVariables();
    subUnitStartersApi.fetchSubUnitStarters(onFetchSubUnitStarters);
    setCreate(true);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        subunitid,
        starterid
      };

      if (create === true) {
        subUnitStartersApi.createSubUnitStarter(postData, onCreateSubUnitStarter);
      } else {
        postData.subunitstarterid = subunitstarterid;
        subUnitStartersApi.updateSubUnitStarter(postData, onUpdateSubUnitStarter);
      }
    }
  }

  function onEditSubUnitStarter(subUnitStarterData) {
    logger.trace('onEditSubUnitStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setSubUnitStarterID(subUnitStarterData.subunitstarterid);
    setCreate(false);
    setVariables(subUnitStarterData);
  }

  function onDeleteSubUnitStarter() {
    logger.trace('onDeleteSubUnitStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'Delete');

    setCreate(true);
    subUnitStartersApi.fetchSubUnitStarters(onFetchSubUnitStarters);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    resetVariables();
    setCreate(true);
    setSubUnitStarterID(0);
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminSubUnitStarter', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    subUnitStartersApi.fetchSubUnitStarters(onFetchSubUnitStarters);
    subUnitsApi.fetchSubUnits(onFetchSubUnits);
    startersApi.fetchStarters(onFetchStarters);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Sub Unit', prop: 'subunit', isSortable: true, isFilterable: true },
    { title: 'Lesson Starter', prop: 'starter', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => subUnitStartersApi.editSubUnitStarter(row.subunitstarter.subunitstarterid, onEditSubUnitStarter)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => subUnitStartersApi.deleteSubUnitStarter(row.subunitstarter.subunitstarterid, onDeleteSubUnitStarter)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  subunitstarters.forEach((subunitstarter) => {
    body.push({
      subunitstarter,
      id: subunitstarter.subunitstarterid,
      subunit: subUnitsApi.lookupSubUnit(subunits, subunitstarter.subunitid),
      starter: startersApi.lookupStarter(starters, subunitstarter.starterid),
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>Link Sub Unit to Starter</h2>}
            {create === false && <h2>Link Sub Unit to Starter</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="lesscon-subunitid">
                  Sub Unit
                  <select className="form-control" name="newsubunit-subunit" id="newsubunit-subunitid" onChange={(e) => setSubUnitID(e.target.value)} value={subunitid}>
                    <option key="0" value="">
                      --Please choose a teaching unit--
                    </option>
                    {subunits.map((subunit) => (
                      <option key={subunit.subunitid} value={subunit.subunitid}>
                        {subunit.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="lesscon-starterid">
                  Starter
                  <select className="form-control" name="newsubunit-starterid" id="newsubunit-starterid" onChange={(e) => setStarterID(e.target.value)} value={starterid}>
                    <option key="0" value="">
                      --Please choose a teaching unit--
                    </option>
                    {starters.map((starter) => (
                      <option key={starter.starterid} value={starter.starterid}>
                        {starter.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Sub Unit" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Sub Unit" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>
            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Sub Unit Starters</h2>
                </div>
                <div style={{ float: 'right', width: '220px' }}>
                  <CSVLink filename="subunitstarters.csv" data={subunitstarters}>
                    Download Sub Unit Starters
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminSubUnitStarters;
