import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const startersApi = require('../../helpers/api/starters');
const starterTagsApi = require('../../helpers/api/startertags');
const tagsApi = require('../../helpers/api/tags');
const activityApi = require('../../helpers/api/activity');

function AdminStarterTag() {
  logger.trace('AdminStarterTag');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [startertagid, setStarterTagID] = useState(0);

  const [startertags, setStarterTags] = useState([]);

  const [starters, setStarters] = useState([]);
  const [tags, setTags] = useState([]);

  const [starterid, setStarterID] = useState(0);
  const [tagid, setTagID] = useState(0);

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');

    setStarterID(data.starterid);
    setTagID(data.tagid);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setStarterID(0);
    setTagID(0);
  }

  function onFetchStarterTags(listOfStarterTags) {
    logger.trace('onFetchStarterTags');
    setStarterTags(listOfStarterTags);
  }

  function onFetchStarters(listOfStarters) {
    logger.trace('onFetchStarters');
    setStarters(listOfStarters);
  }

  function onFetchTags(listOfTags) {
    logger.trace('onFetchTags');
    setTags(listOfTags);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (starterid == null || starterid === 0) {
      errors.push('Invalid Starter!');
    }

    if (tagid == null || tagid === 0) {
      errors.push('Invalid Tag!');
    }

    return errors;
  }

  function onCreateStarterTag() {
    logger.trace('onCreateStarterTag');

    activityApi.logActivity(teacher.teacherid, 'AdminStarterTag', 'Create');

    setStarterID('');
    setTagID('');
    setCreate(true);

    starterTagsApi.fetchStarterTags(onFetchStarterTags);
    startersApi.fetchStarters(onFetchStarters);
    tagsApi.fetchTags(onFetchTags);
  }

  function onUpdateStarterTag() {
    logger.trace('onUpdateStarterTag');

    activityApi.logActivity(teacher.teacherid, 'AdminStarterTag', 'Update');

    setStarterID('');
    setTagID('');
    setCreate(true);

    starterTagsApi.fetchStarterTags(onFetchStarterTags);
    startersApi.fetchStarters(onFetchStarters);
    tagsApi.fetchTags(onFetchTags);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        starterid,
        tagid
      };

      if (create === true) {
        starterTagsApi.createStarterTag(postData, onCreateStarterTag);
      } else {
        postData.startertagid = startertagid;
        starterTagsApi.updateStarterTag(postData, onUpdateStarterTag);
      }
    }
  }

  function onDeleteStarterTag() {
    logger.trace('onDeleteStarterTag');

    activityApi.logActivity(teacher.teacherid, 'AdminStarterTag', 'Delete');

    setStarterID('');
    setTagID('');
    setCreate(true);

    starterTagsApi.fetchStarterTags(onFetchStarterTags);
    startersApi.fetchStarters(onFetchStarters);
    tagsApi.fetchTags(onFetchTags);
  }

  function onEditStarterTag(starterTagData) {
    logger.trace('editStarterTag');

    activityApi.logActivity(teacher.teacherid, 'AdminStarterTag', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setStarterTagID(starterTagData.startertagid);
    setCreate(false);
    setVariables(starterTagData);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    logger.info('Canceling Edit');
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminStarterTag', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    starterTagsApi.fetchStarterTags(onFetchStarterTags);
    startersApi.fetchStarters(onFetchStarters);
    tagsApi.fetchTags(onFetchTags);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Lesson Starter', prop: 'starter', isSortable: true, isFilterable: true },
    { title: 'Tag', prop: 'tag', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => starterTagsApi.editStarterTag(row.startertag.startertagid, onEditStarterTag)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => starterTagsApi.deleteStarterTag(row.startertag.startertagid, onDeleteStarterTag)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  startertags.forEach((startertag) => {
    body.push({
      startertag,
      id: startertag.startertagid,
      starter: startersApi.lookupStarter(starters, startertag.starterid),
      tag: tagsApi.lookupTag(tags, startertag.tagid),
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Starter Tag</h2>}
            {create === false && <h2>Update Starter Tag</h2>}
            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="contag-starterid">
                  Starter
                  <select className="form-control" name="contag-starterid" id="contag-starterid" onChange={(e) => setStarterID(e.target.value)} value={starterid}>
                    <option key="0" value="">
                      --Please choose a lesson starter--
                    </option>
                    {starters.map((starter) => (
                      <option key={starter.starterid} value={starter.starterid}>
                        {starter.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="contag-tagid">
                  Tag
                  <select className="form-control" name="contag-tagid" id="contag-tagid" onChange={(e) => setTagID(e.target.value)} value={tagid}>
                    <option key="0" value="">
                      --Please choose a tag--
                    </option>
                    {tags.map((tag) => (
                      <option key={tag.tagid} value={tag.tagid}>
                        {tag.title}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Starter Tag" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Starter Tag" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>
            <hr />
            <div className="row bg-white">
              <div>
                <div>
                  <h2>Starter Tags</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="startertags.csv" data={startertags}>
                    Download Starter Tags
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>{' '}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminStarterTag;
