import axios from 'axios';

const logger = require('../../logger');

export function fetchStarterTypes(onFetchStarterTypes) {
  logger.trace('fetchStarterTypes');

  axios
    .get('/startertypes')
    .then((response) => {
      onFetchStarterTypes(response.data.types);
    })
    .catch((error) => {
      logger.error(error);
    });
}

export function dummy() {}
