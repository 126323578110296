import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const user = require('../../helpers/user');
const logger = require('../../logger');

export default function PrivateRoute(props) {
  logger.trace('PrivateRoute');

  PrivateRoute.propTypes = {
    children: PropTypes.object.isRequired
  };

  const teacher = user.getStoredTeacher();

  if (teacher !== null && teacher !== undefined) {
    return props.children;
  }

  return <Navigate to="/signin" />;
}
