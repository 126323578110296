import axios from 'axios';

const logger = require('../../logger');

export function fetchSchools(onFetchSchools) {
  logger.trace('fetchSchools');

  axios
    .get('/schools')
    .then((response) => {
      if (response.status === 200) {
        onFetchSchools(response.data.schools);
      }
    })
    .catch((error) => {
      logger.error(error, '/schools');
    });
}

export function createSchool(data, onCreateSchool) {
  logger.trace('createSchool');

  axios
    .post('/school/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('School created...');
        onCreateSchool();
      }
    })
    .catch((error) => {
      logger.error(error, '/school/create');
    });
}

export function updateSchool(data, onUpdateSchool) {
  logger.trace('updateSchool');

  axios
    .post('/school/update', data)
    .then((response) => {
      if (response.status === 200) {
        onUpdateSchool();
      }
    })
    .catch((error) => {
      logger.error(error, 'UPDATE /school/update');
    });
}

export function editSchool(idOfSchool, onEditSchool) {
  logger.trace('editSchool');

  logger.info('Editing school');

  axios
    .get(`/school/${idOfSchool}`)
    .then((response) => {
      if (response.status === 200) {
        onEditSchool(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `GET /school/${idOfSchool}`);
    });
}

export function deleteSchool(idOfSchool, onDeleteSchool) {
  logger.trace('deleteSchool');

  if (window.confirm('Are you sure you wish to delete this Lesson Starter?')) {
    logger.info('Deleting school', idOfSchool);

    axios
      .delete(`/school/${idOfSchool}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('School deleted');
          onDeleteSchool();
        }
      })
      .catch((error) => {
        logger.error(error, '/school/');
      });
  }
}

export function lookupSchool(listOfSchools, idOfSchool) {
  logger.trace('lookupSchool');

  for (let i = 0; i < listOfSchools.length; i += 1) {
    if (listOfSchools[i].schoolid === idOfSchool) {
      return listOfSchools[i].name;
    }
  }
  return idOfSchool;
}
