import { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const user = require('../../helpers/user');

const entriesApi = require('../../helpers/api/diary');
const teachersApi = require('../../helpers/api/teachers');
const startersApi = require('../../helpers/api/starters');
const activityApi = require('../../helpers/api/activity');

function AdminDiary() {
  logger.trace('AdminDiary');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);

  const [entries, setEntries] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [starters, setStarters] = useState([]);

  const [entryid, setEntryId] = useState(0);
  const [description, setDescription] = useState('');
  const [starterid, setStarterId] = useState(0);
  const [teacherid, setTeacherId] = useState(0);
  const [date, setDate] = useState('');
  const [entrypos, setEntryPos] = useState(0);

  const teacher = user.getStoredTeacher();

  function formatDate(theDate) {
    logger.trace('formatDate');
    return theDate !== null ? new Date(theDate).toLocaleDateString('en-GB') : '';
  }

  function setVariables(data) {
    logger.trace('setVariables');

    setEntryId(data.entryid);
    setDescription(data.description);
    setStarterId(data.lessonid);
    setTeacherId(data.teacherid);
    setDate(formatDate(data.date));
    setEntryPos(data.entrypos);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setEntryId(0);
    setDescription('');
    setStarterId(0);
    setTeacherId(0);
    setDate('');
    setEntryPos(0);
  }

  function onFetchEntries(listOfEntries) {
    logger.trace('onFetchEntries');
    setEntries(listOfEntries);
  }

  function onFetchTeachers(listOfTeachers) {
    logger.trace('onFetchTeachers');
    setTeachers(listOfTeachers);
  }

  function onFetchStarters(listOfStarters) {
    logger.trace('onFetchStarters');
    setStarters(listOfStarters);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (teacherid == null || teacherid === 0) {
      errors.push('Invalid Teacher!');
    }

    if (starterid == null || starterid === 0) {
      errors.push('Invalid Starter!');
    }

    return errors;
  }

  function onCreateEntry() {
    logger.trace('onCreateEntry');

    activityApi.logActivity(teacher.teacherid, 'AdminDiary', 'Create');

    setCreate(true);

    resetVariables();

    entriesApi.fetchEntries(onFetchEntries);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function onUpdateEntry() {
    logger.trace('updateEntry');

    activityApi.logActivity(teacher.teacherid, 'AdminDiary', 'Update');

    setCreate(true);

    setTeacherId(0);
    setStarterId(0);

    entriesApi.fetchEntries(onFetchEntries);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        title: '',
        description,
        lessonid: starterid,
        teacherid,
        date,
        entrypos
      };

      if (create === true) {
        entriesApi.createEntry(postData, onCreateEntry);
      } else {
        postData.entryid = entryid;
        entriesApi.updateEntry(postData, onUpdateEntry);
      }
    }
  }

  function onDeleteEntry() {
    logger.trace('deleteEntry');

    activityApi.logActivity(teacher.teacherid, 'AdminDiary', 'Delete');

    setTeacherId(0);
    setStarterId(0);

    entriesApi.fetchEntries(onFetchEntries);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);

    setCreate(true);
  }

  function onEditEntry(entry) {
    logger.trace('onEditEntry');

    activityApi.logActivity(teacher.teacherid, 'AdminDiary', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setEntryId(entry.entryid);
    setCreate(false);
    setVariables(entry);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');

    logger.info('Cancelling Edit');

    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminFavourtie', 'View');

    entriesApi.fetchEntries(onFetchEntries);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Lesson Starter', prop: 'lesson', isSortable: true, isFilterable: true },
    { title: 'Description', prop: 'description', isSortable: true, isFilterable: true },
    { title: 'Teacher', prop: 'teacher', isSortable: true, isFilterable: true },
    { title: 'Date', prop: 'date', isSortable: true, isFilterable: true },
    { title: 'EntryPos', prop: 'entrypos', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => entriesApi.editEntry(row.entry.entryid, onEditEntry)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => entriesApi.deleteEntry(row.entry.entryid, onDeleteEntry)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  entries.forEach((entry) => {
    body.push({
      entry,
      id: entry.entryid,
      lesson: startersApi.lookupStarter(starters, entry.lesson_id),
      description: entry.description,
      teacher: teachersApi.lookupTeacher(teachers, entry.teacher_id),
      date: formatDate(entry.date),
      entrypos: entry.entrypos,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Entry</h2>}
            {create === false && <h2>Update Entry</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="contag-starterid">
                  Starter
                  <select className="form-control" name="contag-starterid" id="contag-starterid" onChange={(e) => setStarterId(e.target.value)} value={starterid}>
                    <option key="0" value="">
                      --Please choose a lesson starter--
                    </option>
                    {starters.map((starter) => (
                      <option key={starter.starterid} value={starter.starterid}>
                        {starter.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newentry-name">
                  Class/Description
                  <input
                    className="form-control"
                    type="text"
                    name="description"
                    id="newentry-description"
                    placeholder="Enter a description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newentry-teacherid">
                  Teacher
                  <select className="form-control" name="teacherid" id="newentry-teacherid" onChange={(e) => setTeacherId(e.target.value)} value={teacherid}>
                    <option value="">--Please choose a teacher--</option>
                    {teachers.map((ateacher) => (
                      <option key={ateacher.teacherid} value={ateacher.teacherid}>
                        {ateacher.firstname} {ateacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newentry-date">
                  Name
                  <input className="form-control" type="text" name="date" id="newentry-date" placeholder="DD/MM/YYYY" onChange={(e) => setDate(e.target.value)} value={date} />
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newentry-entrypos">
                  EntryPos
                  <input
                    className="form-control"
                    type="text"
                    name="entrypos"
                    id="newentry-entrypos"
                    placeholder="Enter a entrypos"
                    onChange={(e) => setEntryPos(e.target.value)}
                    value={entrypos}
                  />
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Entry" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Entry" />
                  <button className="btn btn-secondary" value="Cancel" type="submit" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="container bg-white">
              <div>
                <h2>Entries</h2>
              </div>
              <div style={{ float: 'right', width: '200px' }}>
                <CSVLink filename="entries.csv" data={entries}>
                  Download Entries
                </CSVLink>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminDiary;
