import PropTypes from 'prop-types';

import Stars from './Stars';

const logger = require('../../logger');

function Comments(props) {
  logger.trace('Comments');

  Comments.propTypes = {
    ratings: PropTypes.array.isRequired
  };

  const { ratings } = props;

  return (
    <div className="comments">
      {ratings.map((rating) => (
        <div id={rating.ratingid} key={rating.ratingid} className="pb-1">
          <div className="commentstars">
            <Stars numOfStars={rating.rating} />
          </div>
          <div className="commenttext">{rating.comment}</div>
        </div>
      ))}
    </div>
  );
}

export default Comments;
