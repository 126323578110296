import PropTypes from 'prop-types';

import ImageStarterPreview from '../../components/starter/image/ImageStarterPrev';
import VideoStarterPreview from '../../components/starter/youtube/VideoStarterPrev';
import MultiChoiceStarterPreview from '../../components/starter/multichoice/MultiChoiceStarterPrev';
import QuestionAnswerStarterPreview from '../../components/starter/questionanswer/QuestionAnswerStarterPrev';

import LessonImg from './LessonImg';

const logger = require('../../logger');

function Starter(props) {
  Starter.propTypes = {
    starter: PropTypes.object.isRequired,
    handle: PropTypes.object.isRequired
  };

  const { starter, handle } = props;

  const showStarter = () => {
    switch (starter.type) {
      case 'Video':
        return <VideoStarterPreview handle={handle} title={starter.data.title} src={starter.data.src} thumb="" />;

      case 'Image':
        return <ImageStarterPreview handle={handle} title={starter.data.title} src={starter.data.src} imageAlt={starter.data.imageAlt} />;

      case 'MultiChoice':
        return <MultiChoiceStarterPreview handle={handle} title={starter.data.title} src={starter.data.src} src2={starter.data.src2} imageAlt={starter.data.imageAlt} />;

      case 'QuestionAnswer':
        return <QuestionAnswerStarterPreview handle={handle} title={starter.data.title} src={starter.data.src} src2={starter.data.src2} imageAlt={starter.data.imageAlt} />;

      default:
        logger.warn(`Starter Preview handler missing for ${starter.type} in <Starter />`);
        return <LessonImg handle={handle} starter={starter} />;
    }
  };

  if (starter !== null) {
    return <div>{showStarter()}</div>;
  }
  return <div>Starter unavailable</div>;
}

export default Starter;
