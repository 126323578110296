import axios from 'axios';

const logger = require('../../logger');

export function fetchSubUnitStarters(onFetchSubUnitStarters) {
  logger.trace('fetchSubUnitStarters');

  axios
    .get('/subunitstarters')
    .then((response) => {
      if (response.status === 200) {
        onFetchSubUnitStarters(response.data.subunitstarters);
      }
    })
    .catch((error) => {
      logger.error(error, '/subunitstarters');
    });
}

export function createSubUnitStarter(data, onCreateSubUnitStarter) {
  axios
    .post('/subunitstarter/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('SubUnit Starter created...');
        onCreateSubUnitStarter();
      }
    })
    .catch((error) => {
      logger.error(error, '/subunitstarter/create');
    });
}

export function updateSubUnitStarter(data, onUpdateSubUnitStarter) {
  logger.trace('updateSubUnitStarter');

  axios
    .post('/subunitstarter/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Sub Unit Starter updated...');
        onUpdateSubUnitStarter();
      }
    })
    .catch((error) => {
      logger.error(error, '/subunitstarter/update');
    });
}

export function editSubUnitStarter(idOfSubUnitStarter, onEditSubUnitStarter) {
  logger.trace('editSubUnitStarter');

  logger.info('Editing Sub Unit Starter: ', idOfSubUnitStarter);

  axios
    .get(`/subunitstarter/${idOfSubUnitStarter}`)
    .then((response) => {
      if (response.status === 200) {
        onEditSubUnitStarter(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/subunitstarter/');
    });
}

export function deleteSubUnitStarter(idOfSubUnitStarter, onDeleteSubUnitStarter) {
  logger.trace('deleteSubUnitStarter');

  if (window.confirm('Are you sure you wish to delete this sub unit starter link?')) {
    logger.info('Deleting sub unit starter:', idOfSubUnitStarter);

    axios
      .delete(`/subunitstarter/${idOfSubUnitStarter}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Sub Unit Starter deleted');
          onDeleteSubUnitStarter();
        }
      })
      .catch((error) => {
        logger.error(error, '/subunitstarter/');
      });
  }
}
