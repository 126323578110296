import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const schoolTeachersApi = require('../../helpers/api/schoolteachers');
const teachersApi = require('../../helpers/api/teachers');
const schoolsApi = require('../../helpers/api/schools');
const activityApi = require('../../helpers/api/activity');

function AdminSchoolTeacher() {
  logger.trace('AdminSchoolTeacher');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);

  const [schools, setSchools] = useState([]);

  const [teachers, setTeachers] = useState([]);
  const [schoolteachers, setSchoolTeachers] = useState([]);
  const [schoolteacherid, setSchoolTeacherID] = useState(0);

  const [schoolid, setSchoolID] = useState(0);
  const [teacherid, setTeacherID] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function resetVariables() {
    logger.trace('resetVariables');

    setSchoolID(0);
    setTeacherID(0);
    setStartDate(new Date());
    setEndDate('');
  }

  function formatDate(theDate) {
    logger.trace('formatDate');
    return theDate !== null ? new Date(theDate).toLocaleDateString('en-GB') : '';
  }

  function setVariables(data) {
    logger.trace('setVariables');

    setSchoolID(data.schoolid);
    setTeacherID(data.teacherid);
    setStartDate(formatDate(data.start_date));
    setEndDate(data.end_date !== null ? formatDate(data.end_date) : '');
  }

  function onFetchSchoolTeachers(listOfSchoolTeachers) {
    logger.trace('onFetchSchoolTeachers');
    setSchoolTeachers(listOfSchoolTeachers);
  }

  function onFetchSchools(listOfSchools) {
    logger.trace('onFetchSchools');
    setSchools(listOfSchools);
  }

  function onFetchTeachers(listOfTeachers) {
    logger.trace('onFetchTeachers');
    setTeachers(listOfTeachers);
  }

  function onCreateSchoolTeacher() {
    logger.trace('onCreateSchoolTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminSchoolTeacher', 'Create');

    resetVariables();
    schoolTeachersApi.fetchSchoolTeachers(onFetchSchoolTeachers);
    schoolsApi.fetchSchools(onFetchSchools);
    teachersApi.fetchTeachers(onFetchTeachers);
  }

  function onUpdateSchoolTeacher() {
    logger.trace('onUpdateSchoolTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminSchoolTeacher', 'Update');

    setCreate(true);
    resetVariables();
    schoolTeachersApi.fetchSchoolTeachers(onFetchSchoolTeachers);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (schoolid === null || schoolid === 0) {
      errors.push('Invalid school');
    }

    if (teacherid === null || teacherid === 0) {
      errors.push('Invalid teacher');
    }

    const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

    if (startDate === null || startDate.length === 0) {
      errors.push('Invalid start date');
    } else if (startDate.match(dateRegex) == null) {
      errors.push('Invalid start date!');
    }

    if (endDate !== null && endDate.length > 0) {
      if (endDate.match(dateRegex) == null) {
        errors.push('Invalid end date!');
      }
    }

    return errors;
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        schoolid,
        teacherid,
        start_date: startDate,
        end_date: endDate
      };

      if (create === true) {
        schoolTeachersApi.createSchoolTeacher(postData, onCreateSchoolTeacher);
      } else {
        postData.schoolteacherid = schoolteacherid;
        schoolTeachersApi.updateSchoolTeacher(postData, onUpdateSchoolTeacher);
      }
    }
  }

  function onDeleteSchoolTeacher() {
    logger.trace('onDeleteSchoolTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminSchoolTeacher', 'Delete');

    setCreate(true);
    resetVariables();
    schoolTeachersApi.fetchSchoolTeachers(onFetchSchoolTeachers);
    schoolsApi.fetchSchools(onFetchSchools);
    teachersApi.fetchTeachers(onFetchTeachers);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    logger.info('Cancelling Edit');
    setCreate(true);
    resetVariables();
  }

  function onEditSchoolTeacher(schoolTeacherData) {
    logger.trace('onEditSchoolTeacher');

    activityApi.logActivity(teacher.teacherid, 'AdminSchoolTeacher', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setSchoolTeacherID(schoolTeacherData.schoolteacherid);
    setCreate(false);
    setVariables(schoolTeacherData);
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminSchoolTeacher', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }
  }, [teacher.teacherid]);

  schoolTeachersApi.fetchSchoolTeachers(onFetchSchoolTeachers);
  schoolsApi.fetchSchools(onFetchSchools);
  teachersApi.fetchTeachers(onFetchTeachers);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'School', prop: 'school', isSortable: true, isFilterable: true },
    { title: 'Teacher', prop: 'teacher', isSortable: true, isFilterable: true },
    { title: 'Start Date', prop: 'start_date', isSortable: true, isFilterable: true },
    { title: 'End Date', prop: 'end_date', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => schoolTeachersApi.editSchoolTeacher(row.schoolteacher.schoolteacherid, onEditSchoolTeacher)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => schoolTeachersApi.deleteSchoolTeacher(row.schoolteacher.schoolteacherid, onDeleteSchoolTeacher)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  schoolteachers.forEach((schoolteacher) => {
    body.push({
      schoolteacher,
      id: schoolteacher.schoolteacherid,
      school: schoolsApi.lookupSchool(schools, schoolteacher.schoolid),
      teacher: teachersApi.lookupTeacherFullname(teachers, schoolteacher.teacherid),
      start_date: formatDate(schoolteacher.start_date),
      end_date: formatDate(schoolteacher.end_date),
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New School Teacher</h2>}
            {create === false && <h2>Update School Teacher</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newschteach-schoolid">
                  School:
                  <select className="form-control" name="schoolid" id="newschteach-schoolid" onChange={(e) => setSchoolID(e.target.value)} value={schoolid}>
                    <option value="">--Please choose a school--</option>
                    {schools.map((school) => (
                      <option key={school.schoolid} value={school.schoolid}>
                        {school.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newschteach-teacherid">
                  Teacher:
                  <select className="form-control" name="teacherid" id="newschteach-teacherid" onChange={(e) => setTeacherID(e.target.value)} value={teacherid}>
                    <option value="">--Please choose a school--</option>
                    {teachers.map((ateacher) => (
                      <option key={ateacher.teacherid} value={ateacher.teacherid}>
                        {ateacher.firstname} {ateacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newschteach-startdate">
                  Start Date:
                  <input
                    className="form-control"
                    type="text"
                    name="startDate"
                    id="newschteach-startdate"
                    placeholder="DD/MM/YYYY"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                  />
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newschteach-enddate">
                  End Date:
                  <input
                    className="form-control"
                    type="text"
                    name="endDate"
                    id="newschteach-enddate"
                    placeholder="DD/MM/YYYY"
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                  />
                </label>
              </div>

              {create === true && <input className="btn btn-primary" type="submit" value="Create School Teacher" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update School Teacher" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="row  bg-white">
              <div>
                <div>
                  <h2>Schooll Teachers</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="schoolteachers.csv" data={schoolteachers}>
                    Download School Teachers
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminSchoolTeacher;
