import React from 'react';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

import './NotFound.css';

const logger = require('../logger');
const activityApi = require('../helpers/api/activity');

function NotFound() {
  logger.trace('NotFound');

  activityApi.logActivity(0, 'PrivacyPage', 'View');

  return (
    <>
      <Header />

      <div className="notfound">
        <center>
          <br />
          <br />
          <h1>404 - Page Not Found</h1>
          <br />
          <br />
          <h3>Thats not the page you are looking for!</h3>
          <br />
          <br />
        </center>
      </div>

      <Footer />
    </>
  );
}

export default NotFound;
