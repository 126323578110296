import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const subjectsApi = require('../../helpers/api/subjects');
const activityApi = require('../../helpers/api/activity');

function AdminSubject() {
  logger.trace('');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [subjectid, setSubjectID] = useState(0);

  const [subjects, setSubjects] = useState([]);

  const [name, setName] = useState('');

  function setVariables(data) {
    logger.trace('');

    setName(data.name);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setName('');
  }

  const nav = useNavigate();
  if (!adminAccess.CheckAccessToAdminScreen) {
    nav('/lessonstarters');
  }
  const teacher = user.getStoredTeacher();

  function onFetchSubjects(listOfSubjects) {
    logger.trace('onFetchSubjects');
    setSubjects(listOfSubjects);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (name == null || name.length === 0) {
      errors.push('Name cannot be blank!');
    }

    return errors;
  }

  function onCreateSubject() {
    logger.trace('onCreateSubject');

    activityApi.logActivity(teacher.teacherid, 'AdminSubject', 'Create');

    setName('');
    subjectsApi.fetchSubjects(onFetchSubjects);
  }

  function onUpdateSubject() {
    logger.trace('onUpdateSubject');

    activityApi.logActivity(teacher.teacherid, 'AdminSubject', 'Update');

    setCreate(true);
    setSubjectID(0);
    resetVariables();
    subjectsApi.fetchSubjects(onFetchSubjects);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const data = {
        name
      };

      if (create === true) {
        subjectsApi.createSubject(data, onCreateSubject);
      } else {
        data.subjectid = subjectid;
        subjectsApi.updateSubject(data, onUpdateSubject);
      }
    }
  }

  function onEditSubject(subjectData) {
    logger.trace('onEditSubject');

    activityApi.logActivity(teacher.teacherid, 'AdminSubject', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setSubjectID(subjectData.subjectid);
    setCreate(false);
    setVariables(subjectData);
  }

  function onDeleteSubject() {
    logger.trace('onDeleteSubject');

    activityApi.logActivity(teacher.teacherid, 'AdminSubject', 'Delete');

    setCreate(true);
    subjectsApi.fetchSubjects(onFetchSubjects);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');

    logger.info('Canelling Subject Edit');
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminSubject', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    subjectsApi.fetchSubjects(onFetchSubjects);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Subject', prop: 'name', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => subjectsApi.editSubject(row.subject.subjectid, onEditSubject)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => subjectsApi.deleteSubject(row.subject.subjectid, onDeleteSubject)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  subjects.forEach((subject) => {
    body.push({
      subject,
      id: subject.subjectid,
      name: subject.name,
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Subject</h2>}
            {create === false && <h2>Update Subject</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newsubject-subject">
                  Name
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="newsubject-name"
                    placeholder="Enter subject name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Subject" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Subject" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="container bg-white">
              <div>
                <div>
                  <h2>Subjects</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="subjects.csv" data={subjects}>
                    Download Subjects
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminSubject;
