import axios from 'axios';

const logger = require('../../logger');

export function fetchStarterTags(onFetchStarterTags) {
  logger.trace('fetchStarterTags');

  axios
    .get('/startertags')
    .then((response) => {
      if (response.status === 200) {
        onFetchStarterTags(response.data.startertags);
      }
    })
    .catch((error) => {
      logger.error(error, '/startertags');
    });
}

export function createStarterTag(data, onCreateStarterTag) {
  logger.trace('createStarterTag');

  logger.info('Creating Starter Tag: ');

  axios
    .post('/startertag/create', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Starter Tag created...');
        onCreateStarterTag();
      }
    })
    .catch((error) => {
      logger.error(error, '/startertag/create');
    });
}

export function updateStarterTag(data, onUpdateStarterTag) {
  logger.trace('updateStarterTag');

  axios
    .post('/startertag/update', data)
    .then((response) => {
      if (response.status === 200) {
        logger.info('Starter Tag updated...');
        onUpdateStarterTag();
      }
    })
    .catch((error) => {
      logger.error(error, '/startertag/update');
    });
}

export function deleteStarterTag(idOfStarterTag, onDeleteStarterTag) {
  logger.trace('deleteStarterTag');

  if (window.confirm('Are you sure you wish to delete this starter tag?')) {
    logger.info('Deleting Starter Tag: ', idOfStarterTag);

    axios
      .delete(`/startertag/${idOfStarterTag}`)
      .then((response) => {
        if (response.status === 200) {
          logger.info('Starter Tag created...');
          onDeleteStarterTag();
        }
      })
      .catch((error) => {
        logger.error(error, '/startertag/');
      });
  }
}

export function editStarterTag(idOfStarterTag, onEditStarterTag) {
  logger.trace('editStarterTag');

  logger.info('Editing StarterTag: ', idOfStarterTag);

  axios
    .get(`/startertag/${idOfStarterTag}`)
    .then((response) => {
      if (response.status === 200) {
        onEditStarterTag(response.data);
      }
    })
    .catch((error) => {
      logger.error(error, '/startertag/');
    });
}
