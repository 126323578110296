import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const activityApi = require('../../helpers/api/activity');

function AdminMetrics() {
  logger.trace('AdminMetrics');

  const nav = useNavigate();
  if (!adminAccess.CheckAccessToAdminScreen) {
    nav('/lessonstarters');
  }
  const teacher = user.getStoredTeacher();

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminReports', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }
  }, [teacher.teacherid]);

  return (
    <div>
      <h2>Reports</h2>
      <div className="container">List of reports</div>
    </div>
  );
}

export default AdminMetrics;
