import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const startersApi = require('../../helpers/api/starters');
const subjectsApi = require('../../helpers/api/subjects');
const coursesApi = require('../../helpers/api/courses');
const teachingUnitsApi = require('../../helpers/api/teachingunits');
const subUnitsApi = require('../../helpers/api/subunits');
const subUnitStartersApi = require('../../helpers/api/subunitstarters');
const activityApi = require('../../helpers/api/activity');

function AdminCurriculum() {
  logger.trace('AdminSubUnit');

  const [errorMessages, setErrorMessages] = useState([]);

  const [lessonStarters, setLessonStarters] = useState([]);

  const [subjects, setSubjects] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [courses, setCourses] = useState([]);
  const [teachingUnits, setTeachingUnits] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [subunits, setSubUnits] = useState([]);

  const [selCourses, setSelectableCourses] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selTeachingUnits, setSelectableTeachingUnits] = useState([]);
  const [selSubUnits, setSelectableSubUnits] = useState([]);

  const [starterid, setStarterID] = useState(0);
  const [subjectid, setSubjectID] = useState(0);
  const [courseid, setCourseID] = useState(0);
  const [teachingUnitid, setTeachingUnitID] = useState(0);
  const [subunitid, setSubUnitID] = useState(0);

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function onFetchLessonStarters(listOfStarters) {
    logger.trace('onFetchLessonStarters');
    setLessonStarters(listOfStarters);
  }

  function onFetchSubjects(listOfSubjects) {
    logger.trace('onFetchSubjects');
    setSubjects(listOfSubjects);
  }

  function onFetchCourses(listOfCourses) {
    logger.trace('onFetchCourses');
    setCourses(listOfCourses);
  }

  function onFetchTeachingUnits(listOfTeachingUnits) {
    logger.trace('onFetchTeachingUnits');
    setTeachingUnits(listOfTeachingUnits);
  }

  function onFetchSubUnits(listOfSubUnits) {
    logger.trace('onFetchSubUnits');
    setSubUnits(listOfSubUnits);
  }

  function changeStarterID(starterID) {
    logger.trace('changeStarterID');
    setStarterID(starterID);
  }

  function onChangeSubject(idOfSubject, listOfCourses) {
    logger.trace('OnChangeSubject');
    setSelectableCourses(listOfCourses);
    setSubjectID(idOfSubject);
  }

  function onChangeCourse(idOfCourse, teachingunits) {
    logger.trace('onChangeCourse');
    setSelectableTeachingUnits(teachingunits);
    setCourseID(idOfCourse);
  }

  function onChangeTeachingUnit(idTeachingUnit, listOfSubUnits) {
    logger.trace('onChangeTeachingUnit');
    setSelectableSubUnits(listOfSubUnits);
    setTeachingUnitID(idTeachingUnit);
  }

  function onCreateSubUnitStarter() {
    logger.trace('onCreateSubUnitStarter');

    activityApi.logActivity(teacher.teacherid, 'AdminCurriculum', 'Create');

    setStarterID(0);
    setSubjectID(0);
    setCourseID(0);
    setTeachingUnitID(0);
    setSubUnitID(0);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (subjectid == null || subjectid === 0) {
      errors.push('Invalid Subject!');
    }

    if (courseid == null || courseid === 0) {
      errors.push('Invalid Course!');
    }

    if (teachingUnitid == null || teachingUnitid === 0) {
      errors.push('Invalid Teaching Unit!');
    }

    return errors;
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const data = {
        subunitid,
        starterid
      };

      logger.info('Creating Sub Unit Starter');
      subUnitStartersApi.createSubUnitStarter(data, onCreateSubUnitStarter);
    }
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminCurriculum', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    startersApi.fetchStarters(onFetchLessonStarters);
    subjectsApi.fetchSubjects(onFetchSubjects);
    coursesApi.fetchCourses(onFetchCourses);
    teachingUnitsApi.fetchTeachingUnits(onFetchTeachingUnits);
    subUnitsApi.fetchSubUnits(onFetchSubUnits);
  }, [teacher.teacherid]);

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            <h2>New Curriculum</h2>

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newcurric-starterid">
                  Lesson Starter
                  <select className="form-control" name="starterid" id="newcurric-starterid" onChange={(e) => changeStarterID(e.target.value)} value={starterid}>
                    <option key="0" value="">
                      --Please choose a lesson starter--
                    </option>
                    {lessonStarters.map((starter) => (
                      <option key={starter.starterid} value={starter.starterid}>
                        {starter.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newcurric-subjectid">
                  Subject
                  <select
                    className="form-control"
                    name="subjectid"
                    id="newcurric-subjectid"
                    onChange={(e) => subjectsApi.changeSubject(e.target.value, onChangeSubject)}
                    value={subjectid}
                  >
                    <option key="0" value="">
                      --Please choose a subject--
                    </option>
                    {subjects.map((subject) => (
                      <option key={subject.subjectid} value={subject.subjectid}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newcurric-courseid">
                  Course
                  <select
                    className="form-control"
                    name="courseid"
                    id="newcurric-courseid"
                    onChange={(e) => coursesApi.changeCourse(e.target.value, onChangeCourse)}
                    value={courseid}
                  >
                    <option key="0" value="">
                      --Please choose a course--
                    </option>
                    {selCourses.map((course) => (
                      <option key={course.courseid} value={course.courseid}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newcurric-teachingunit">
                  Teaching Unit
                  <select
                    className="form-control"
                    name="newcurric-teachingunit"
                    id="newcurric-teachingUnitid"
                    onChange={(e) => teachingUnitsApi.changeTeachingUnit(subjectid, courseid, e.target.value, onChangeTeachingUnit)}
                    value={teachingUnitid}
                  >
                    <option key="0" value="">
                      --Please choose a teaching unit--
                    </option>
                    {teachingUnits.map((teachingUnit) => (
                      <option key={teachingUnit.teachingunitid} value={teachingUnit.teachingunitid}>
                        {teachingUnit.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="newcurric-subunit">
                  Sub Unit
                  <select className="form-control" name="newcurric-subunit" id="newcurric-subunitid" onChange={(e) => setSubUnitID(e.target.value)} value={subunitid}>
                    <option key="0" value="">
                      --Please choose a sub unit--
                    </option>
                    {selSubUnits.map((subunit) => (
                      <option key={subunit.subunitid} value={subunit.subunitid}>
                        {subunit.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <input className="btn btn-primary" type="submit" value="Add to Curriculum" />
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminCurriculum;
