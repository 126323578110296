import axios from 'axios';

const logger = require('../../logger');

export function resetPassword(email, onResetPassword) {
  const data = { email };

  axios
    .post('/teacher/resetpassword', data)
    .then((response) => {
      if (response.status === 200) {
        onResetPassword('If an account exisits with that email address we will send you a reset password email shortly.');
      } else {
        onResetPassword('We were unable to reset you password at this time. Please try again later or contact the admin team.');
      }
    })
    .catch((error) => {
      logger.error(error, 'users.js POST - /teacher/resetpassword');
      onResetPassword('Failed to reset password, please contact administrators');
    });
}

export function login(email, password, onLogin) {
  const data = { email, password };

  axios
    .post('/login', data)
    .then((response) => {
      if (response.status === 200) {
        onLogin(true, response.data);
      }
    })
    .catch((error) => {
      logger.error(error, `POST - /login - email: ${data.email}`);
      onLogin(false, 'Access denied!');
    });
}
