import { useState } from 'react';
import PropTypes from 'prop-types';

import logger from '../../../logger';

function VideoStarterEdit(props) {
  logger.trace('VideoStarterEdit');

  const [title, setTitle] = useState(props.title);
  const [src, setSrc] = useState(props.src);
  const [thumb, setThumb] = useState(props.thumb);

  VideoStarterEdit.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    thumb: PropTypes.string.isRequired,
    onChildChange: PropTypes.func
  };

  VideoStarterEdit.defaultProps = {
    onChildChange: null
  };

  function somethingChanged(target) {
    logger.trace('somethingChanged');

    const data = { title, src };

    if (target.name === 'title') {
      setTitle(target.value);
      data.title = target.value;
    }
    if (target.name === 'src') {
      setSrc(target.value);
      data.src = target.value;
    }
    if (target.name === 'thumb') {
      setThumb(target.value);
      data.thumb = target.value;
    }

    if (props.onChildChange !== undefined && props.onChildChange !== null) {
      props.onChildChange(data);
    } else {
      logger.warn('No onChildChange defined for VideoStarterEdit');
    }
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-title">
          Title:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="title"
          id="newcon-title"
          placeholder="Enter lesson starter title"
          onChange={(e) => somethingChanged(e.target)}
          value={title}
        />
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-src">
          Source:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="src"
          id="newcon-src"
          placeholder="Enter YouTube embedded link"
          onChange={(e) => somethingChanged(e.target)}
          value={src}
        />
      </div>

      <div className="mb-3">
        <label className="form-label" htmlFor="newcon-thumb">
          Thumbnail:
        </label>
        <input
          className="form-control"
          type="text"
          size="50"
          name="thumb"
          id="newcon-src"
          placeholder="Enter YouTube thumbnail url"
          onChange={(e) => somethingChanged(e.target)}
          value={thumb}
        />
      </div>
    </>
  );
}

export default VideoStarterEdit;
