import axios from 'axios';

const logger = require('../../logger');

export function logActivity(teacherid, page, activity) {
  logger.trace('logActivity');

  const data = {
    teacherid,
    page,
    activity
  };

  axios.post('/activity', data).catch((error) => {
    logger.error(error, 'POST - /activity');
  });
}

export function dummy() {}
