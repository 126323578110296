/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import PropTypes from 'prop-types';

const logger = require('../../logger');

function Stars(props) {
  logger.trace('Stars');

  Stars.propTypes = {
    numOfStars: PropTypes.number.isRequired
  };

  const { numOfStars } = props;

  return (
    <>
      {Math.round(numOfStars) > 0 ? <i className="bi bi-star-fill text-warning" /> : <i className="bi bi-star text-warning" />}
      {Math.round(numOfStars) > 1 ? <i className="bi bi-star-fill text-warning" /> : <i className="bi bi-star text-warning" />}
      {Math.round(numOfStars) > 2 ? <i className="bi bi-star-fill text-warning" /> : <i className="bi bi-star text-warning" />}
      {Math.round(numOfStars) > 3 ? <i className="bi bi-star-fill text-warning" /> : <i className="bi bi-star text-warning" />}
      {Math.round(numOfStars) > 4 ? <i className="bi bi-star-fill text-warning" /> : <i className="bi bi-star text-warning" />}
    </>
  );
}

export default Stars;
