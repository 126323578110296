// Package imports
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import './styles/app.scss';

// Component imports
import MyAccountPage from './pages/MyAccountPage';
import MessagePage from './pages/MessagePage';
import FavouritesPage from './pages/FavouritesPage';
// import MyDiaryPage from './pages/MyDiaryPage';
import LandingPage from './pages/LandingPage';
import LessonStarters from './pages/lessonstarters/LessonStarters';
import LessonStarter from './pages/lessonstarters/LessonStarter';
import PrivacyPage from './pages/PrivacyPage';
import NotFound from './pages/NotFound';

import SignInPage from './pages/login/SignInPage';
import SignUpPage from './pages/login/SignUpPage';
import ForgotPasswordPage from './pages/login/ForgotPasswordPage';
import NewPasswordPage from './pages/login/NewPasswordPage';
import DiaryPage from './pages/diary/DiaryPage';

import AdminHome from './pages/admin/AdminHome';
import AdminSchool from './pages/admin/AdminSchool';
import AdminTeacher from './pages/admin/AdminTeacher';
import AdminDiary from './pages/admin/AdminDiary';
import AdminSchoolTeacher from './pages/admin/AdminSchoolTeacher';
import AdminStarter from './pages/admin/AdminStarter';
import AdminSubject from './pages/admin/AdminSubject';
import AdminCourse from './pages/admin/AdminCourse';
import AdminTeachingUnit from './pages/admin/AdminTeachingUnit';
import AdminSubUnit from './pages/admin/AdminSubUnit';
import AdminSubUnitStarter from './pages/admin/AdminSubUnitStarter';
import AdminTag from './pages/admin/AdminTag';
import AdminStarterTag from './pages/admin/AdminStarterTag';
import AdminFavourite from './pages/admin/AdminFavourite';
import AdminRating from './pages/admin/AdminRating';
import AdminCurriculum from './pages/admin/AdminCurriculum';

import AdminMessage from './pages/admin/AdminMessage';
import AdminFeedback from './pages/admin/AdminFeedback';

import PrivateRoute from './components/common/PrivateRoute';
import AdminRoute from './components/common/AdminRoute';

const logger = require('./logger');

logger.debug(`Connected to backed via: ${process.env.REACT_APP_BACKEND_URL}`);
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;

if (process.env.REACT_APP_AUTHORISATION === 'true') {
  logger.debug('Added ACCESS_TOKEN to Authoization Header');
  axios.defaults.headers.common.Authorization = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`;
} else {
  logger.debug('Running without Authorization Header');
}

function App() {
  logger.trace('App');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/newpassword/:resetToken" element={<NewPasswordPage />} />

        <Route
          path="/home"
          element={
            <PrivateRoute>
              <MyAccountPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <PrivateRoute>
              <MessagePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/favourites"
          element={
            <PrivateRoute>
              <FavouritesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/diary"
          element={
            <PrivateRoute>
              <DiaryPage />
            </PrivateRoute>
          }
        />

        <Route path="/lessonstarters" element={<LessonStarters />} />
        <Route path="/lessonstarters/:sortByRating" element={<LessonStarters />} />
        <Route path="/lessonstarter/:starterid" element={<LessonStarter />} />

        <Route
          path="/admin"
          element={
            <AdminRoute>
              <AdminHome />
            </AdminRoute>
          }
        />
        <Route
          path="/adminschool"
          element={
            <AdminRoute>
              <AdminSchool />
            </AdminRoute>
          }
        />
        <Route
          path="/adminteacher"
          element={
            <AdminRoute>
              <AdminTeacher />
            </AdminRoute>
          }
        />
        <Route
          path="/adminschoolteacher"
          element={
            <AdminRoute>
              <AdminSchoolTeacher />
            </AdminRoute>
          }
        />
        <Route
          path="/admindiary"
          element={
            <AdminRoute>
              <AdminDiary />
            </AdminRoute>
          }
        />
        <Route
          path="/adminsubject"
          element={
            <AdminRoute>
              <AdminSubject />
            </AdminRoute>
          }
        />
        <Route
          path="/admincourse"
          element={
            <AdminRoute>
              <AdminCourse />
            </AdminRoute>
          }
        />
        <Route
          path="/adminteachingunit"
          element={
            <AdminRoute>
              <AdminTeachingUnit />
            </AdminRoute>
          }
        />
        <Route
          path="/adminsubunit"
          element={
            <AdminRoute>
              <AdminSubUnit />
            </AdminRoute>
          }
        />
        <Route
          path="/adminstarter"
          element={
            <AdminRoute>
              <AdminStarter />
            </AdminRoute>
          }
        />
        <Route
          path="/adminsubunitstarter"
          element={
            <AdminRoute>
              <AdminSubUnitStarter />
            </AdminRoute>
          }
        />
        <Route
          path="/admintag"
          element={
            <AdminRoute>
              <AdminTag />
            </AdminRoute>
          }
        />
        <Route
          path="/adminstartertag"
          element={
            <AdminRoute>
              <AdminStarterTag />
            </AdminRoute>
          }
        />
        <Route
          path="/adminfavourite"
          element={
            <AdminRoute>
              <AdminFavourite />
            </AdminRoute>
          }
        />
        <Route
          path="/adminrating"
          element={
            <AdminRoute>
              <AdminRating />
            </AdminRoute>
          }
        />
        <Route
          path="/admincurriculum"
          element={
            <AdminRoute>
              <AdminCurriculum />
            </AdminRoute>
          }
        />
        <Route
          path="/adminmessage"
          element={
            <AdminRoute>
              <AdminMessage />
            </AdminRoute>
          }
        />
        <Route
          path="/adminfeedback"
          element={
            <AdminRoute>
              <AdminFeedback />
            </AdminRoute>
          }
        />

        <Route path="/privacy" element={<PrivacyPage />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
