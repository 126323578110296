const ACCESS_LEVELS = {
  OPEN_ACCESS: -1,
  ADMIN_ACCESS: 1,
  CREATOR_ACCESS: 2,
  TEACHER_ACCESS: 3,
};

module.exports = {
  ACCESS_LEVELS,
};
