import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const favouritesApi = require('../../helpers/api/favourites');
const teachersApi = require('../../helpers/api/teachers');
const startersApi = require('../../helpers/api/starters');
const activityApi = require('../../helpers/api/activity');

function AdminFavourite() {
  logger.trace('AdminFavourite');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [favouriteid, setFavouriteID] = useState(0);

  const [favourites, setFavourites] = useState([]);

  const [teachers, setTeachers] = useState([]);
  const [starters, setStarters] = useState([]);

  const [teacherid, setTeacherID] = useState(0);
  const [starterid, setStarterID] = useState(0);

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');

    setTeacherID(data.teacherid);
    setStarterID(data.starterid);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setTeacherID(0);
    setStarterID(0);
  }

  function onFetchFavourites(listOfFavourites) {
    logger.trace('onFetchFavourites');
    setFavourites(listOfFavourites);
  }

  function onFetchTeachers(listOfTeachers) {
    logger.trace('onFetchTeachers');
    setTeachers(listOfTeachers);
  }

  function onFetchStarters(listOfStarters) {
    logger.trace('onFetchStarters');
    setStarters(listOfStarters);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (teacherid == null || teacherid === 0) {
      errors.push('Invalid Teacher!');
    }

    if (starterid == null || starterid === 0) {
      errors.push('Invalid Starter!');
    }

    return errors;
  }

  function onCreateFavourite() {
    logger.trace('onCreateFavourite');

    activityApi.logActivity(teacher.teacherid, 'AdminFavourite', 'Create');

    setTeacherID('');
    setStarterID('');

    favouritesApi.fetchFavourites(onFetchFavourites);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function onUpdateFavourite() {
    logger.trace('updateFavourite');

    activityApi.logActivity(teacher.teacherid, 'AdminFavourite', 'Update');

    setTeacherID('');
    setStarterID('');

    favouritesApi.fetchFavourites(onFetchFavourites);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        teacherid,
        starterid
      };

      if (create === true) {
        favouritesApi.createFavourite(postData, onCreateFavourite);
      } else {
        postData.favouriteid = favouriteid;
        favouritesApi.updateFavourite(postData, onUpdateFavourite);
      }
    }
  }

  function onDeleteFavourite() {
    logger.trace('deleteFavourite');

    activityApi.logActivity(teacher.teacherid, 'AdminFavourite', 'Delete');

    setTeacherID('');
    setStarterID('');

    favouritesApi.fetchFavourites(onFetchFavourites);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);

    setCreate(true);
  }

  function onEditFavourite(favourite) {
    logger.trace('onEditFavourite');

    activityApi.logActivity(teacher.teacherid, 'AdminFavourite', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setFavouriteID(favourite.favouriteid);
    setCreate(false);
    setVariables(favourite);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');

    logger.info('Cancelling Edit');

    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminFavourtie', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    favouritesApi.fetchFavourites(onFetchFavourites);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Teacher', prop: 'teacher', isSortable: true, isFilterable: true },
    { title: 'Lesson Starter', prop: 'starter', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => favouritesApi.editFavourite(row.favourite.favouriteid, onEditFavourite)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => favouritesApi.deleteFavourite(row.favourite.favouriteid, onDeleteFavourite)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  favourites.forEach((favourite) => {
    body.push({
      favourite,
      id: favourite.favouriteid,
      teacher: teachersApi.lookupTeacher(teachers, favourite.teacherid),
      starter: startersApi.lookupStarter(starters, favourite.starterid),
      options: ''
    });
  });

  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Favourite</h2>}
            {create === false && <h2>Update Favourite</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newfav-teacherid">
                  Teacher
                  <select className="form-control" name="teacherid" id="newfav-teacherid" onChange={(e) => setTeacherID(e.target.value)} value={teacherid}>
                    <option value="">--Please choose a teacher--</option>
                    {teachers.map((ateacher) => (
                      <option key={ateacher.teacherid} value={ateacher.teacherid}>
                        {ateacher.firstname} {ateacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newfav-startereid">
                  Starter
                  <select className="form-control" name="starterid" id="newfav-starterid" onChange={(e) => setStarterID(e.target.value)} value={starterid}>
                    <option key="0" value="">
                      --Please choose a lesson starter--
                    </option>
                    {starters.map((starter) => (
                      <option key={starter.starterid} value={starter.starterid}>
                        {starter.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Favourite" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Favourite" />
                  <button className="btn btn-secondary" value="Cancel" type="submit" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="container bg-white">
              <div>
                <h2>Favourites</h2>
              </div>
              <div style={{ float: 'right', width: '200px' }}>
                <CSVLink filename="favourites.csv" data={favourites}>
                  Download Favourites
                </CSVLink>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminFavourite;
