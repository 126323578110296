const logger = require('../logger');

export function formatDateTime(dateTime) {
  logger.trace('formatDate');
  if (dateTime === null) {
    return '';
  }
  const newDateTime = new Date(dateTime);
  return `${newDateTime.toLocaleDateString('en-GB')} ${newDateTime.toLocaleTimeString('en-GB')}`;
}

export function dummy() {}
