import React from 'react';
import PropTypes from 'prop-types';

import { FullScreen } from 'react-full-screen';
import logger from '../../../logger';

import './YouTubeEmbed.css';

function YouTubeEmbed({ embedUrl, handle }) {
  logger.trace('YouTubeEmbed');

  YouTubeEmbed.propTypes = {
    embedUrl: PropTypes.string.isRequired,
    handle: PropTypes.object.isRequired
  };

  const width = handle !== undefined && handle.active === true ? '100%' : '840px';
  const height = handle !== undefined && handle.active === true ? '100%' : '480px';

  return (
    <div className="video-container">
      <div className="video-responsive">
        <FullScreen handle={handle}>
          <iframe
            width={width}
            height={height}
            src={embedUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </FullScreen>
      </div>
    </div>
  );
}

export default YouTubeEmbed;
