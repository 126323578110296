import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import { Col, Row, Table } from 'react-bootstrap';

import './AdminHome.css';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import AdminMenu from './AdminMenu';

const logger = require('../../logger');
const adminAccess = require('./access');
const user = require('../../helpers/user');

const teachersApi = require('../../helpers/api/teachers');
const ratingsApi = require('../../helpers/api/ratings');
const startersApi = require('../../helpers/api/starters');
const activityApi = require('../../helpers/api/activity');

function AdminRating() {
  logger.trace('AdminRating');

  const [errorMessages, setErrorMessages] = useState([]);

  const [create, setCreate] = useState(true);
  const [ratingid, setRatingID] = useState(0);

  const [ratings, setRatings] = useState([]);

  const [teachers, setTeachers] = useState([]);
  const [starters, setStarters] = useState([]);

  const [teacherid, setTeacherID] = useState(0);
  const [starterid, setStarterID] = useState(0);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const nav = useNavigate();
  const teacher = user.getStoredTeacher();

  function setVariables(data) {
    logger.trace('setVariables');

    setTeacherID(data.teacherid);
    setStarterID(data.starterid);
    setRating(data.rating);
    setComment(data.comment);
  }

  function resetVariables() {
    logger.trace('resetVariables');

    setTeacherID(0);
    setStarterID(0);
    setRating(0);
    setComment('');
  }

  function onFetchRatings(listOfRatings) {
    logger.trace('onFetchRatings');
    setRatings(listOfRatings);
  }

  function onFetchTeachers(listOfTeachers) {
    logger.trace('onFetchTeachers');
    setTeachers(listOfTeachers);
  }

  function onFetchStarters(listOfStarters) {
    logger.trace('onFetchStarters');
    setStarters(listOfStarters);
  }

  function validateFields() {
    logger.trace('validateFields');

    const errors = [];

    if (teacherid == null || teacherid === 0) {
      errors.push('Invalid Teache!');
    }

    if (starterid == null || starterid === 0) {
      errors.push('Invalid Starter!');
    }

    return errors;
  }

  function onCreateRating() {
    logger.trace('createRating');

    activityApi.logActivity(teacher.teacherid, 'AdminRating', 'Create');

    resetVariables();
    ratingsApi.fetchRatings(onFetchRatings);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function onUpdateRating() {
    logger.trace('updateRating');

    activityApi.logActivity(teacher.teacherid, 'AdminRating', 'Update');

    resetVariables();
    ratingsApi.fetchRatings(onFetchRatings);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function handleFormSubmit(event) {
    logger.trace('handleFormSubmit');

    event.preventDefault();

    const errors = validateFields();
    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);

      const postData = {
        ratingid,
        teacherid,
        starterid,
        rating,
        comment
      };

      if (create === true) {
        ratingsApi.createRating(postData, onCreateRating);
      } else {
        ratingsApi.updateRating(postData, onUpdateRating);
      }
    }
  }

  function onDeleteRating() {
    logger.trace('onDeleteRating');

    activityApi.logActivity(teacher.teacherid, 'AdminRating', 'Delete');

    resetVariables();
    ratingsApi.fetchRatings(onFetchRatings);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }

  function onEditRating(ratingData) {
    logger.trace('onEditRating');

    activityApi.logActivity(teacher.teacherid, 'AdminRating', 'Edit');

    window.scrollTo({ top: 0, left: 0, behaviour: 'smooth' });

    setRatingID(ratingData.ratingid);
    setCreate(false);
    setVariables(ratingData);
  }

  function cancelEdit() {
    logger.trace('cancelEdit');
    logger.info('Cancelling Edit: ', ratingid);
    setCreate(true);
    resetVariables();
  }

  useEffect(() => {
    logger.trace('useEffect');

    activityApi.logActivity(teacher.teacherid, 'AdminRating', 'View');

    if (adminAccess.CheckAccessToAdminScreen() === false) {
      nav('/lessonstarters');
    }

    ratingsApi.fetchRatings(onFetchRatings);
    teachersApi.fetchTeachers(onFetchTeachers);
    startersApi.fetchStarters(onFetchStarters);
  }, [teacher.teacherid]);

  const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Teacher', prop: 'teacher', isSortable: true, isFilterable: true },
    { title: 'Lesson Starter', prop: 'starter', isSortable: true, isFilterable: true },
    { title: 'Comment', prop: 'comment', isSortable: true, isFilterable: true },
    { title: 'Rating', prop: 'rating', isSortable: true, isFilterable: true },
    {
      title: 'Options',
      prop: 'options',
      isSortable: false,
      /* eslint-disable-next-line */
      cell: (row) => {
        return (
          <div>
            <button type="submit" value="Edit" onClick={() => ratingsApi.editRating(row.theRating.ratingid, onEditRating)}>
              <i className="bi bi-pencil-square" />
            </button>
            <button type="submit" value="Delete" onClick={() => ratingsApi.deleteRating(row.theRating.ratingid, onDeleteRating)}>
              <i className="bi bi-trash" />
            </button>{' '}
          </div>
        );
      }
    }
  ];

  const body = [];
  ratings.forEach((theRating) => {
    body.push({
      theRating,
      id: theRating.ratingid,
      teacher: teachersApi.lookupTeacher(teachers, theRating.teacherid),
      starter: startersApi.lookupStarter(starters, theRating.starterid),
      comment: theRating.comment,
      rating: theRating.rating,
      options: ''
    });
  });
  return (
    <>
      <Header />

      <div className="container bg-white">
        <div className="row">
          <div className="col md-2 adminsidebar">
            <AdminMenu />
          </div>
          <div className="col md-10 bg-white">
            {create === true && <h2>New Rating</h2>}
            {create === false && <h2>Update Rating</h2>}

            {errorMessages.length > 0 && (
              <div>
                <ul>
                  {errorMessages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label" htmlFor="newrating-teacherid">
                  Teacher:
                  <select className="form-control" name="teacherid" id="newrating-teacherid" onChange={(e) => setTeacherID(e.target.value)} value={teacherid}>
                    <option value="">--Please choose a teacher--</option>
                    {teachers.map((ateacher) => (
                      <option key={ateacher.teacherid} value={ateacher.teacherid}>
                        {ateacher.firstname} {ateacher.lastname}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="newrating-startereid">
                  Starter:
                  <select className="form-control" name="starterid" id="newrating-starterid" onChange={(e) => setStarterID(e.target.value)} value={starterid}>
                    <option className="form-label" key="0" value="">
                      --Please choose a lesson starter--
                    </option>
                    {starters.map((starter) => (
                      <option key={starter.starterid} value={starter.starterid}>
                        {starter.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newrating-rating">
                  Rating:
                  <select className="form-control" name="rating" id="newrating-rating" onChange={(e) => setRating(e.target.value)} value={rating}>
                    <option>--Please choose a rating--</option>
                    <option key={1} value={1}>
                      {1}
                    </option>
                    <option key={2} value={2}>
                      {2}
                    </option>
                    <option key={3} value={3}>
                      {3}
                    </option>
                    <option key={4} value={4}>
                      {4}
                    </option>
                    <option key={5} value={5}>
                      {5}
                    </option>
                  </select>{' '}
                </label>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="newrating-comment">
                  Comment:
                  <textarea
                    className="form-control"
                    rows="8"
                    cols="50"
                    name="comment"
                    id="newrating-comment"
                    placeholder="Enter comment"
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                  />
                </label>
              </div>
              {create === true && <input className="btn btn-primary" type="submit" value="Create Rating" />}
              {create === false && (
                <>
                  <input className="btn btn-primary" type="submit" value="Update Teacher" />
                  <button type="submit" className="btn btn-secondary" value="Cancel" onClick={() => cancelEdit()}>
                    Cancel
                  </button>
                </>
              )}
            </form>

            <hr />

            <div className="row bg-white">
              <div>
                <div>
                  <h2>Ratings</h2>
                </div>
                <div style={{ float: 'right', width: '200px' }}>
                  <CSVLink filename="ratings.csv" data={ratings}>
                    Download Ratings
                  </CSVLink>
                </div>
              </div>
              <DatatableWrapper
                body={body}
                headers={headers}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 25,
                    options: [10, 25, 50, 100]
                  }
                }}
              >
                <Row className="mb-4">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0">
                    <PaginationOptions />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>{' '}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdminRating;
