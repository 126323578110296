function getYouTubeThumbnailPath() {
  if (process.env.REACT_APP_YOUTUBE_THUMBNAIL_PATH !== undefined && process.env.REACT_APP_YOUTUBE_THUMBNAIL_PATH !== '') {
    return process.env.REACT_APP_YOUTUBE_THUMBNAIL_PATH;
  }
  return '/images/lessonstarters/YouTubeThumbNails/';
}

function getDidYouKnowPath() {
  if (process.env.REACT_APP_DIDYOUKNOW_PATH !== undefined && process.env.REACT_APP_DIDYOUKNOW_PATH !== '') {
    return process.env.REACT_APP_DIDYOUKNOW_PATH;
  }
  return '/images/lessonstarters/DidYouKnow/';
}

function getMultiChoicePath() {
  if (process.env.REACT_APP_MULTICHOICE_PATH !== undefined && process.env.REACT_APP_MULTICHOICE_PATH !== '') {
    return process.env.REACT_APP_MULTICHOICE_PATH;
  }
  return '/images/lessonstarters/MultiChoice/';
}

function getQuestionAnswerPath() {
  if (process.env.REACT_APP_QUESTIONANSWER_PATH !== undefined && process.env.REACT_APP_QUESTIONANSWER_PATH !== '') {
    return process.env.REACT_APP_QUESTIONANSWER_PATH;
  }
  return '/images/lessonstarters/QuestionAnswer/';
}

export function replaceImagePaths(imgSrc) {
  // TODO Move these into environment variables
  let newImgSrc = imgSrc.replace('{REACT_APP_YOUTUBE_THUMBNAIL_PATH}', getYouTubeThumbnailPath());
  newImgSrc = newImgSrc.replace('{REACT_APP_DIDYOUKNOW_PATH}', getDidYouKnowPath());
  newImgSrc = newImgSrc.replace('{REACT_APP_MULTICHOICE_PATH}', getMultiChoicePath());
  newImgSrc = newImgSrc.replace('{REACT_APP_QUESTIONANSWER_PATH}', getQuestionAnswerPath());
  return newImgSrc;
}

export function dummy() {}
