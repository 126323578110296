import logger from '../logger';

export function formatWeekViewDate(date) {
  if (date !== undefined) {
    const formatted = date.toString().split(' ').splice(0, 4);
    return `${formatted[0]} ${formatted[2]} ${formatted[1]} ${formatted[3]}`;
  }
  return 'Something went wrong while getting the date';
}

const monthLookup = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sept: '09',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12'
};

export function formatForFetch(date) {
  const dateSplit = date.split(' ');
  // const formatted = `${dateSplit[3]}-${monthLookup[dateSplit[2]]}-${dateSplit[1]}`;
  const formatted = `${dateSplit[1]}/${monthLookup[dateSplit[2]]}/${dateSplit[3]}`;
  // eslint-disable-next-line consistent-return
  return formatted;
}

export function formatDateForId(date) {
  const split = date.split(' ');
  return `${split[1]}/${monthLookup[split[2]]}/${split[3]}`;
  // return `${split[3]}-${monthLookup[split[2]]}-${split[1]}`;
}

export function insertArray(list, currentIndex, starterBeingDragged) {
  // Remove starterBeingDragged from current array
  const removedArray = list.filter((starter) => starter !== starterBeingDragged);
  // Insert starterBeingDragged in the new place
  const newFormatStart = removedArray.slice(0, currentIndex);
  const newFormatEnd = removedArray.slice(currentIndex, removedArray.length);
  const newFormat = [...newFormatStart, starterBeingDragged, ...newFormatEnd];
  return newFormat;
}

export function pushToArray(stateDay, stateDaySetter, starter) {
  stateDaySetter([...stateDay, starter]);
}

export function formatDateRemoveYear(date) {
  const split = date.split(' ');
  return `${split[0]} ${split[1]} ${split[2]}`;
}

export function getDatesOfWeek(startDate, isNextWeek) {
  const getCurrentDate = new Date(startDate);
  const weekOffset = isNextWeek ? 7 : -7;
  const nextWeekDate = new Date(getCurrentDate.getTime() + weekOffset * 24 * 60 * 60 * 1000);
  const datesOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const newDate = formatWeekViewDate(new Date(nextWeekDate.getTime() + i * 24 * 60 * 60 * 1000));
    datesOfWeek.push(newDate);
  }
  return datesOfWeek;
}

export function handleNextWeekClick(currentWeekDates, stateSetter) {
  const nextDate = new Date(currentWeekDates[6]);
  nextDate.setDate(nextDate.getDate() + 1);
  const nextWeekStart = new Date(nextDate);
  nextWeekStart.setDate(nextDate.getDate() - nextWeekStart.getDay() - 6);
  stateSetter(getDatesOfWeek(nextWeekStart, true));
}

export function handlePreviousWeekClick(currentWeekDates, stateSetter) {
  const previousDate = new Date(currentWeekDates[0]);
  previousDate.setDate(previousDate.getDate() - 1);
  const previousWeekStart = new Date(previousDate);
  previousWeekStart.setDate(previousWeekStart.getDate() - previousWeekStart.getDay() + 1);
  stateSetter(getDatesOfWeek(previousWeekStart, false));
}

export function removeFromArray(list, starterToRemove) {
  return list.filter((starter) => starter !== starterToRemove);
}

export function updateDiaryCardsWithNewEntryIds(day, list, listSetter, updateIdsObject) {
  const updatedList = list.map((card) => {
    const oldEntryId = String(card.entryid);
    const newEntryId = updateIdsObject[oldEntryId] !== undefined ? updateIdsObject[oldEntryId] : card.entryid;
    return {
      ...card,
      entryid: Number(newEntryId)
    };
  });

  listSetter(updatedList);
}

// ####################################### DRAG FUNCTIONS #######################################
export function handleDragStart(starterDetails, startingList, event, componentDraggingSetter, startListSetter) {
  componentDraggingSetter(starterDetails);
  startListSetter(startingList);

  const cardElement = event.target.children[0].classList;
  cardElement.add('dragging-diary-card');
}

export function handleDragDrop(list, startList, stateList, currentIndex, starterBeingDragged, listSetter, postRequiredSetter) {
  postRequiredSetter(list);
  if (startList === list) {
    // Reordering items in a list
    listSetter(insertArray(stateList, currentIndex, starterBeingDragged));
    return;
  }

  // Inserting in the new list at currentIndex
  const targetIndex = currentIndex > 0 ? currentIndex : 0;

  listSetter((prevList) => insertArray(prevList, targetIndex, starterBeingDragged));

  // Remove the dragged DiaryCard from the starting list
  listSetter(removeFromArray(stateList));
}

export function formatForDelete(date) {
  const dateString = new Date(date).toDateString();
  const dateStringSplit = dateString.split(' ');
  // return `${dateStringSplit[2]}-${monthLookup[dateStringSplit[1]]}-${dateStringSplit[3]}`;
  return `${dateStringSplit[3]}-${monthLookup[dateStringSplit[1]]}-${dateStringSplit[2]}`;
}

function getWeekDates(randomDate) {
  const startDate = new Date(randomDate);
  startDate.setDate(startDate.getDate() - startDate.getDay()); // Get the Monday of the week

  const weekDates = [];
  for (let i = 1; i < 8; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    const formattedDate = currentDate.toLocaleDateString('en-UK', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
    weekDates.push(formattedDate);
  }

  return weekDates;
}

export function handleDatePicked(event, setShowDiaryDatePicker, setCurrentWeekDates) {
  logger.trace('handleDatePicked');
  const inputDate = new Date(event);
  const weekDates = getWeekDates(inputDate);

  setCurrentWeekDates(weekDates);
  setShowDiaryDatePicker(false);
}

export function formatForUpdate(date) {
  const thisDate = new Date(date);
  const year = thisDate.getFullYear();
  const month = (thisDate.getMonth() + 1).toString().padStart(2, 0); // Plus one bevause Jan returns a '0'
  const day = thisDate.getDate();
  const output = `${day}/${month}/${year}`;
  return output;
}

export function isSelectedDateWeekend(date, dateSetter, notificationSetter, valueSetter) {
  logger.trace('isSelectedDateWeekend');

  const inputDate = new Date(date);
  if (inputDate.toDateString().split(' ').includes('Sat') || inputDate.toDateString().split(' ').includes('Sun')) {
    // eslint-disable-next-line quotes
    notificationSetter({ show: true, warn: true, message: "You can't add a starter to a weekend" });
    setTimeout(() => {
      notificationSetter({ show: false, warn: false, message: '' });
    }, 2000);
    return;
  }
  dateSetter(formatForUpdate(date));
  valueSetter(formatForUpdate(date));
}

export function isSelectedDateWeekendForDiaryModal(date, dateSetter, notificationSetter) {
  logger.trace('isSelectedDateWeekendForDiaryModal');

  const inputDate = new Date(date);
  if (inputDate.toDateString().split(' ').includes('Sat') || inputDate.toDateString().split(' ').includes('Sun')) {
    // eslint-disable-next-line quotes
    notificationSetter({ show: true, warn: true, message: "You can't add a starter to a weekend" });
    setTimeout(() => {
      notificationSetter({ show: false, warn: false, message: '' });
    }, 2000);
    return;
  }
  dateSetter(date);
}
