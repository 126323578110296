import './LandingPage.css';

import { useNavigate } from 'react-router-dom';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

const logger = require('../logger');
const activityApi = require('../helpers/api/activity');

function LandingPage() {
  logger.trace('LandiingPage');

  activityApi.logActivity(0, 'LandingPage', 'View');

  const navigate = useNavigate();

  const gettingStarted = () => {
    navigate('/lessonstarters');
  };
  const topResources = () => {
    navigate('/lessonstarters/sortByRating');
  };
  const favResources = () => {
    navigate('/favourites');
  };

  return (
    <>
      <Header />

      <div className="container">
        <div className="row py-5">
          <div className="col">
            <div className="onestop">
              <div className="py-2 bg-light onestop-header">
                <h3>Your One-Stop Resource for Engaging Lesson Starters</h3>
              </div>
              <div className="py-2 onestop-text">
                Your companion for sparking engaging classroom discussions. Discover a comprehensive collection of discussion points, captivating videos, and code snippets selected
                to ignite curiosity and inspire student engagement.
              </div>
              <div className="py-2 lesson-starters">
                <button type="button" className="btn btn-primary" onClick={gettingStarted}>
                  Get Started
                </button>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="hero img-fluid">
              <img src="/images/hero-collage.png" alt="Hero Collage" />
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------------------------------ */}

      <div className="bg-primary">
        <div className="container">
          <div className="row middle-block">
            <div className="col-4 self-paced-learning">
              <div className="py-2">
                <img className="mx-auto d-block" src="/images/stopwatch.png" alt="Stopwatch" />
              </div>
              <div className="middle-block-header py-2">
                <h4>Self Paced Learning</h4>
              </div>
              <div className="">
                <span className="middle-block-text py-2">A viable platform to set deadlines and achieve your goals at your pace.</span>
              </div>
            </div>

            <div className="col-4 user-friendly">
              <div className="py-2">
                <img className="mx-auto d-block" src="/images/smiley-face.png" alt="Smiley Face" />
              </div>
              <div className="middle-block-header py-2">
                <h4>User Friendly</h4>
              </div>
              <div className="center-block py-2">
                <span className="middle-block-text">Very user friendly with features that enable an engaging experience.</span>
              </div>
            </div>

            <div className="col-4 scottish-curriculum">
              <div className="py-2">
                <img className="mx-auto d-block" src="/images/scottish-flag.png" alt="Scottish Flag" />
              </div>
              <div className="middle-block-header py-2">
                <h4>Scottish Curriculum</h4>
              </div>
              <div className="center-bloc py-2">
                <span className="middle-block-text">Content specifically created for the Scottish curriculum.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------------------------------------------------------ */}

      <div className="container">
        <div className="row bottom-block p-5">
          <div className="row align-items-center">
            <div className="col d-flex justify-content-center">
              <div className="align-items-center">
                <img src="/images/woman-programming.png" alt="Woman Programming" />
              </div>
            </div>

            <div className="col">
              <div className="bottom-row-text">
                <div className="py-2">
                  <h3>Lesson Starters</h3>
                </div>
                <div className="py-2">Browse our collection of lesson starters created and submitted by other teachers across Scotland.</div>
                <div className="lesson-starters py-2">
                  <button type="button" className="btn btn-primary" onClick={gettingStarted}>
                    Lesson Starters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col">
              <div className="bottom-row-text">
                <div className="py-2">
                  <h3>Favourite Resources</h3>
                </div>
                <div className="py-2">Select your favourite resources to make them easy to find every time you need them.</div>
                <div className="py-2 favourite-resources">
                  <button type="button" className="btn btn-primary" onClick={favResources}>
                    Favourite Resources
                  </button>
                </div>
              </div>
            </div>

            <div className="col d-flex justify-content-center">
              <div className="align-items-center">
                <img src="/images/champion.png" alt="Champion" />
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col d-flex justify-content-center">
              <div className="align-items-center">
                <img src="/images/woman-sofa.png" alt="Woman on sofa" />
              </div>
            </div>

            <div className="col">
              <div className="bottom-row-text">
                <div className="py-2">
                  <h3>Recommendations</h3>
                </div>
                <div className="py-2">Find the top rated resources quickly and easily with our simple star rating system.</div>
                <div className="py-2 recommendations">
                  <button type="button" className="btn btn-primary" onClick={topResources}>
                    See Top Resources
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LandingPage;
